import styled, { css } from "styled-components";

export const PostHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-bottom: 8px;
`;

export const PostAuthor = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  flex: 1;
  min-width:0;

  img {
    width: 46px;
    height: 46px;
    border-radius: 8px;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  }

  .author-name-address {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
      
    .author-name {
      font-size: 17px;
      font-weight: 700;
      text-decoration: none;
      transition: all 0.3s ease;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;

export const LightText = styled.span`
  font-size: 13px;
  font-weight: 400;
  color: #a0a0a0;
`;

export const Heading = styled.h1`
  color: var(--color-text);
  font-size: 20px;
  font-weight: 600;
  overflow-x: hidden;
`;

export const Content = styled.div`
  color: var(--color-text);
  font-size: 16px;
  font-weight: 400;

  .main-Div-moreLess {
    justify-content: start !important;

    span.readMoreLess {
      font-size: 16px !important;
      font-weight: 500 !important;
      color: #a0a0a0 !important;
    }
  }
`;

export const HTMLParser = styled.div`
  padding: 20px 0;
  font-size: 16px;
  
  &.pre {
    white-space: pre-wrap;
    word-break: break-word;
  }
  
  .videocontainer {
    margin: auto -20px;
  }

  iframe {
    width: 100%;
    height: unset !important;
    aspect-ratio: 16 / 9;
  }

  a:hover {
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary} !important;
  }

  .hashtag {
    color: ${(props) => props.theme.colors.secondary};
    cursor: pointer;
  }
`;

export const Media = styled.div<{ $size?: number }>`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  margin: auto -20px;

  picture {
    position: relative;
    height: auto;
    overflow: hidden;
    border-radius: 0;
    cursor: pointer;
    transition: all 0.5s ease;
    // box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
    background: #F3F3F3;
    display:flex;
    justify-content: center;

    &:hover {
      opacity: 0.8;
    }

    span {
      position: absolute;
      top: 50%;
      margin-top: -27.5px;
      left: 50%;
      margin-left: -27.5px;
      pointer-events: none;

      i {
        color: var(--primary-color);
        font-size: 25px;
        border-radius: 50%;
        padding: 15px;
        background-color: white;
      }
    }

    img {
      width: 100%;
      // aspect-ratio: 1 / 1;
      object-fit: cover;
      object-position: center;
      max-height: 600px;
    }

    .more-overlay {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      font-size: 20px;
      font-weight: 700;
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 0;
      margin-left: 0;
      pointer-events: none;
    }

    ${(props) =>
      props.$size === 1
        ? css`
            width: 100%;
            min-height:300px;
            img {
              // object-fit: contain;
            }
          `
        : props.$size === 2
          ? css`
              width: 49.42%;
              min-height:250px;
            `
          : css`
              // width: 31.5%;
              flex: 1 0;
              img {
                aspect-ratio: 1 / 1;
              }
            `}
  }
`;

export const PostActionsWrapper = styled.div<{ $notAllowed?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 10px;

  ${(props) =>
    props.$notAllowed &&
    css`
      pointer-events: none;
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const PostActionButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  padding-inline: 9px;
  border-radius: 14px;
  background-color: var(--primary-color-lighten-5);
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 500;
  height: 25px;
  border: none;
  transition: all 0.5s ease;
  line-height: 14px;

  &:hover {
    background-color: var(--primary-color-lighten-5);
  }
  svg {
    width: 21px;
    height: 19px;
  }
`;

export const CategoryLink = styled.a`
  color: var(--color-text) !important;
  transition: all 0.3s ease;

  &:hover {
    color: #000000 !important;
  }
`;
