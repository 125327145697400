import React from 'react';
import Avatar from '@mui/material/Avatar';
import { getInitials } from 'lib/utils/Commons';

export const LocciAvatar = ({ title, picture,onAvatarClick, ...props }) => {

    if (picture) {
        return <Avatar   className={`avatar ${props.className}`} alt={title} src={picture}  style={{ borderRadius:0, height: props.size, width: props.size }} onClick={onAvatarClick}/>;
    } else {

        return <Avatar    className="avatar" style={{ borderRadius:0,height: props.size, width: props.size }}  onClick={onAvatarClick}>{getInitials(title)}</Avatar>;
    }
}
