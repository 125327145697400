import { Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Button from "../../elements/buttons/Button";



const GroupMemberRemoveConfirmation = (props) => {
    const { t } = useTranslation();

    return (
        <Modal
            centered
            show={props.show}
            onHide={() => props.closeModal()}
            contentClassName="global-confirmation-modal"
        >
            <Modal.Body>
                <h2>{t("group_members_screen_remove_title")}</h2>

                <p>
                    {t("group_members_screen_remove_text_pt1")} <b>{props?.userName}</b> {t("group_members_screen_remove_text_pt2")}
                </p>
                <Form>
                    <Form.Group size="lg" className="form-group" >
                        <Form.Control
                            type="text"
                            placeholder={t("member_request_screen_reason_input_hint")}
                            onChange={(e) => props.onChange(e.target.value)}
                        />
                    </Form.Group>
                </Form>


                {/* {props?.subContent && <p>{t(props?.subContent)}</p>} */}

                <div className="report-actions">
                    <Button
                        clear="true"
                        text={t("member_request_screen_close_button_text")}
                        handleClick={() => props.closeModal()}
                    />
                    <Button

                        text={t("group_members_screen_remove_button_text")}
                        handleClick={() => props.onSubmit()}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default GroupMemberRemoveConfirmation;
