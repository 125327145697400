import * as GlobalStore from "lib/store/global";
import { RootState } from ".";

export const mapStateToProps = (state: RootState) => {
  return {
    query: GlobalStore.selectQuery(state),
    location: GlobalStore.selectLocation(state),
    postFilter: GlobalStore.selectPostFilter(state),
    myDefaultGrpId: GlobalStore.selectMyDefaultGrpId(state),
    myGroups: GlobalStore.selectMyGroups(state),
    shouldCallPersonlizedAPI: GlobalStore.selectShouldCallPersonlizedAPI(state),
    numbeOfNotifications: GlobalStore.selectNumbeOfNotifications(state),
    forwardMessage: GlobalStore.selectForwardMessage(state),
    showToggleSearch: GlobalStore.selectShowToggleSearch(state),
    showAddPost: GlobalStore.selectShowAddPost(state),
    showRegionalData: GlobalStore.selectShowRegionalData(state),
    draftMessage: GlobalStore.selectDraftMessage(state),
    publicFeedIcon: GlobalStore.selectPublicFeedIcon(state),
    showPublicPostRight: GlobalStore.selectShowPublicPostRight(state),
    isPublicPersonalize: GlobalStore.selectIsPublicPersonalize(state),
  };
};
