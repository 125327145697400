/* eslint-disable jsx-a11y/iframe-has-title */
import { useState, useEffect } from "react";
import { Card, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../../views/general/generaltab.scss";
import i18n from "i18next";

import BackButton from "../commons/BackButton";
import MyProfile from "./index";
import MyCitizensList from "./MyCitizensList";


const ProfileContainer = (props) => {
  const { t } = useTranslation();
  const language = i18n.language.split("-")[0];

  const [key, setKey] = useState("PROFILE_DATA");

  const handleTabSelect = (param) => {
    setKey(param);
  };

  // let browserLang = navigator.language || navigator.userLanguage
  // let selectedLang = browserLang?.includes("en")
  //   ? 1
  //   : browserLang?.includes("de")
  //   ? 2
  //   : browserLang?.includes("hu")
  //   ? 8
  //   : 2;

  return (
    <>
      <div className="container">
        {console.log("feed", language)}
        {/* <div class="back-btn-cont">
          <BackButton />
        </div> */}
        <Card className="gdpr-card custom-group-card">
          <Card.Body>
            <Tabs 
              justify
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => handleTabSelect(k)}
              unmountOnExit={true}
              // className="custom-width" 
              className="tab-content"
            >
              <Tab
                eventKey="PROFILE_DATA"
                title={t("profile_data_heading")}
                tabClassName=" text-uppercase"
              >
                <MyProfile  {...props} />
              </Tab>
              <Tab
                eventKey="MY_CITIZENS"
                title={t("my_citizens_heading")}
                tabClassName=" text-uppercase"
              >
                <MyCitizensList {...props} />
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default ProfileContainer;
