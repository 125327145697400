import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { dotDateFormatter } from "lib/utils/Commons";
import UserDetails from "../../user-details";

function FollowTabData(props) {
  const { t, i18n } = useTranslation();
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [userId, setUserId] = useState(null);

  const clickOnName = (params) => {
    if (props.originFrom === "child") {
      setUserId(params);
      setShowUserDetails(true);
    } else {
      props.clickOnName(params);
    }
  };

  useEffect(() => {}, [props.favourites]);

  console.log("props", props.favourites);
  return (
    <>
      <div className="comment-section">
        <div className="heading">
          <h3>{t("Following")}</h3>
        </div>
        {props.favourites !== undefined && props.favourites.length > 0 ? (
          <div className="comment-section">
            {props.favourites.map((favourite) => (
              <div className="data-section">
                <div className="left cursor"  onClick={() => clickOnName(favourite.FK_userID)}>
                  <div className="img-cont">
                  <img src= {favourite.USE_pic!==""?favourite.USE_pic:"/images/profile.png"}/>
                  </div>
                  <div className="text-data">
                    <div
                      className="subheader"
                      
                    >
                      {favourite.USE_firstname + " " + favourite.USE_surename}
                    </div>
                    {/* <div className="comment">is following the</div> */}
                  </div>
                </div>
                <div className="right">
                  {dotDateFormatter(favourite.LOF_date)}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>{t("No_data_found")}</div>
        )}
      </div>

      {showUserDetails && (
        <UserDetails
          id={userId}
          {...props}
          handleClose={() => setShowUserDetails(false)}
        />
      )}
    </>
  );
}

export default FollowTabData;
