import React, { Component, useState } from "react";
import "./confirmCreateGroupDialog.scss"
import Button from "../../elements/buttons/Button";
import { Form, Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";

class ConfirmPostDialog extends Component {
  state = {
    message: "",
    content: "",
  };

  componentDidMount() {}

  submitForm = () => {
    this.props.onSubmit();
    this.props.handleClose();
  };
  render() {
    const { t } = this.props;
    console.log('props',this.props); 
    return (
      <>
        <Modal
          centered
          show={this.props.deleting}
          onHide={this.props.handleClose}
          contentClassName="confirm_post_modal"
        >
          <Modal.Body>
            <h2>{t("confirm_create_group")}</h2>
            <>
              {this.props.groupName &&   (
                <p>
                  {t("confirm_create_group_notification")} {this?.props?.groupName !=="" && this.props.groupName + "?"}
                </p>
              ) }
              <div className="report-actions">
                <Button
                  clear="true"
                  text={t("cancel_alert")}
                  handleClick={this.props.handleClose}
                />
                <Button
                  text={t("member_request_screen_confirm_button_text")}
                  handleClick={this.submitForm}
                />
              </div>
            </>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(ConfirmPostDialog);
