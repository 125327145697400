import React, { useEffect, useRef } from "react";
import linkImg from "../../../../assets/link.jpg";
import "./groupTab.scss";
import IconButton from "../../../elements/buttons/IconButton";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import linkifyHtml from "linkify-html";
import ReactHtmlParser from "html-react-parser";
import sanitizeHtml from 'sanitize-html';

// import urlMetadata from "url-metadata";
// const urlMetadata = require('url-metadata')
// var Meta = require("html-metadata-parser");

const Image = ({ url = "none", fallback }) => {
  const imageRef = useRef();

  return (
    <img
      ref={imageRef}
      alt=""
      style={{ padding: "10px" }}
      src={url}
      onError={(e) => (imageRef.current.src = fallback)}
    />
  );
};

const parseHtml = (htmlString='') =>{
  try{
    console.log(htmlString,'qwerty');
    return ReactHtmlParser(sanitizeHtml(htmlString))
  } catch(e){
    console.log('error whilr parsing', htmlString);
    return null
  }
}

const LinkTabGrp = (props) => {
  const history = useNavigate();

  const { t } = useTranslation();

  const mediaWithHeader = React.useMemo(() => {
    const mediaMap = new Map();
    props?.links?.map((data) => {
      let key = moment(data?.LC_created_on).format("MMMM YYYY");
      const d = moment(new Date()).format("MMMM YYYY");
        
           console.log(key,"fasd");
      if (props.search !== "") {
        if (
          data?.LC_message?.toLowerCase()?.includes(props.search.toLowerCase())
        ) {
          if (key === d) {
            key = t("cuurent");
          }
          if (!mediaMap?.has(key)) {
            mediaMap?.set(key, [data]);
          } else {
            mediaMap?.get(key)?.push(data);
          }
        }
      } else {
        if (key === d) {
          key = t("cuurent");
        }
        if (!mediaMap?.has(key)) {
          mediaMap?.set(key, [data]);
        } else {
          mediaMap?.get(key)?.push(data);
        }
      }
    });
    return mediaMap;
  }, [props.links, props.search]);

  // function checkImageValidity(imageUrl) {
  //   return new Promise(function (resolve) {
  //     var image = new Image();
  //     image.onload = function () {
  //       resolve(true); // Image exists
  //     };
  //     image.onerror = function () {
  //       resolve(false); // Image does not exist
  //     };
  //     image.src = imageUrl;
  //   });
  // }

  // Usage example

  // checkImageValidity(imageUrl).then(function (result) {
  //   return
  // });

  console.log({ mediaWithHeader });

  return (
    <div className="mainTabCont">
      <div className="linkInside">
        {mediaWithHeader?.size === 0 ? (
          <div className="noData">{t("No_data_found")}</div>
        ) : (
          [...mediaWithHeader?.keys()]?.map((month, i) => (
            <div className="linkBox">
              <div>
                <p>{month}</p>
              </div>
              {console.log(mediaWithHeader)}
              <div className="linkContainer 332424">
                {mediaWithHeader?.get(month)?.map(
                  (data, i) =>
                  <>
                      
                      <span>
                        {data?.USE_firstname +
                          " " +
                          data?.USE_surename +
                          ", " +
                          moment(data?.LC_created_on)?.format(
                            "YYYY-MM-DD hh:mm:ss"
                          )}
                      </span>
                      <div className="mainLink">
                        {/* <div className="upper">
                        <Image url={me?.META?.icon} fallback={linkImg} />
                        <div className="upperRight">
                          <span className="text">
                            {data?.MetaLinkData
                              ? me?.META?.description
                                ? me?.META?.description
                                : "Link" + " " + (i + 1)
                              : "Link" + " " + (i + 1)}
                          </span>
                          <span className="des">
                          </span>
                        </div>
                      </div> */}
                        <div className="lower">
                          <div className="msg">
                            {/* {console.log(data?.LC_message,"sad")}  */}
                            {/* <a href={data?.LC_message} target="blank" >{data?.LC_message}</a> */}
                            {parseHtml(
                              linkifyHtml(data?.LC_message, {
                                nl2br: true,
                                target: {
                                  url: "_blank",
                                },
                              })
                            )}
                          </div>
                        </div>
                      </div>
                    </>

                )}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default LinkTabGrp;
