import React, { useEffect } from "react";
import "./groupTab.scss";
import IconButton from "../../../elements/buttons/IconButton";
import moment from "moment";
import { downloadChatFiles } from "../../../../api/common";
import { useTranslation } from "react-i18next";

const DocTabGrp = (props) => {
  const { t } = useTranslation();


  const mediaWithHeader = React.useMemo(() => {
    const mediaMap = new Map();
    props.docs.map((data) => {
      let key = moment(data.LC_created_on).format("MMMM YYYY");
      const d = moment(new Date()).format("MMMM YYYY");

      if (props.search !== "") {

        if(data?.CTA_file_name?.toLowerCase()?.includes(props.search.toLowerCase())){
          if (key === d) {
            key = t("cuurent");
          }
          if (!mediaMap.has(key)) {
            mediaMap.set(key, [data]);
          } else {
            mediaMap.get(key).push(data);
          }
        }
        
      } else {
        if (key === d) {
          key = t("cuurent");
        }
        if (!mediaMap.has(key)) {
          mediaMap.set(key, [data]);
        } else {
          mediaMap.get(key).push(data);
        }
      }
    });
    return mediaMap;
  }, [props.docs , props.search]);

  const bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return (bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i];
  };

  const downloadFiles = (data) => {

    console.log("gonee",data)
    console.log("gonee",props)

    console.log("gonee",props.token)
    console.log("gonee",data.chat_messagesID)
    console.log("gonee",props.roomId)

    

    downloadChatFiles(props.token, data.chat_messagesID, data.LC_room_id, "chat");
  };



  return (
    <div className="mainTabCont">
      <div className="docInside">
        {mediaWithHeader?.size === 0 ? (
          <div className="noData">{t("No_data_found")}</div>
        ) : (
          [...mediaWithHeader.keys()].map((month, i) => (
            <div className="monthBox">
              <div>
                <p>{month}</p>
              </div>
              <div className="docContainer">
                {mediaWithHeader.get(month).map((data) => (
                  <div onClick={() => downloadFiles(data)} className="docBox">
                    <div className="details">
                      <span className="title">
                        {data?.CTA_file_name ? data?.CTA_file_name : ""}
                      </span>
                      <span className="detailsBottom">
                        {data?.USE_firstname +
                          " " +
                          data?.USE_surename +
                          " | " +
                          moment(data?.LC_created_on).format(
                            "DD/MM/YYYY HH:mm"
                          ) +
                          " | " +
                          bytesToSize(data?.CTA_properties)}
                      </span>
                    </div>
                    <IconButton
                      icon="download"
                      clear={true}
                      className="float-right"
                      handleClick={() => downloadFiles(data)}
                      //  text={t("delete_menu_option")}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default DocTabGrp;
