import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import persist from "../persist";

export enum ThemeMode {
  LIGHT = "light",
  DARK = "dark",
}

export type UIState = {
  themeMode: ThemeMode;
};

const initialState: UIState = {
  themeMode: ThemeMode.LIGHT,
};

export const revertAll = createAction("REVERT_ALL");

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    themeModeUpdated: (state, action: PayloadAction<ThemeMode>) => {
      state.themeMode = action.payload;
    },
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const { themeModeUpdated } = uiSlice.actions;

export const selectThemeMode = (state: RootState) => state.ui.themeMode;

export default persist("ui", uiSlice.reducer);
