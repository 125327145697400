import { useTranslation } from "react-i18next";
import * as Styled from "./styled";

interface Props {
  isEmpty?: boolean;
  emptyPlaceholder?: JSX.Element;
  children: React.ReactNode;
}

export const HandleEmptyState = ({ isEmpty, emptyPlaceholder, children }: Props) => {
  const { t } = useTranslation();

  if (isEmpty) {
    return (
      <>
        {emptyPlaceholder || (
          <Styled.TextWrapper>
            <Styled.Text>{t("common.no-data")}</Styled.Text>
          </Styled.TextWrapper>
        )}
      </>
    );
  }
  return <>{children}</>;
};
