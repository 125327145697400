import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Button from "../elements/buttons/Button";

const IndicationDialogue = (props) => {
  const { t } = useTranslation();

  return (
    <Modal
      centered
      show={props.show}
      onHide={() => props.closeModal()}
      contentClassName="global-confirmation-modal"
    >
      <Modal.Body>
        <h2>{t(props?.title)}</h2>

        {/* {props?.subContent && <p>{t(props?.subContent)}</p>} */}

        <div className="report-actions">
          <div></div>
          <Button text={t("ok")} handleClick={() => props.onSubmit()} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default IndicationDialogue;
