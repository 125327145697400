import React, { useEffect } from "react";
import natureImg from "../../../../assets/nature.jpg";
import "./groupTab.scss";
import moment from "moment"; 
// import 'moment/locale/de'; // Import German locale
// import 'moment/locale/nl'; // Import Dutch locale
import { useTranslation } from "react-i18next";

const MediaTabGrp = (props) => {

  const { t } = useTranslation();

    // const language = localStorage.getItem("i18nextLng");  

    // if (language === 'de') {
    //   moment.locale('de'); // Set the locale to German
    // } else if (language === 'hu') {
    //   moment.locale('nl'); // Set the locale to Dutch
    // } else {
    //   moment.locale('en'); // Set the locale to English (default)
    // }

  const mediaWithHeader = React.useMemo(() => {
    const mediaMap = new Map();
    props.media.map((data) => {
      let key = moment(data.LC_created_on).format("MMMM YYYY");
      const d = moment(new Date()).format("MMMM YYYY");
      if(key === d){
        key = t("cuurent")
      }
      if (!mediaMap.has(key)) {
        mediaMap.set(key, [data]);
      } else {
        mediaMap.get(key).push(data);
      }
    });
    return mediaMap;
  }, [props.media]);

  console.log({mediaWithHeader})
  

  return (
    <div className="mainTabCont">
      <div className="mediaInside">
        {mediaWithHeader?.length === 0 ? (
          <div className="noData">{t("No_data_found")}</div>
        ) : (
          [...mediaWithHeader.keys()].map((month, i) => (
            <div className="monthWise">
              <div>{<p>{month}</p>} </div>
              {console.log("asdfsdffd",mediaWithHeader)}
              <div className="images">
                {mediaWithHeader.get(month).map((data, idx) => {
                  return (
                    <div className="imgg">
                      <img
                        onClick={() => props.previewImage(mediaWithHeader.get(month), idx)}
                        src={data?.thumbnail} 
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default MediaTabGrp;
