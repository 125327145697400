import React from "react";
import ChatComponent from "../chat/ChatComponent";
import { withRouter } from "components/customNavigate/withRouter";

class GroupChatNew extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;
    
    return <ChatComponent key={this.props.params.id} chattype={"group"} {...this.props} />;
  }
}
// Chat.contextType = MessageContextProvider;
export default withRouter(GroupChatNew);

// compose(
//   withTranslation(),
//   connect(mapStateToProps)
// )(withRouter(withScrollHookHOC(Chat)));
//export default withTranslation()(withRouter(Chat));
