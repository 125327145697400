import React from 'react';
import { Modal } from "react-bootstrap";
import IconButton from '../elements/buttons/IconButton';
import ViewGroup from './ViewGroup';

export const ViewGroupModal = (props) => {
    return (
        <Modal centered show onHide={props.handleClose} className="custom-modal viewGrpModal" >
             <IconButton
                 clear="true"
                 className="closeIcon"
                 icon="close"
                handleClick={props.handleClose}
              />
            <ViewGroup groupId={props.groupId} token={props.token} />
        </Modal>
    )
}


export default ViewGroupModal;
