import React, { Component } from "react";
import { withRouter } from "../customNavigate/withRouter";
import {
  getGroupAbout,
  getGroupPermissions,
  downloadFromUrl,
  joinGroup,
  fetchGroupRules,
} from "../../api/groups";
import Button from "../elements/buttons/Button";

import Checkbox from "../elements/checkbox";
import IconButton from "../elements/buttons/IconButton";
import EditGroup from "./edit-group";
import GroupPortal from "./GroupPortal";
import { Container, Row, Col, Card, Image, Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import BackButton from "../commons/BackButton";
import "./viewgroupnew.scss";
import Loader from "../../components/posts/single-post/PostLoader/postLoader.js";
import Lightbox from "../elements/lightbox";
import linkifyHtml from "linkify-html";
import ReactHtmlParser from "html-react-parser";
import { dotDateFormatter } from "lib/utils/Commons";
import IconLink from "../elements/buttons/IconLink";
class ViewGroup extends Component {
  state = {
    data: {},
    loading: true,
    editing: false,
    showGroupPortal: false,
    groupId: null,
    isShownInModal: false,
    allowGroupPortal: false,
    showGroupModal: false,
    btnText: "join_group",
    showRulesModal: false,
    rules: null,
    inviteCode: "",
    showAlreadyMemberModal: false,
    showResponseModal: false,
    responseText: "",
    promocode: "",
    isMember: "",
    isLightboxOpen: false,
    lightboxMedia: [],
    lightboxIndex: 0,
    showGoToGrpButton: false,
    pendingRequest:false
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.callGetGroupDataAPI();
  }
  onDownloadClick(data) {
    downloadFromUrl(this.props.token, data);
  }

  callGetGroupDataAPI() {
    const query = this.props.location.search;
    let pcode = "";
    let icode = "";
    if (query.includes("pcode")) {
      pcode = query.split("=")[1];
    }

    if (query.includes("gid")) {
      icode = query.split("=")[1];
    }

    this.setState({
      inviteCode: icode,
      promoCode: pcode,
    });

    let groupId = this.props.groupId
   
    if (!groupId) {
      groupId =  this.props?.match.params.id;
    console.log({groupId});


      this.setState({ isShownInModal: true, allowGroupPortal: false });

      getGroupPermissions(this.props.token, groupId).then((res) => {
        if (res.data.OK) {
          this.setState({
            isMember: res.data.data.member,
          }); 

          if(res.data.data.pending === "1"){
            this.setState({
              pendingRequest:true,
              btnText : "Join Pending"
            })
          }
        }
      });
    } else {
      getGroupPermissions(this.props.token, groupId).then((res) => {
        if (res.data.OK) {
          this.setState({
            isMember: res.data.data.member,
            allowGroupPortal: res.data.data.member === "1" && this.state.isShownInModal,
          });   

          if(res.data.data.pending === "1"){
            this.setState({
              pendingRequest:true,
              btnText : "Join Pending"
            })
          }

          if (
            res.data.data.member === "1" &&
            this.state.inviteCode !== "" &&
            this.state.inviteCode !== undefined
          ) {
            this.setState({
              showAlreadyMemberModal: true,
              showGoToGrpButton: true,
            });
          }
        }
      });
    }
    this.setState({ groupId: groupId });
    getGroupAbout(this.props.token, groupId).then((res) => {
      if (res.data.OK) {
        this.setState({ data: res.data.OK, loading: false });
      } else {
        this.setState({ loading: false });
      }
    });
  }
  getDocumentsView(data) {
    // console.log(JSON.stringify(data));
    return (
      <div className="media-box  d-flex ">
        <div className="pr-1">
          {" "}
          <i className="material-icons">attach_file</i>
        </div>
        <div className="text-break">
          {data.name === undefined ? data.LGM_img_name : data.name}
        </div>
        <div>
          {" "}
          <a onClick={() => this.onDownloadClick(data)}>
            <i className="material-icons material-icons-outlined ml-15 greenIcon">
              get_app
            </i>
          </a>
        </div>
      </div>
    );
  }

  showGroupEnterModal() {
    this.setState({
      showGroupModal: true,
    });
  }

  closeGroupEnterModal() {
    this.setState({
      showGroupModal: false,
    });
  }

  joinGroupBtn(group) {
    console.log(group);
    let isNewUser = "n";
    const newUserName = localStorage.getItem("newUserRegister");
    const userInfo = JSON.parse(localStorage.getItem("user"));
    if (newUserName && userInfo && userInfo.Name === newUserName) {
      isNewUser = "y";
      localStorage.removeItem("newUserRegister");
    }

    joinGroup(
      this.props.token,
      group.FK_user_categoryID,
      this.state.promoCode,
      this.state.inviteCode,
      isNewUser
    ).then((res) => {
      console.log(res.data.OK);
      if (res.data.OK === "joined") {
        this.setState({
          btnText: "btn_title_joined",
        });
      } else if (res.data.OK === "joined pending") {
        console.log("called");
        this.setState({
          btnText: "member_request_sent",
        });
      } else {
        this.setState({
          showResponseModal: true,
          responseText: res.data.ER,
        });
      }
    });
    this.closeGroupEnterModal();
  }

  getRulesData = async (group) => {
    this.setState({
      showRulesModal: true,
    });
    try {
      const response = await fetchGroupRules(
        this.props.token,
        group.FK_user_categoryID
      );
      console.log("ruless", response.data);

      if (response.data) {
        const data = response.data;
        if (data.ER) {
          this.setState({
            rules: "Error group does not exist.",
          });
        } else {
          this.setState({
            rules: data.OK,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  closeRulesModal = () => {
    this.setState({
      showRulesModal: false,
    });
  };

  openLightBox = (imageUrl, lightboxIndex = 0) => {
    let media = [{ type: "p", img: imageUrl }];
    if (media && media.length > 0 && imageUrl !== "") {
      this.setState({
        lightboxMedia: media,
        lightboxIndex: lightboxIndex,
        isLightboxOpen: true,
      });
    }
  };

  render() {
    const { t } = this.props;
    const data = this.state.data; 
    console.log(data,"groupA");
    let classNames = "view-group";
    if (!this.state.isShownInModal) {
      classNames += "main-content ";
    }
    return (
      <>
        {this.state.allowGroupPortal && (
          <>
            <div className="newPostBtnHolder">
              <IconButton
                icon="groups"
                text={
                  t("group_portal_nav_title_primary") +
                  " " +
                  t("group_portal_nav_title_secondary")
                }
                type="button"
                handleClick={() => this.setState({ showGroupPortal: true })}
              />
              {data.superAdmin === "1" && (
                <IconButton
                  icon="edit"
                  text={
                    t("edit_group_screen_primary") +
                    " " +
                    t("edit_group_screen_secondary")
                  }
                  type="button"
                  handleClick={() => this.setState({ editing: true })}
                />
              )}
            </div>
          </>
        )}
        {this.state.loading ? (
          <Loader />
        ) : (
          <Container className={classNames}>
            {/* {this.state.isShownInModal && (
              <div className="back-btn-cont">
                <BackButton />
              </div>
            )} */}

            <Row>
              <Col lg={12} >
                <Card className="group-profileCard shadow  bg-white">
                  <div className="card-header"></div>
                  <Card.Body>
                    <Image
                      src={
                        data.UCT_avatar
                          ? data.UCT_avatar
                          : "/images/profile.png"
                      }
                      
                      className="profile-image cursor"
                      onClick={() => this.openLightBox(data.UCT_avatar)}
                    />
                    <div className="grpname">
                      <span className="pl-1">{data.LGP_name}</span>
                    </div>
                    <div className="add">
                      <span>{data.CIT_plz}</span>
                      <span className="pl-1">{data.CIT_name}</span>,
                      <span className="pl-1">
                        {/* <img src={data.country_flag} className="flagimg" /> */}
                        {data.STA_name}
                      </span>
                    </div>
                    <div class="createdat">
                      <span> {t("view_group_since")}</span>
                      <span className="pl-1">
                        
                        {dotDateFormatter(data?.created)}
                      </span>
                    </div>
                    {this.state.isMember === "0" && (
                      <div className="pt-3">
                        {console.log(this.state.btnText)}
                        <Button
                          name="join"
                          text={t(`${this.state.btnText}`)} 
                          disabled={this.state.pendingRequest}
                          handleClick={
                            this.state.btnText === "Join Pending" ||
                            this.state.btnText === "btn_title_joined" ||
                            this.state.btnText === "member_request_sent"
                              ? null
                              : () => this.showGroupEnterModal()
                          }
                          className="newbtn"
                        />
                      </div>
                    )}

                    {this.state.isMember === "1" && (
                      <div className="pt-3">
                        <Button
                          text={t("group_posts")}
                          handleClick={() =>
                            this.props.history.push(
                              "/group/" + this.state.groupId
                            )
                          }
                          className="newbtn"
                        />
                      </div>
                    )}
                  </Card.Body>
                  <Card.Footer className="bg-white">
                    <Row>
                      <Col className="col-4 nopadding text-center rb">
                        <div className="data-label">{t("group_posts")}</div>
                        <div className="data-value">{data.totalGroupPost}</div>
                      </Col>
                      <Col className=" col-4 nopadding text-center rb">
                        <div className="data-label">
                          {t("group_members_screen_title_primary")}
                        </div>
                        <div className="data-value">
                          {data.totalGroupMember}
                        </div>
                      </Col>
                      <Col className=" col-4 nopadding text-center rb">
                        <div className="data-label">{t("shared_posts")}</div>
                        <div className="data-value">{data.totalSharedPost}</div>
                      </Col>
                    </Row>
                  </Card.Footer>
                </Card>
              </Col>
              <Col lg={12}>
                <Card className="group-aboutusCard">
                  <Card.Title>{t("view_group_title")}</Card.Title>
                  <Card.Text>
                    {ReactHtmlParser(
                      linkifyHtml(data.LGP_about ? data.LGP_about : "", {
                        nl2br: true,
                        target: {
                          url: "_blank",
                        },
                      })
                    )}
                  </Card.Text>
                </Card>
                <Card className="group-contactCard pb-10">
                  <Card.Body>
                    <div className="contact-heading pb-2">
                      {" "}
                      {t("add_edit_group_rules_contact")}
                    </div>
                    <div className="contact-section">
                      <div className="d-block">
                        <div className="d-flex  col-lg-12 nopadding">
                          <div className="data-title">
                            {" "}
                            {t("view_group_email")}:
                          </div>
                          <div>
                            <a
                              href={`mailto:${data.LGP_contact}`}
                              className="data-value"
                              target="_blank"
                            >
                              { data.LGP_contact}
                            </a>
                          </div>
                        </div>
                        <div className="d-flex col-lg-12 nopadding">
                          <div className="data-title">
                            {" "}
                            {t("add_edit_group_website")}:
                          </div>
                          <div>
                            <a
                              href={
                                data.LGP_web.includes("http")
                                  ? `${data.LGP_web}`
                                  : `http://${data.LGP_web}`
                              }
                              className="data-value"
                              target="_blank"
                            >
                              {data.LGP_web ? data.LGP_web : ""}
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="d-block sm-pt-3">
                        <div className="d-flex  col-lg-12 nopadding">
                          <div className="data-title">
                            {" "}
                            {t("edit_profile_telephone")}:
                          </div>
                          <div>
                            <span className="data-value">
                              {" "}
                              {data.LGP_phone ? data.LGP_phone : ""}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex col-lg-12 nopadding">
                          <div className="data-title">{t("address")}:</div>
                          <div className="data-value">
                            {data.CIT_plz}&nbsp;&nbsp;{data.CIT_name}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="media-section">
                      <Row className="pt-3">
                        <div className="col-lg-12 ">
                          <div className="heading">
                            {" "}
                            {t("add_edit_group_media_and_documents_title")}
                          </div>

                          <div>
                            <div>
                              {data.LGP_media && data.LGP_media.length > 0 ? (
                                <div>
                                  {data?.LGP_media &&
                                    data.LGP_media.length > 0 && (
                                      <div className="media-box-cont">
                                        {data.LGP_media.map((item, i) =>
                                          this.getDocumentsView(item)
                                        )}
                                      </div>
                                    )}
                                </div>
                              ) : (
                                <div className="no-data"></div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12 ">
                          <div className="heading">
                            {" "}
                            {t("add_edit_group_group_rules_title")}
                          </div>

                          <div>
                            {data.LGP_grouprules &&
                            data.LGP_grouprules.length > 0 ? (
                              <div>
                                {data?.LGP_grouprules &&
                                  data.LGP_grouprules.length > 0 && (
                                    <div className="media-box-cont">
                                      {data.LGP_grouprules.map((item, i) =>
                                        this.getDocumentsView(item)
                                      )}
                                    </div>
                                  )}
                              </div>
                            ) : (
                              <div className="no-data"></div>
                            )}
                          </div>
                        </div>
                      </Row>
                    </div>

                    <div className="setting-section">
                      <div className="heading">
                        {t("add_edit_group_settings_title")}
                      </div>
                      <div className="setting-cont">
                        <div className="ctitle">
                          {" "}
                          {data?.LGP_public === "y"
                            ? t("add_edit_group_type_public")
                            : t("add_edit_group_type_private")}
                        </div>
                        <div className="csubtitle">
                          {" "}
                          {t("add_edit_group_group_admins_title") + ":"}
                        </div>
                        <div className="dmin-data pt-3">
                          {data.LGP_admin?.map((item, i) => (
                            <div className="col-lg-12 pb-2">
                              <Image
                                src={
                                  item.USE_pic === "" || item.USE_pic === null
                                    ? " /images/profile.png"
                                    : item.USE_pic
                                }
                                roundedCircle
                                width='40px'
                                height='40px'
                              />
                              <span className="pl-20">{item.name}</span>
                            </div>
                          ))}
                        </div>

                        <div className="admin-per pt-2">
                          <div className="item">
                            <Checkbox
                              text={t(
                                "add_edit_group_checkbox_member_invite_text"
                              )}
                              value={data.LGP_invite === "y"}
                              handleChange={(e) => {}}
                            />
                          </div>
                          <div className="item">
                            <Checkbox
                              text={t(
                                "add_edit_group_checkbox_member_enter_memberlist_text"
                              )}
                              value={data.LGP_viewmember === "y"}
                              handleChange={(e) => {}}
                            />
                          </div>
                          <div className="item">
                            <Checkbox
                              text={t(
                                "add_edit_group_checkbox_member_create_posts_text"
                              )}
                              value={data.LGP_post === "y"}
                              handleChange={(e) => {}}
                            />
                          </div>
                          <div className="item">
                            <Checkbox
                              text={t(
                                "add_edit_group_checkbox_member_enter_confirmation_text"
                              )}
                              value={data.LGP_confirm === "y"}
                              handleChange={(e) => {}}
                            />
                          </div>

                          <div className="item">
                            <Checkbox
                              text={t(
                                "add_edit_group_checkbox_member_can_share_grp_post"
                              )}
                              value={data.LGP_shareable === "y"}
                              handleChange={(e) => {}}
                            />
                          </div>

                          <div className="item">
                            <Checkbox
                              text={t(
                                "add_edit_group_checkbox_member_can_share_grp_post_in_other_group"
                              )}
                              value={data.LGP_share_to_group === "y"}
                              handleChange={(e) => {}}
                            />
                          </div>

                          <div className="item">
                            <Checkbox
                              text={t(
                                "add_edit_group_checkbox_post_to_public"
                              )}
                              value={data.LGP_posttopublic === "y"}
                              handleChange={(e) => {}}
                            />
                          </div>

                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            {this.state.editing && (
              <EditGroup
                token={this.props.token}
                editing={this.state.editing}
                editedGroupId={this.state.groupId}
                history={this.props.history}
                stopEdit={() =>
                  this.setState({
                    editing: false,
                    editedGroupId: null,
                  })
                }
              />
            )}
          </Container>
        )}

        {this.state.showGroupPortal && (
          <GroupPortal
            showGroupPortal={this.state.showGroupPortal}
            handleClose={() => this.setState({ showGroupPortal: false })}
            groupId={this.state.groupId}
          />
        )}
        {
          <Modal
            centered
            show={this.state.showGroupModal}
            onHide={this.closeGroupEnterModal}
            backdrop="static"
            contentClassName="box"
            className="enter-group-confirm"
          >
            <Modal.Body>
              <h2>{t("enter_group_modal_option")}</h2>

              <>
                <p>
                  {t("confirm_title")}
                  <b className="pl-1">{data.LGP_name} ?</b>
                </p>

                <div>
                  {t("confirm_subtitle")}
                  <b>
                    <a onClick={() => this.getRulesData(data)} className="pl-1">
                      {t("group_rules")}{" "}
                    </a>
                  </b>
                  <span>({t("open_with_click")})</span>
                </div>

                <div className="report-actions pt-3">
                  <Button
                    clear="true"
                    text={t("cancel_alert")}
                    handleClick={() => this.closeGroupEnterModal()}
                  />
                  <Button
                    text={t("enter")}
                    handleClick={() => this.joinGroupBtn(data)}
                  />
                </div>
              </>
            </Modal.Body>
          </Modal>
        }

        {
          <Modal
            centered
            show={this.state.showRulesModal}
            onHide={this.closeRulesModal}
            backdrop="static"
            className="group-rules-modal"
          >
            <Modal.Body>
              <h2>{t("add_edit_group_rules_title")}</h2>

              {this.state.rules === "Error group does not exist." ? (
                <p>{t("no_rules_added")}</p>
              ) : (
                this.state.rules !== null &&
                this.state.rules.length > 0 && (
                  <div className="docs-wrapper">
                    {this.state.rules.map((item, i) =>
                      this.getDocumentsView(item)
                    )}
                  </div>
                )
              )}

              <div className="float-right">
                <Button
                  clear="true"
                  text={t("close")}
                  handleClick={this.closeRulesModal}
                />
              </div>
            </Modal.Body>
          </Modal>
        }

        <Modal
          show={this.state.showAlreadyMemberModal}
          onHide={() => this.setState({ showAlreadyMemberModal: false })}
          contentClassName="box"
          centered
        >
          {/* <Modal.Header closeButton></Modal.Header> */}
          <Modal.Body>
            <div>{t("already_grp_member_title")}</div>

            <div className="float-right pt-3">
              <Button
                text={t("ok")}
                handleClick={() =>
                  this.setState({ showAlreadyMemberModal: false })
                }
              />
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showResponseModal}
          onHide={() => this.setState({ showResponseModal: false })}
          // contentClassName="invite-modal"
          // dialogClassName="invite-modal"
          className="invite-modal response"
        >
          <IconButton
            clear="true"
            className="pr-1 pt-1 close-modal"
            icon="close"
            handleClick={() => this.setState({ showResponseModal: false })}
          />
          <Modal.Body>{this.state.responseText}</Modal.Body>
        </Modal>

        {this.state.isLightboxOpen && (
          <Lightbox
            onClose={() => this.setState({ isLightboxOpen: false })}
            media={this.state.lightboxMedia}
            index={this.state.lightboxIndex}
            setIndex={(i) => this.setState({ lightboxIndex: i })}
          />
        )}
      </>
    );
  }
}
//

export default withTranslation()(withRouter(ViewGroup));
