import React, { useEffect, useRef, useState } from "react";
import { Form, Modal } from "react-bootstrap";

import IconButton from "../../elements/buttons/IconButton";
import Webcam from "react-webcam";

import "./chatmodal.scss";

import { videoURLtoFile } from "lib/utils/Commons";
import Button from "../../elements/buttons/Button";
import { useTranslation } from "react-i18next";

// let recorder;
let thumbBase64Data;

const VideoCapture = (props) => {
  const { t, i18n } = useTranslation();
  const [caption, setCaption] = useState("");
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const [capturing, setCapturing] = React.useState(false);
  const [recordedChunks, setRecordedChunks] = React.useState([]);
  const [isRecordingComplete, setRecordingComplete] = useState(false);
  const [snap, setSnap] = useState("");

  const handleStartCaptureClick = React.useCallback(() => {
    setCapturing(true);
    setRecordedChunks([]);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    webcamRef.current.video.muted = true;
    webcamRef.current.video.volume = 0;
    // console.log("stream", webcamRef.current);
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setSnap(imageSrc);
    videoURLtoFile(imageSrc, "thumnail.jpeg", "jpeg").then((file) => {
      thumbBase64Data = file;
    });
    console.log({ mediaRecorderRef });
    mediaRecorderRef.current.stop();
    setCapturing(false);
    webcamRef.current.stream.getTracks().forEach(function (track) {
      track.stop();
    });
    setRecordingComplete(true);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const onSendClick = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      const url = URL.createObjectURL(blob);

      videoURLtoFile(url, "videoRecording.mp4", "webm")
        .then((file) => {
          let myFile = { file: file, type: "v", fthumbnail: thumbBase64Data };
          // console.log("myfiie", myFile);
          props.onSendClick([myFile], caption);
          // props.closeModal();
        })
        .catch((e) => {
          props.closeModal();
        });
    }
  }, [recordedChunks, caption]);

  //webcam

  useEffect(() => {
    // if (!window.MediaRecorder) {
    //   alert("MediaRecorder not supported please upgrade your browser");
    // }
  }, []);

  const videoConstraints = {
    facingMode: "user",
  };
  return (
    <>
      <Modal
        centered
        show
        onHide={props.closeModal}
        className="custom-modal attachment videomodel"
      >
        <IconButton
          clear="true"
          className="closeIcon"
          icon="close"
          handleClick={props.closeModal}
        />

        <>
          {!window.MediaRecorder ? (
            <div class="media-recorder-error">
              {t("mediarecorder_error_title")}
            </div>
          ) : (
            <div>
              {snap !== "" ? (
                <img style={{height:'100%',width:'100%'}} src={snap} />
              ) : (
                <Webcam 
                  audio={true}
                  ref={webcamRef}
                  forceScreenshotSourceSize={true}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                />
              )}

              <>
                {!isRecordingComplete && (
                  <div className="capture-btn">
                    {capturing ? (
                      <Button
                        text={t("stop_video_recording_title")}
                        handleClick={handleStopCaptureClick}
                      />
                    ) : (
                      <Button
                        text={t("start_video_recording_title")}
                        handleClick={handleStartCaptureClick}
                      />
                    )}
                  </div>
                )}
              </>
            </div>
          )}
        </>

        {recordedChunks.length > 0 && (
          <div>
            <div className="caption p-10">
              <Form>
                <Form.Group size="lg" controlId="topic">
                  <Form.Control
                    autoFocus
                    type="text"
                    placeholder={t("add_caption_title")}
                    value={caption}
                    onChange={(e) => setCaption(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </div>
            <div className="report-actions m-20">
              <Button
                clear="true"
                text={t("cancel_alert")}
                handleClick={props.closeModal}
              />
              <Button
                clear="true"
                text={t("send_button")}
                handleClick={onSendClick}
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default VideoCapture;
