import IconButton from "components/elements/buttons/IconButton";
import * as Styled from "./style";
import { useEffect, useState } from "react";
import { useSocketContext } from "lib/context/socketContext";
// import { useAuthState } from "lib/context";
import moment from "moment";
import { LocciAvatar } from "components/commons/LocciAvatar";
import { useNavigate, useParams } from "react-router-dom";
import * as GlobalStore from "lib/store/global";
import { useAppSelector } from "lib/store";
import { useTranslation } from "react-i18next";


type ChatItemProps = {
    Admin: string;
    CAR_avatar: string;
    CAR_room: string;
    CAR_type: string;
    CTA_file_name: string|null;
    LCM_attachment_type: string|null;
    LCM_fromId: string;
    LCM_is_deleted: string|null;
    LCM_read: string;
    LCM_text: string;
    LC_created_on: string;
    LC_message_type: string;
    UOL_lasttime: string;
    UOL_status: string;
    USE_firstname: string;
    USE_pic: string;
    USE_surename: string;
    Unread: string;
    chat_roomID: string;
    userID: string;
};

const ChatBar = () => {

    // const userDetails = useAuthState();
    const navigate = useNavigate();
    const location = useAppSelector(GlobalStore.selectLocation);
    const params = useParams();
    const { t } = useTranslation();
    const { webScoketRef, initializeWebSocket } = useSocketContext();
    const [socket, setSocket] = useState<WebSocket | null>(null);
    const [unread, setUnread] = useState(false);
    const [showContent, setShowContent] = useState(false);
    const [bubbleState, setBubbleState] = useState(false);
    const [inbox, setInbox] = useState([]);

    useEffect(() => {
        if (webScoketRef?.current == null) {
            initializeWebSocket();
        } else {
            setSocket(webScoketRef?.current);
        }

        const localBubbleState = localStorage.getItem("bubble-state");

        if (localBubbleState !== null) {
            setBubbleState(JSON.parse(localBubbleState));
        }
    }, []);

    useEffect(() => {
        if (webScoketRef?.current?.readyState !== socket?.readyState) {
            setSocket(webScoketRef?.current);
        }
    }, [webScoketRef?.current]);

    useEffect(() => {
        initSocket();

        return () => {
            socket?.removeEventListener("message", onMessageListener);
            socket?.removeEventListener("open", onSocketOpen);
        };
    },[socket, socket?.readyState]);

    useEffect(() => {
        if(showContent) {
            getChatList();
        }
    }, [showContent]);

    useEffect(()=>{
        if(location.includes("/inbox")) {
            setShowContent(false);
        } else {
            setShowContent(bubbleState);
        }
    }, [location]);

    useEffect(() => {
        hasUnread();
    }, [inbox]);

    const initSocket = () => {

        if (socket?.readyState === WebSocket.OPEN) {
            getChatList();
        }

        if (socket) {
            socket.addEventListener("open", onSocketOpen);
            socket.addEventListener("message", onMessageListener);
        }
    };

    const onSocketOpen = () => {
        getChatList();
    };

    const onMessageListener = (event: MessageEvent) => {
        const data = JSON.parse(event.data);
        // console.log("Data received", data.event, data);

        if (data.event == "get_user_chat_list") {     
            setInbox( sortList(data.list) );
        } else if (data.event == "send_chat" || data.event == "upload_attachment") {
            getChatList();
        }
    };

    const sortList = (data: []) => {
        const unreadmessage = data
          ?.filter((item: ChatItemProps) => item.LCM_read === "n")
          .sort(
            (a: ChatItemProps, b: ChatItemProps) =>
              new Date(b.LC_created_on).getTime() -
              new Date(a.LC_created_on).getTime()
          );
        const readmessages = data
          ?.filter((item: ChatItemProps) => item.LCM_read === "y")
          .sort(
            (a: ChatItemProps, b: ChatItemProps) =>
              new Date(b.LC_created_on).getTime() -
              new Date(a.LC_created_on).getTime()
          );
    
        return [...unreadmessage, ...readmessages];
    };

    const hasUnread = () => {
        const unread = inbox?.filter( (item: ChatItemProps) => parseInt(item.Unread) > 0 );

        setUnread(unread.length > 0);
    };

    const getChatList = () => {
        if(socket) {

            if ((socket.readyState === WebSocket.OPEN)) {
                const param = {
                    event: "get_user_chat_list",
                    search: "",
                };
    
                socket.send(JSON.stringify(param)); 
            }
        }
    };

    const getAvatarUrl = (item: ChatItemProps) => {
        return item.CAR_type === "p" ? item.USE_pic : item.CAR_avatar;
    };

    const formatDate = (date: string) => {
        return moment(date, "yyyy-MM-DD hh:mm:ss").isSame(moment(), "day") 
        ? moment(date, "yyyy-MM-DD hh:mm:ss").format("HH:mm") 
        : moment(date, "yyyy-MM-DD hh:mm:ss").format("DD/MM/YYYY");
    };

    const openChatRoom = (item: ChatItemProps) => {
        if (item.CAR_type === "g") { 
            navigate({
                pathname: `/group-chat/${item.chat_roomID}`,
            });
        } else { 
            navigate({
                pathname: `/chat/${item.userID}`,
            });
        }

        item.Unread = "0";
        
        hasUnread();
    };

    const isSelected = (item: ChatItemProps) => {
        if(location.includes("/group-chat/")) {
            if (params.id == item.chat_roomID && item.CAR_type == "g") {
                item.Unread = "0";
                return true;
            }
        } else if(location.includes("/chat/")) {
            if (params.id == item.userID && item.CAR_type == "p") {
                item.Unread = "0";
                return true;
            }
        }

        return false;
    };

    const attachementIconWithText = (item: ChatItemProps) => {
        let icon;

        switch (item.LCM_attachment_type) {
            case "a": icon = "mic"; break;
            case "p": case "v": icon = "photo_camera"; break;
            case "d": icon = "attachment"; break;
            case "c": icon = "contacts"; break;
            default: icon = "attachment"; break;
        }
        
        return (
            <IconButton 
                icon={icon}
                clear={true}
                className="last-message"
            />
        );
    };

    const showBubbleList = (state: boolean) => {
        localStorage.setItem("bubble-state", JSON.stringify(state));
        setShowContent(state);
        setBubbleState(state);
    };

    return (
        <Styled.ChatWidget>
            <Styled.ChatHeader onClick={()=> showBubbleList(!showContent)}>
                <div>{t("inbox_messaging")}</div>
                <div className="header-actions">
                    {unread && <Styled.NewBadge />}
                    <IconButton
                        clear={true}
                        icon={showContent ? "keyboard_arrow_down" : "keyboard_arrow_up"}
                    ></IconButton>
                </div>
            </Styled.ChatHeader>

            <Styled.ChatContainer $show={showContent}>
                <Styled.ChatList>
                    {inbox.map((item: ChatItemProps, index) => (
                        <Styled.ChatListItem 
                            key={index} 
                            $unread={item.Unread} 
                            $isSelected={isSelected(item)}
                            onClick={() => openChatRoom(item)}
                        >
                            <LocciAvatar
                                title={item.CAR_room || (item?.USE_firstname + " " + item?.USE_surename)}
                                picture={getAvatarUrl(item)}
                                size={"45px"}
                                onAvatarClick={() => {}}
                            />
                            <div className="card-content">
                                <div className="card-content-item d-flex justify-space-between">
                                    <h3 className="text-truncate">{item?.CAR_room
                                        ? item?.CAR_room
                                        : item.USE_firstname + " " + item.USE_surename}</h3>
                                    <div className="small">
                                        {formatDate(item?.LC_created_on)}
                                    </div>
                                </div>
                                <div className="card-content-item d-flex justify-space-between">
                                    <p className="bubble-message">
                                        {item.LCM_attachment_type && attachementIconWithText(item)}
                                        {item.LCM_text && item.LCM_text.substring(0, 175)}
                                        {item.LCM_text.length > 175 && "..."}
                                    </p>
                                    {parseInt(item.Unread) > 0 && <Styled.NewBadge />}

                                </div>

                            </div>
                        </Styled.ChatListItem>
                    ))}
                </Styled.ChatList>
            </Styled.ChatContainer>

        </Styled.ChatWidget>
    );
};

export default ChatBar;