// import html2canvas from 'html2canvas';

export const generateVideoThumbnails = async (videoFile, numberOfThumbnails) => {
    return new Promise(async (resolve, reject) => {
      if (!videoFile.type?.includes('video')) {
        reject('Not a valid video file');
        return;
      }
  
      const video = document.createElement('video');
      video.src = URL.createObjectURL(videoFile);
  
      video.onloadedmetadata = async () => {
        const duration = Math.floor(video.duration);
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
  
        const thumbnailPromises = [];
        const thumbnails = [];

        try{
          for (let i = 0; i < numberOfThumbnails; i++) {
            const time = (i / (numberOfThumbnails - 1)) * duration;
            video.currentTime = time;
            await new Promise((resolve) => video.addEventListener('seeked', resolve));
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            const thumbnailDataUrl = canvas.toDataURL('image/jpeg');
            thumbnails.push(thumbnailDataUrl);
          }
          resolve(thumbnails);
        } catch (error) {
          reject(error); // Forward the error to the outer promise
        }
  
      };
    });
  };
  

// const getVideoThumbnail = async (video, time, index) => {
//   return new Promise((resolve, reject) => {
//     video.currentTime = time;

//     console.log('Capturing thumbnail for time:', time);
//     html2canvas(video, {
//       useCORS: true,
//     })
//       .then((canvas) => {
//         console.log('Thumbnail captured successfully');
//         // Rest of the code
//       })
//       .catch((err) => {
//         console.error('Error capturing thumbnail:', err);
//         reject(err);
//       });
//   });
// };

// const dataURLtoBlob = (dataURL) => {
//   const arr = dataURL.split(',');
//   const mime = arr[0].match(/:(.*?);/)[1];
//   const bstr = atob(arr[1]);
//   let n = bstr.length;
//   const u8arr = new Uint8Array(n);

//   while (n--) {
//     u8arr[n] = bstr.charCodeAt(n);
//   }

//   return new Blob([u8arr], { type: mime });
// };
