import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import { determineLanguageAPI } from '../../i18n';
import BackButton from '../commons/BackButton';
import { Card, Tab, Tabs } from 'react-bootstrap';
import OverUs from "../../components/OverUs/index"

export const FAQAndSupport = (props) => {
  const [key, setKey] = useState("faq");

    const { t } = useTranslation();
    const [link, setLink] = useState("https://www.locci.cc/faq_en");


    useEffect(() => {       
        console.log('lan', i18n.language); 
        if(i18n.language!=='en-GB')
        {
            const language=i18n.language.split('-')[0]

          setLink("/faq_"+ language);
        }
    }, [i18n.language])

    const handleTabSelect = (param) => {
        setKey(param);
      };

    return (

        
        

      <div className="container">
        {/* <div class="back-btn-cont">
          <BackButton />
        </div> */}

        <Card className="gdpr-card">
          <Card.Body>
            <Tabs 
              justify
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => handleTabSelect(k)}
              unmountOnExit={true}
              // className="custom-width" 
              className="tab-content"
            >
              <Tab
                eventKey="faq"
                title={t('FAQ_support')}
                // tabClassName="col-lg-6"
              >
            <div className="main-content-narrow">
              {/* {console.log('link',link)} */}
                <div className="html-content" >
                    <iframe src={link} frameBorder={0} width={"100%"} className={"iframe"} />
                </div>
            </div>
              
              </Tab>
              <Tab
                eventKey="Over_us"
                title={t("Over_us")}
                // tabClassName="col-lg-6"
              >
            <OverUs/>
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </div>
    )
}


export default FAQAndSupport;
