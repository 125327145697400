import React, { useState } from "react";

type SafeImageProps = {
    src: string;
    alt: string;
  };

function SafeImage({ src, alt }: SafeImageProps) {
  const [visible, setVisible] = useState(true);

  const handleError = () => {
    setVisible(false);
  };

  if (!visible) {
    return null;
  }

  return <img src={src} alt={alt} onError={handleError} style={{ display: visible ? "block" : "none" }} />;
}

export default SafeImage;