import styled, { css } from "styled-components";

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: space-between;
  flex: 1;
  overflow-x:auto;
  overflow-y:hidden;
`;

export const Tab = styled.a<{ $active?: boolean }>`
  width: 100%;
  height: 30px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.3px;
  margin-bottom: 0px;
  transition: all 0.3s ease;
  color: var(--color-text-secondary);

  ${(props) =>
    props.$active
      ? css`
          color: var(--color-text);
          border-bottom: 2px solid var(--color-text);
        `
      : css`
          color: #687684 !important;
        `}

  &:hover {
    color: #000 !important;
    border-color: var(--color-text);
  }
`;

export const CardHeading = styled.h5`
  color: #bcbcbc;
  font-size: 16px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 14px;
`;

export const CardDevider = styled.hr`
  width: 100%;
  height: 1px;
  border: none;
  background-color: #e0ebc6;
  margin: 30px 0;
  @media ${({ theme }) => theme.mediaQueries.phone} {
    margin: 15px 0;
  }
`;

export const CardText = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: #000000;
`;

export const InfoContactList = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
`;

export const InfoContactListItem = styled.a`
  line-height: 31px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  display: inline-flex;
  border-radius: 10px;
  text-decoration: none;
  padding-right: 15px;
  transition: all 0.5s ease;

  span {
    background-color: var(--primary-color);
    border-radius: 10px;
    width: 31px;
    height: 31px;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    i {
      font-size: 20px;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
    color: #000;
  }

  &.disabled {
    pointer-events: none;
    color: #a3ab90;
    opacity: 0.7;
  }
`;

export const PersonList = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  // flex-wrap: wrap;
`;
export const PersonListItem = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  max-width: 300px;

  img {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }

  span {
    // font-size: 20px;
    color: #000;
    line-height: 20px;
  }
`;

export const FormGroupList = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  border-radius: 16px;

  label {
    width: calc(100% - 20px);
    margin-left: 20px;
    padding: 20px 20px 20px 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    border-bottom: 1px solid #e3e3e3;

    &:last-child {
      border-bottom: none;
    }

    .MuiFormControlLabel-label,
    .MuiFormControlLabel-label.Mui-disabled {
      color: #000;
    }
  }
`;

export const FeedContainer = styled.div<{ $maxWidth?: string; $width?: string }>`
  margin: 0 auto;
  ${(props) => props.$width && `width: ${props.$width};`}
  ${(props) => props.$maxWidth && `max-width: ${props.$maxWidth};`}
`;

export const LeftGrid = styled.div<{ $smShow?: boolean; }>`
  flex: 1;
  padding-right: 20px;

  @media (max-width: 768px) {
    display: none;

    ${(props) => props.$smShow && css`
      display: block;
      padding-right: 0px;
    `}
  }
`;

export const RightGrid = styled.div<{ $smShow?: boolean; }>`
  flex: 1;
  padding-left: 20px;

  @media (max-width: 768px) {
    display:none;

    ${(props) => props.$smShow && css`
      display: block;
      padding-right: 0px;
      max-width: 550px;
      width: 100%;
      padding: 0;
      margin: 0 auto 10px;
      order: -1;
    `}
  }
`;

export const WidgetCard = styled.section<{$sticky?:boolean}>`
  width: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  display:flex;
  flex-direction: column;
  align-items: center;

  ${(props) => props.$sticky && css`
    position: sticky;
    top: calc(var(--header-height) + 51px);
  `}
`;

export const TwitterTextStyles = {
  linkStyle: {
    color: "var(--color-text-link)",
    cursor: "pointer",
  },
  hashtagStyle: {
    color: "var(--color-text-link)",
    cursor: "pointer",
  },
  mentionStyle: {
    color: "var(--color-text-link)",
    cursor: "pointer",
  },
};