import React, { Component, useState } from "react";
import "../report-post/ReportPost.scss";
import Button from "../../elements/buttons/Button";
import { Form, Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";

class CommentDeleteDialog extends Component {
  state = {
    message: "",
    content: "",
  };

  componentDidMount() {}

  deleteComment = () => {

    this.props.onSubmit();
    this.props.handleClose();
  };
  render() {
    const { t } = this.props;
    return (
      <>
        <Modal
          centered
          show={this.props.deleting}
          onHide={this.props.handleClose}
        >
          <Modal.Body>
            <h2>{t('delete_comment_title')}</h2>
              <>
                <p>{t('comment_delete_confirm')}</p>

                <div className="report-actions">
                  <Button
                    clear="true"
                    text={t("cancel_alert")}
                    handleClick={this.props.handleClose}
                  />
                  <Button text={t('member_request_screen_confirm_button_text')} handleClick={this.deleteComment} />
                </div>
              </>
            
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(CommentDeleteDialog);
