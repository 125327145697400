import React, { Component } from "react";
import { Form, Modal } from "react-bootstrap";
import { getSettings, saveSettings } from "../../api/user";
import Button from "../elements/buttons/Button";
import "react-datepicker/dist/react-datepicker.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./Settings.scss";
import moment from "moment";
import Checkbox from "../elements/checkbox";
import { withTranslation } from "react-i18next";
import BackButton from "../commons/BackButton";
import IconButton from "../elements/buttons/IconButton";
import { connect } from "react-redux";
import { mapStateToProps } from "lib/store/mapStateToProps";


class Settings extends Component {
  state = {
    currentDistance: 1000,
    response: null,
    showModal: false,
  };

  constructor(props) {
    super(props);
    this.minDistance = 0;
    this.maxDistance = 2000;
  }
  

   
  componentDidMount() {
    if (this.props.token) {
      this.getData();
    }
  }

  closeModal() {
    this.setState({
      showModal: !this.state.showModal,
    });
  }   

   
  getData() {
    getSettings(this.props.token).then((res) => {
      if (res.data.OK) {
        const data = res.data.data;
        this.setState({
          isNewFromFav: data.info_favorite == "y",
          isCommentFromFavPosts: data.info_comment == "y",
          isNewLike: data.info_likes == "y",
          isInbox: data.info_mail == "y",
          isNewFollower: data.info_follow == "y",
          isPostFromRegion: data.info_region == "y",
          isNewMember: data.info_group_join == "y",
          isNewPostInGroup: data.info_group == "y",
          isEmailNotification: data.info_permail == 'y',
          currentDistance: data.radius,
        });
      }
    });
  }

  submitSettingsForm(e) {
    e.preventDefault();

    saveSettings(this.props.token, this.state, 'settings').then((res) => {
      if (res.data.OK) {
        this.setState({
          showModal: true,
          response: res.data.OK,
          editing: false,
        });
      } else {
        this.setState({
          showModal: true,
          response: res.data.ER,
        });
      }
    });
  }

  render() {
    const { t } = this.props;
    const editing = this.state.editing;
    const data = this.state.profile;
    const birthDate =
      this.state.birthday !== "0000-00-00"
        ? moment(this.state.birthday, "YYYY-MM-DD").toDate()
        : moment.now();

    const states = this.state.countries;
    const languages = this.state.languages;

    const myLang = this.state.myLang;
    const myState = this.state.myState; 
    console.log(this.props.showRegionalData,"0000");
    return (
      <div className="my-settings main-content">
        <div className="main-content-narrow">
          {/* <div className="ctaBack">
            <BackButton />
          </div> */}
          <div className="my-settings-box">
            <div className="my-settings-content">
              <Form
                className="settingsForm"
                onSubmit={(e) => this.submitSettingsForm(e)}
              >
                {this.props.showRegionalData&&(<>
                  <h2 className="m-0">{t("settings_title_radius")}</h2>
                <p>
                  {t("settings_radius_text")}{" "}
                  <strong>{this.state.currentDistance}</strong> {t("unit_km")}
                </p>
                <div className="slider-wrapper">
                  <Slider
                    min={this.minDistance}
                    max={this.maxDistance}
                    value={this.state.currentDistance}
                    onChange={(e) => this.setState({ currentDistance: e })}
                    onAfterChange={(e) => this.setState({ currentDistance: e })}
                  />

                  <div className="slider-labels">
                    <span>
                      {this.minDistance} {t("unit_km")}
                    </span>
                    <span>
                      {this.maxDistance / 2}
                      <br />
                      {t("unit_km")}
                    </span>
                    <span>
                      {this.maxDistance} {t("unit_km")}
                    </span>
                  </div>
                </div> 
                </>)
                }
 { this.props.showRegionalData&&(<>
                <h2 className="m-0">
                  {t("settings_title_push_notifications")}
                </h2>      
                <Form.Group size="lg" controlId="isNewFromFav">
                  <Checkbox
                    text={t("settings_option_favorites_post")}
                    value={this.state.isNewFromFav}
                    handleChange={(e) =>
                      this.setState({ isNewFromFav: !this.state.isNewFromFav })
                    }
                  />
                </Form.Group>

                <Form.Group size="lg" controlId="isCommentFromFavPosts">
                  <Checkbox
                    text={t("settings_option_favorites_post_comment")}
                    value={this.state.isCommentFromFavPosts}
                    handleChange={(e) =>
                      this.setState({
                        isCommentFromFavPosts:
                          !this.state.isCommentFromFavPosts,
                      })
                    }
                  />

                </Form.Group>

                <Form.Group size="lg" controlId="isNewLike">
                  <Checkbox
                    text={t("settings_option_message_like")}
                    value={this.state.isNewLike}
                    handleChange={(e) =>
                      this.setState({ isNewLike: !this.state.isNewLike })
                    }
                  />
                </Form.Group>

                <Form.Group size="lg" controlId="isNewFollower">
                  <Checkbox
                    text={t("settings_option_followers")}
                    value={this.state.isNewFollower}
                    handleChange={(e) =>
                      this.setState({
                        isNewFollower: !this.state.isNewFollower,
                      })
                    }
                  />
                </Form.Group>

                <Form.Group size="lg" controlId="isPostFromRegion">
                  <Checkbox
                    text={t("settings_option_posts_in_region")}
                    value={this.state.isPostFromRegion}
                    handleChange={(e) =>
                      this.setState({
                        isPostFromRegion: !this.state.isPostFromRegion,
                      })
                    }
                  />
                </Form.Group> </>)}

        
                {/* <Form.Group size="lg" controlId="isNewMember">
                  <Checkbox
                    text={t("settings_option_group_members")}
                    value={this.state.isNewMember}
                    handleChange={(e) =>
                      this.setState({ isNewMember: !this.state.isNewMember })
                    }
                  />
                </Form.Group> */}

                {/* <Form.Group size="lg" controlId="isNewPostInGroup">
                  <Checkbox
                    text={t("settings_option_group_post")}
                    value={this.state.isNewPostInGroup}
                    handleChange={(e) =>
                      this.setState({
                        isNewPostInGroup: !this.state.isNewPostInGroup,
                      })
                    }
                  />
                </Form.Group> */}

                <hr />

                <h2 className="m-0">
                  {t("chat_notifiation")}
                </h2>

                <Form.Group size="lg" controlId="isInbox">
                  <Checkbox
                    text={t("settings_option_private_messages")}
                    value={this.state.isInbox}
                    handleChange={(e) =>
                      this.setState({ isInbox: !this.state.isInbox })
                    }
                  />
                </Form.Group>
                <hr />


                <h2 className="m-0">
                  {t("settings_title_email_notifications")}
                </h2>

                <Form.Group size="lg" controlId="isEmailNotification">
                  <Checkbox
                    text={t("settings_option_email")}
                    value={this.state.isEmailNotification}
                    handleChange={(e) =>
                      this.setState({
                        isEmailNotification: !this.state.isEmailNotification,
                      })
                    }
                  />
                </Form.Group>

                <div className="settings-actions">
                  <Button type="submit" text={t("save")} />
                </div>
              </Form>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.showModal}
          onHide={() => this.closeModal()}
          className="custom-modal"
        >
          <IconButton
            clear="true"
            className="closeIcon"
            icon="close"
            handleClick={() => this.closeModal()}
          />

          {t(this.state.response)}
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(connect(mapStateToProps)(Settings));
