import * as apiGroups from "api/groups";
import * as apiPosts from "api/posts";
import { getUserPostById } from "api/user";
import { Card } from "components/shared/card";
import { DotFlashing } from "components/shared/dot-flashing";
import SinglePost from "components/single-group/feed/SinglePost";
import { useInfiniteScroll } from "lib/hooks/useInfiniteScroll";
import { useAppDispatch, useAppSelector } from "lib/store";
import * as GlobalStore from "lib/store/global";
import { GroupPermissionData, GroupWithPostData } from "lib/types/groupTypes";
import { PostData } from "lib/types/postTypes";
import { logError } from "lib/utils/logError";
import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import locciIcon from "../../assets/newsIcon.png";
import MyGroupModal from "components/groups/MyGroupModal";
import { LocciAvatar } from "../commons/LocciAvatar";
import Button from "../elements/buttons/Button";
import GroupPortal from "../groups/GroupPortal";
import ViewGroupModal from "../groups/ViewGroupModal";
import EditGroup from "../groups/edit-group";
import AnalysePostDialog from "../posts/analysePost/analysePostDialog";
import DeletePost from "../posts/deletePost/DeletePost";
import EditPostContainer from "../posts/edit-post/EditPostContainer";
import ReportPost from "../posts/report-post";
import UserDetails from "../user-details";
import ActionBar from "components/actionBar";

enum POST_ACTION_TYPE {
  ANALYSE = "analyse",
  DELETE = "delete",
  EDIT = "edit",
  REPORT = "report",
}

type Props = {
  token: string;
  userId?: string;
  groupId?: string;
  postId?: string;
  publicPosts?: boolean;
  groupPerData?: GroupPermissionData;
  singlePost?: boolean;
  isGroupMember?: boolean;
};

type PostGroupProps = {
  FK_user_categoryID?: string,
  LGP_public?: string,
  LGP_posttopublic?: string,
};

type StateProps = {
  editSettings: boolean;
  showGroupPortal: boolean;
  canCreatePost: boolean;
  query: string;
  groupData?: {
    groupName: string;
    avatar: string;
    isPostShareAble: boolean;
  };
  isGroupInfo: boolean;
  groupId?: string | null;
  userId?: string;
  postId?: string;
  showSideButtons: boolean;
  noDatatitle: string;
  noAuthError: string;
  showSharedGroups: boolean;
  sharedPostId: number | null;
  showNotGroupMember: boolean;
  posts: PostData[];
  loading: boolean;
  offset: number;
  isUserDetails: boolean;
  userDetailsID?: number;
  userDetailsPost?: number;
  postGroup?: PostGroupProps;
  mySharedGroups?: GroupWithPostData[];
  reportedPost?: string | number;

  isReported?: boolean;
  isDeleted: boolean;
  isCompleted: boolean;
};

const Feed = (props: Props) => {
  const params = useParams<{ p: string }>();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loadingRef = useRef<HTMLDivElement>(null);
  const query = useAppSelector((state) => state.global.query);
  const [postActionType, setPostActionType] = useState<POST_ACTION_TYPE | null>(null);
  const [postActionData, setPostActionData] = useState<any>(null);
  const [referredPost, setReferredPost] = useState<string | null>(null);
  const [state, setState] = useState<StateProps>({
    editSettings: false,
    showGroupPortal: false,
    canCreatePost: true,
    query: "",
    groupData: undefined,
    isGroupInfo: false,
    groupId: null,
    showSideButtons: true,
    userId: undefined,
    postId: undefined,
    noDatatitle: "",
    noAuthError: "",
    showSharedGroups: false,
    sharedPostId: null,
    showNotGroupMember: false,
    posts: [],
    loading: true,
    offset: 0,
    isUserDetails: false,
    userDetailsID: undefined,
    userDetailsPost: undefined,
    reportedPost: undefined,
    isReported: false,
    isDeleted: false,
    isCompleted: false,
  });

  const publicFeedIcon = useAppSelector(GlobalStore.selectPublicFeedIcon);
  // const showPublicPostRight = useAppSelector(GlobalStore.selectShowPublicPostRight);
  const PUBLIC_GROUP_DATA = {
    id: 0,
    name: t("regional_feed_title"),
    userid: "public",
    admin: "public",
    superAdmin: "public",
    userpic: publicFeedIcon,
    allow_post: "1",
    promo: "public",
    UCM_IsPersonalized: "public",
    community: "public",
    created: "2023-02-17 08:30:17",
  };

  useInfiniteScroll(loadingRef, () => {
    if (state.loading) return;
    if (state.isCompleted) return;

    if (props.postId === undefined && props.userId === undefined && !state.showSharedGroups) {
      setState((prev) => ({
        ...prev,
        loading: true,
      }));
      callGetPostsAPI();
    } else if (props.userId !== undefined) {
      setState((prev) => ({
        ...prev,
        loading: true,
      }));
      getUserPosts();
    }
  });

  useEffect(() => {
    const { groupId } = props;
    if (props.userId === undefined && groupId === undefined) {
      setReferredPost(params.p ?? null);
    }

    if (groupId) {
      setState((prev) => ({ ...prev, groupId }));
      initGroupData();
    }
  }, [props.token]);

  useEffect(() => {
    const { groupId, userId, postId, singlePost } = props;
    if (state.groupId !== groupId && !singlePost) {
      initGroupData();
    }

    if (
      state.query !== query &&
      userId === undefined &&
      postId === undefined &&
      state.groupId === groupId
    ) {
      setState((prev) => ({ ...prev, query: query, offset: 0 }));
      callGetPostsAPI(true);
    }

    if (userId !== undefined && state.userId !== userId) {
      setState((prev) => ({ ...prev, userId: userId, offset: 0, showSideButtons: false }));
      getUserPosts();
    } else if (state.groupId !== groupId && !state.isGroupInfo && !singlePost) {
      setState((prev) => ({ ...prev, groupId, offset: 0 }));
      callGetPostsAPI(true);
    } else if (state.postId !== postId) {
      setState((prev) => ({ ...prev, postId: postId }));
      getPostById();
    }
  }, [query, props.userId, props.userId, props.postId, props.singlePost]);

  const getUserPosts = async () => {
    try {
      const response = await getUserPostById(props.token, props.userId, state.offset);
      if (response.data.data) {
        const data = response.data.data;

        if (data.length == 0) {
          setState((prev) => ({ ...prev, isCompleted: true }));
        }

        setState((prev) => ({
          ...prev,
          posts: [...prev.posts, ...data],
          loading: false,
          offset: response.data.offset,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          noAuthError: response.data.ER,
          loading: false,
        }));
      }
    } catch (error) {
      logError(error);
    }
  };

  const getPostById = async () => {
    try {
      const res = await apiPosts.getPostData(props.token, props.postId);
      if (res.data.OK) {
        const data = res.data.OK;

        const posts: PostData[] = [];
        posts.push(data);
        setState((prev) => ({
          ...prev,
          posts,
          loading: false,
        }));
      }
    } catch (error) {
      logError(error);
    }
  };

  const initGroupData = async () => {
    try {
      const { token, groupId } = props;
      const res = await apiGroups.getGroupAbout(token, groupId);
      const data = res?.data?.OK;

      if (!data) return;

      setState((prev) => ({
        ...prev,
        canCreatePost: data.LGP_post === "y" || data.superAdmin === "1" || data.admin === "1",
        groupData: {
          groupName: data.LGP_name,
          avatar: data.UCT_avatar,
          isPostShareAble: data.LGP_shareable,
        },
        groupId,
      }));

      const shouldHideAddPost =
        data.LGP_post === "y" || data.superAdmin === "1" || data.admin === "1";

      dispatch(GlobalStore.showHideAddPost(shouldHideAddPost));
    } catch (error) {
      logError(error);
    }
  };

  const callGetPostsAPI = (reset: boolean = false) => {
    apiPosts
      .getPosts(
        props.token,
        reset ? 0 : state.offset,
        props.groupId,
        query,
        referredPost as null,
        props.publicPosts,
      )
      .then((res) => {
        //reset after first query
        if (res.data.data) {
          if (res.data.data.length == 0) {
            setState((prev) => ({ ...prev, isCompleted: true }));
          }

          res.data.data.length < 1 && query === ""
            ? setState((prev) => ({ ...prev, noDatatitle: "no_post_in_region" }))
            : setState((prev) => ({ ...prev, noDatatitle: "no_post_found" }));
          if (reset) {
            setState((prev) => ({
              ...prev,
              posts: res.data.data,
              loading: false,
              // offset: res.data.data?.length === 1 ? 1 : res.data.offset,
              offset: res.data.offset,
            }));
          } else {
            setState((prev) => ({
              ...prev,
              posts: [...state.posts, ...res.data.data],
              loading: false,
              // offset: res.data.data?.length === 1 ? 1 : res.data.offset,
              offset: res.data.offset,
            }));
          }
        } else {
          if (location.pathname.includes("group"))
            setState((prev) => ({ ...prev, noDatatitle: "no_post_in_group" }));
          else setState((prev) => ({ ...prev, noDatatitle: "no_post_in_region" }));

          if (reset) setState((prev) => ({ ...prev, posts: [], loading: false }));
          else setState((prev) => ({ ...prev, loading: false }));
        }
      });
  };

  const handleDeletePost = (postID: number) => {
    apiPosts.deletePost(props.token, postID).then((res) => {
      if (res.data.OK) {
        setState((prev) => ({
          ...prev,
          posts: state.posts.filter((x) => x.loci_postID !== postID),
        }));
      }
    });
  };

  const reportPostAction = (message: string) => {
    apiPosts.reportPost(props.token, state.reportedPost, message).then((res) => {
      if (res.data.OK) {
        setState((prev) => ({ ...prev, isReported: true }));
      }
    });
  };

  const openUserDetails = (userID: number, postID: number) => {
    if (props.isGroupMember === false) {
      setState((prev) => ({ ...prev, showNotGroupMember: true }));
      return;
    }
    setState((prev) => ({
      ...prev,
      isUserDetails: true,
      userDetailsID: userID,
      userDetailsPost: postID,
    }));
  };

  const openGroupInfoPage = (groupId: string) => {
    if (props.isGroupMember === false) {
      setState((prev) => ({ ...prev, showNotGroupMember: true }));
      return;
    }
    navigate(`/group/${groupId}`);
    // setState((prev) => ({ ...prev, groupId, isGroupInfo: true }));
  };

  const sharePostAction = async (id: number) => {
    try {
      const res = await apiGroups.getGroupsWithPost(props.token, id);

      const data = res?.data;
      if (!data?.OK) return;

      setState((prev) => ({
        ...prev,
        sharedPostId: id,
        showSharedGroups: true,
        postGroup: data?.postGrp as PostGroupProps ?? undefined,
        mySharedGroups: data.grp as GroupWithPostData[],
      }));
    } catch (error) {
      logError(error);
    }
  };

  const onMySharedGrpClick = (data: any) => {
    apiGroups.mappPostInGroup(props.token, data.id, state.sharedPostId).then((res) => {
      if (res.data.ER) {
        alert(res.data.ER);
      } else {
        setState((prev) => ({
          ...prev,
          showSharedGroups: false,
        }));
        alert(t("publish_post_success"));
        if (data.id === 0) navigate("/public-feed");
        else {
          navigate("/group/" + data.id);
          window.location.reload();
        }
      }
    });
  };

  const openSettingModal = () => {
    setState((prev) => ({ ...prev, showGroupPortal: false, editSettings: true }));
  };

  const getTitle = () => {
    if (!props.groupId) return t("public_feed_title");
    return state.groupData?.groupName || "";
  };

  const getIcon = () => {
    if (!props.groupId) return publicFeedIcon;
    return state.groupData?.avatar || "/images/profile.png";
  };

  const posts = state.posts;

  return (
    <>
      <div className='feed main-content'>
        <div ref={loadingRef} className='container'>
          <div className='group-title'>
            <div className='avatar-title'>
              <LocciAvatar
                title={getTitle()}
                picture={getIcon()}
                size={"60px"}
                onAvatarClick={undefined}
              />
              <h2>{getTitle()}</h2>
            </div>
          </div>
          <ActionBar
              leftIcon={state.showSideButtons ? "add" : undefined}
              onLeftClick={() => {
                setPostActionType(POST_ACTION_TYPE.EDIT);
                setPostActionData(null);
              }}
              rightIcon={state.showSideButtons && location.pathname != "/public-feed" ? "more_horiz" : undefined}
              onRightClick={() => setState((prev) => ({ ...prev, showGroupPortal: true }))}
              containerStyle={{border:0}}
          ></ActionBar>
          <div className='place-holder'></div>

          {posts.map((item) => (
            <Card key={item.loci_postID}>
              <SinglePost
                isMember
                token={props.token}
                post={item}
                analysePostAction={() => {
                  setPostActionType(POST_ACTION_TYPE.ANALYSE);
                  setPostActionData(item);
                }}
                sharePostAction={sharePostAction}
                editPostAction={() => {
                  setPostActionType(POST_ACTION_TYPE.EDIT);
                  setPostActionData(item);
                }}
                deletePostAction={() => {
                  setPostActionType(POST_ACTION_TYPE.DELETE);
                  setPostActionData(item);
                  setState((prev) => ({
                    ...prev,
                    isDeleted: false,
                    token: props.token,
                  }));
                }}
                reportPostAction={() => {
                  setPostActionType(POST_ACTION_TYPE.REPORT);
                  setPostActionData(item);
                  setState((prev) => ({
                    ...prev,
                    isReported: false,
                    reportedPost: item.loci_postID,
                  }));
                }}
                userDetailsAction={(id, post) => openUserDetails(post.FK_userID, post.loci_postID)}
                groupInfoAction={openGroupInfoPage}
              />
            </Card>
          ))}

          {location.pathname === `/group/${props.groupId}` &&
            posts.length < 1 &&
            state.noDatatitle !== undefined && (
              <div className='text-center'>{t(`${state.noDatatitle}`)}</div>
            )}

          {location.pathname === "/public-feed" &&
            posts.length < 1 &&
            state.noDatatitle !== undefined && (
              <div className='text-center'>{t(`${state.noDatatitle}`)}</div>
            )}

          {location.pathname === `/user-posts/${props.userId}` && (
            <div className='text-center'>{state.noAuthError}</div>
          )}
        </div>
        <DotFlashing loading={state.loading} />

        {state.showSharedGroups && (
          <MyGroupModal
            {...props}
            data={state.mySharedGroups || []}
            groupData={state.postGroup ?? undefined}
            type='sharedGroups'
            editing={state.showSharedGroups}
            onCardClick={onMySharedGrpClick}
            closeModal={() =>
              setState((prev) => ({
                ...prev,
                showSharedGroups: false,
              }))
            }
          />
        )}

        {postActionType === POST_ACTION_TYPE.EDIT && (
          <EditPostContainer
            {...props}
            editing
            showEditPost
            token={props.token}
            location={location}
            groupId={state.groupId}
            editedPost={postActionData}
            isEditable={!!postActionData}
            groupData={
              location.pathname === "/public-feed" ? PUBLIC_GROUP_DATA : props.groupPerData
            }
            stopEdit={() => {
              setPostActionType(null);
            }}
          />
        )}

        {postActionType === POST_ACTION_TYPE.ANALYSE && (
          <AnalysePostDialog
            analysing
            token={props.token}
            postID={postActionData?.loci_postID}
            handleClose={() => {
              setPostActionType(null);
            }}
          />
        )}

        {postActionType === POST_ACTION_TYPE.DELETE && (
          <DeletePost
            deleting
            token={props.token}
            isDeleted={state.isDeleted}
            postID={postActionData?.loci_postID}
            deletePost={handleDeletePost}
            handleClose={() => {
              setPostActionType(null);
            }}
          />
        )}
        {postActionType === POST_ACTION_TYPE.REPORT && (
          <ReportPost
            reporting
            handleClose={() => {
              setPostActionType(null);
            }}
            reportPost={reportPostAction}
            isReported={state.isReported}
          />
        )}

        {state.isUserDetails && (
          <UserDetails
            token={props.token}
            id={state.userDetailsID}
            postId={state.userDetailsPost}
            showFollowOption={!!state.userDetailsPost}
            handleClose={() => setState((prev) => ({ ...prev, isUserDetails: false }))}
          />
        )}

        {state.isGroupInfo && (
          <ViewGroupModal
            groupId={state.groupId}
            handleClose={() => setState((prev) => ({ ...prev, isGroupInfo: false }))}
            token={props.token}
          />
        )}
      </div>
      {state.editSettings && (
        <EditGroup
          token={props.token}
          editing={state.editSettings}
          editedGroupId={state.groupId}
          history={{ push: navigate }}
          stopEdit={() => {
            setState((prev) => ({
              ...prev,
              editSettings: false,
            }));
          }}
        />
      )}
      {state.showGroupPortal && (
        <GroupPortal
          isGroupAdmin={props.groupPerData?.admin === "1"}
          showGroupPortal={state.showGroupPortal}
          handleClose={() => setState((prev) => ({ ...prev, showGroupPortal: false }))}
          groupId={props.groupId}
          openSettingModal={openSettingModal}
        />
      )}

      <Modal show={state.showNotGroupMember}>
        <Modal.Body className='text-center'>{t("action_message_not_group_member")}</Modal.Body>
        <Modal.Footer>
          <Button
            text={t("close")}
            clear='true'
            handleClick={() => setState((prev) => ({ ...prev, showNotGroupMember: false }))}
          ></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Feed;
