import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { Image } from "components/shared/image";
import {getMentionSuggestions} from "../../../api/posts";
import './MentionTextarea.scss';
import defaultStyle from './defaultStyle';
import mentionStyle from './mentionStyle';

const MentionTextarea = forwardRef(({ 
  token, 
  onChange, 
  value, 
  autoFocus, 
  preMentions, 
  ...props 
}, ref) => {
  const [content, setContent] = useState('');
  const [preData, setPreData] = useState([]);
  const mentionsInputRef = useRef(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      if (mentionsInputRef.current) {
        mentionsInputRef.current.focus();
      }
    }
  }));

  // 
  useEffect(() => {
    if (preMentions) {
      let mentions = Object.keys(preMentions)?.map((key)=>{
        const user = preMentions[key];
        let item = {
          id: `${user.type}:${user.ID}`,
          display: user.display_name,
          avatar: user.avatar ?? '',
          type: user.type
        };
        return item;
      });
  
      setPreData(mentions);
      convertContentToMarkup(value, mentions);
    }
  }, [value, preMentions]);
  
  // 
  useEffect(() => {
    if (autoFocus && mentionsInputRef.current) {
      mentionsInputRef.current.focus();
    }
  }, [autoFocus]);
  
  useEffect(() => {
    setContent(props?.updateContent);
  }, [props?.updateContent]);

  /**
   * 
   */
  const handleContentChange = (event, newValue, newPlainTextValue, mentions) => {
    const cleanedMentionList = mentions.map(mention => ({
      ...mention,
      display: mention.display.replace(/^@/, '') 
    }));

    setContent(newValue);
    onChange(newPlainTextValue, cleanedMentionList, newValue);
    setPreData([]);
  };

  /**
   * 
   */
  const renderSuggestion = (suggestion, search, highlightedDisplay, index, focused) => (
    <div className={`suggestion-item ${focused ? 'focused' : ''}`} key={suggestion.id}>
      <Image
        src={suggestion.avatar}
        alt={suggestion.display}
        placeholder='/images/profile.png'
        className="avatar"
      />
      <div className="suggestion-info">
        <div className="suggestion-name">{highlightedDisplay}</div>
        <div className="suggestion-type">{suggestion.type}</div>
      </div>
    </div>
  );

  /**
   * @param {string} query
   * @param {(suggestions: MentionData[]) => void} callback
   */
  const fetchSuggestions = (query, callback) => {
    getMentionSuggestions(token, query)
    .then(res => {
      const data = res.data?.OK?.data;

      if(data.length>0) {
        callback(data.map(user => ({
          id: `${user.type}:${user.ID}`,
          display: user.display_name,
          avatar: user.avatar,
          type: user.type
        })));
      }
    });
  };

  /**
   * Converts initial content to the markup format.
   * @param {string} content
   * @param {Object} mentionsMap
   * @returns {string}
   */
  const convertContentToMarkup = (content, mentions) => {
    let convertedContent = content;
    let plainTextContent = content;

    Object.keys(preMentions).forEach(key => {
      const mention = preMentions[key];
      const markup = `@[${mention.display_name}](${mention.type}:${mention.ID})`;
      const plainMarkup = `@${mention.display_name}`;
      convertedContent = convertedContent.replaceAll(key, markup);
      plainTextContent = plainTextContent.replaceAll(key, plainMarkup);
    });
    
    handleContentChange(null, convertedContent, plainTextContent, mentions);

    return convertedContent;
  };

  return (
      <MentionsInput
        value={content}
        onChange={handleContentChange}
        className="mentions-input"
        style={{...defaultStyle, ...props?.style}}
        // style={defaultStyle}
        inputRef={mentionsInputRef}
        {...props}
      >
        <Mention
          trigger="@"
          data={(query, callback) => {
            if(!query && preData && preData.length > 0) {
              callback(preData);
            } else {
              fetchSuggestions(query, callback);
            }
          }}
          renderSuggestion={renderSuggestion}
          displayTransform={(id, display) => `@${display}`}
          markup="@[__display__](__id__)"
          style={mentionStyle}
          appendSpaceOnAdd={true}
        />
      </MentionsInput>
  );
});

export default MentionTextarea;