
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { dotDateFormatter } from "lib/utils/Commons";
import UserDetails from "../../user-details";

function CommentTabData(props) {
  const { t, i18n } = useTranslation();


  useEffect(() => {
    console.log("list", props.comments);
  }, [props.comments]);

  const [showUserDetails, setShowUserDetails] = useState(false);
  const [userId, setUserId] = useState(null);
  const clickOnName = (params) => {
    if (props.originFrom === "child") {
      setUserId(params);
      setShowUserDetails(true);
    } else {
      props.clickOnName(params);
    }
  };

  return (
    <>
      <div>
        <div className="heading">
          <h3>{t("Comments")}</h3>
        </div>
        {props.comments !== undefined && props.comments.length > 0 ? (
          <div className="comment-section">
            {props.comments.map((comment) => (
              <div className="data-section">
                <div className="left cursor"  onClick={() => clickOnName(comment.FK_userID)}>
                  <div className="img-cont">
                  <img src= {comment.USE_pic!==""?comment.USE_pic:"/images/profile.png"}/>

                  </div>
                  <div className="text-data">
                    <div
                      className="subheader"
                     
                    >
                      {comment.USE_firstname + " " + comment.USE_surename}
                    </div>
                    <div className="comment">
                      {comment.LOC_text
                        ? comment.LOC_text
                        : "highlighted blocks"}
                    </div>
                  </div>
                </div>
                <div className="right">
            {dotDateFormatter(comment.LOC_date)}

               
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>{t("No_data_found")}</div>
        )}
      </div>
      {showUserDetails && (
        <UserDetails
          id={userId}
          {...props}
          handleClose={() => setShowUserDetails(false)}
        />
      )}
    </>
  );
}

export default CommentTabData;
