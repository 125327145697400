import AttachmentIcon from "@mui/icons-material/Attachment";
import DownloadIcon from "@mui/icons-material/Download";
import { Chip } from "@mui/material";
import { downloadFromUnauthURL } from "api/groups";
import Lightbox from "components/elements/lightbox";
import { HandleEmptyState } from "components/shared/handle-empty-state";
import { Image } from "components/shared/image";
import { GroupSettingsData } from "lib/types/groupTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Styled from "./styled";

enum TABS {
  IMAGES = "images",
  VIDEOS = "videos",
  DOCUMENTS = "documents",
  RULES = "rules",
}

type Props = {
  data: GroupSettingsData | null;
};

export const InfoTabFiles = ({ data }: Props) => {
  const { t } = useTranslation();
  const [lightboxIndex, setLightboxIndex] = useState<number | null>(null);
  const [activeTab, setActiveTab] = useState<TABS>(TABS.IMAGES);

  const handleDownloadClick = (id: number, name: string) => {
    downloadFromUnauthURL(null, {
      id,
      name,
    });
  };

  const handleLightboxItemOpen = (index: number) => {
    setLightboxIndex(index);
  };

  const documents =
    data?.LGP_media?.filter((media) => media.mime_type.includes("application")) ?? [];
  const videos = data?.LGP_media?.filter((media) => media.mime_type.includes("video")) ?? [];
  const images =
    data?.LGP_media?.filter((media) => media.mime_type.includes("image")).map((media) => ({
      ...media,
      type: "p",
    })) ?? [];

  const groupRules = data?.LGP_grouprules ?? [];

  return (
    <>
      <Styled.InfoTabFiles>
        <Styled.InfoTabFilesHeader>
          <Styled.InfoTabFilesHeaderItems>
            {Object.values(TABS).map((tab) => (
              <Styled.InfoTabFilesHeaderItem
                key={tab}
                $active={activeTab === tab}
                onClick={() => setActiveTab(tab)}
              >
                {t(`group.gallery-tab-${tab}`)}
              </Styled.InfoTabFilesHeaderItem>
            ))}
          </Styled.InfoTabFilesHeaderItems>
        </Styled.InfoTabFilesHeader>
        <Styled.InfoTabFilesContent>
            {activeTab === TABS.IMAGES && (
              <HandleEmptyState isEmpty={!images || images?.length === 0}>
                <Styled.InfoTabFilesWrapper>
                  {images?.map(({ id, img, name }, index) => (
                    <Styled.InfoTabFilesItem
                      key={`image-${id}`}
                      $fixedWidthResponsive
                      onClick={() => handleLightboxItemOpen(index)}
                    >
                      <Image src={img} alt={name} />
                    </Styled.InfoTabFilesItem>
                  ))}
                </Styled.InfoTabFilesWrapper>
              </HandleEmptyState>
            )}
            {activeTab === TABS.VIDEOS && (
              <HandleEmptyState isEmpty={!videos || videos?.length === 0}>
                <Styled.InfoTabVideoWrapper>
                  {videos?.map(({ id, thumb, name, date }, index) => (
                    <Styled.InfoTabFilesItem
                      key={`video-${id}`}
                      $fixedWidthResponsive
                      onClick={() => handleLightboxItemOpen(index)}
                    >
                      <Styled.InfoTabFilesItemThumb $small $fullWidth>
                        <Image src={thumb} alt={name} />
                      </Styled.InfoTabFilesItemThumb>
                      <Styled.InfoTabFilesItemContent>
                        <h4>{name}</h4>
                        <div>
                          <span>{date}</span>
                        </div>
                      </Styled.InfoTabFilesItemContent>
                    </Styled.InfoTabFilesItem>
                  ))}

                </Styled.InfoTabVideoWrapper>
              </HandleEmptyState>
            )}
            {activeTab === TABS.DOCUMENTS && (
              <HandleEmptyState isEmpty={!documents || documents?.length === 0}>
                <Styled.InfoTabDocumentsWrapper>
                  {documents.slice(0, 5).map(({ id, name }) => (
                    <Styled.InfoTabFilesItem key={`document-${id}`}>
                      <Chip
                        deleteIcon={<DownloadIcon />}
                        onClick={() => {
                          handleDownloadClick(id, name);
                        }}
                        onDelete={() => {
                          handleDownloadClick(id, name);
                        }}
                        icon={<AttachmentIcon />}
                        label={name}
                      />
                    </Styled.InfoTabFilesItem>
                  ))}
                </Styled.InfoTabDocumentsWrapper>
              </HandleEmptyState>
            )}
            {activeTab === TABS.RULES && (
              <HandleEmptyState isEmpty={!groupRules || groupRules?.length === 0}>
                <Styled.InfoTabDocumentsWrapper>
                  {groupRules.slice(0, 5).map(({ id, name }) => (
                    <Styled.InfoTabFilesItem key={`rule-${id}`}>
                      <Chip
                        deleteIcon={<DownloadIcon />}
                        onClick={() => {
                          handleDownloadClick(id, name);
                        }}
                        onDelete={() => {
                          handleDownloadClick(id, name);
                        }}
                        icon={<AttachmentIcon />}
                        label={name}
                      />
                    </Styled.InfoTabFilesItem>
                  ))}
                </Styled.InfoTabDocumentsWrapper>
              </HandleEmptyState>
            )}
        </Styled.InfoTabFilesContent>
      </Styled.InfoTabFiles>

      {lightboxIndex !== null && (
        <Lightbox
          onClose={() => setLightboxIndex(null)}
          media={activeTab === TABS.IMAGES ? images : videos}
          index={lightboxIndex}
          setIndex={(i: number) => setLightboxIndex(i)}
          nameDate={undefined}
        />
      )}
    </>
  );
};
