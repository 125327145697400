import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import IconButton from "../elements/buttons/IconButton";

const ToastModal = ({ msg }) => {
  const { t, i18n } = useTranslation();

  const [show, setShow] = useState(true);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      className="custom-modal custom-toaster"
    >
      <IconButton
        clear="true"
        className="closeIcon"
        icon="close"
        handleClick={() => setShow(false)}
      />
      {t(msg)}
    </Modal>
  );
};

export default ToastModal;
