import React, { Component } from "react";
import "./EditGroup.scss";
import Button from "../../elements/buttons/Button";
import { Form, Modal, Spinner } from "react-bootstrap";
import IconButton from "../../elements/buttons/IconButton";
import Checkbox from "../../elements/checkbox";
import FileList from "../../upload";
import { getGroupAbout, saveGroup, deleteGroup } from "../../../api/groups";
import DynamicSearch from "../../elements/dynamic-search";
import { getMunicipality, getStatesAndLanguages } from "../../../api/user";
import DropdownSearch from "../../elements/dropdown-search";
import { useTranslation, withTranslation } from "react-i18next";
import Loader from "../../posts/single-post/PostLoader/postLoader";
import { validateEmail } from "lib/utils/Commons";
import ConfirmCreateGroupDialog from "../confirmCreateGroupDialog/confirmCreateGroupDialog";

//edit or create new post
class EditGroupComponent extends Component { 
  constructor(props) {
    super(props);
    this.postcodeOffset = React.createRef();
    this.totalPostcode = React.createRef()
  }
  state = {
    avatar: [],
    docFiles: [],
    rulesFiles: [],
    about: "",
    groupName: "",
    phoneNumber: "",
    website: "",
    admins: [],
    isInvite: true,
    isMemberlist: true,
    isAllowPost: true,
    isConfirm: false,
    LGP_shareable: true,
    LGP_shareable_other_group: true,
    LGP_posttopublic: false,
    promocode: "",
    saving: false,
    isLoading: false,
    showErrorModal: false,
    message: "",
    regTown: null,
    noDataFoundMsg: "",
    showConfirmCreateDialog:false,
    groupCreatesSuccessFully:false,
    successFullyMsg:""
  };

  handleClose = () => {
    this.props.stopEdit();
  };

  handleAdmin = (id) => {
    const admins = this.state.admins;
    if (admins?.includes(id))
      this.setState({ admins: admins.filter((x) => x != id) });
    else this.setState({ admins: [...admins, id] });
  };

  setAvatar = (avatar) => {
    this.setState({ avatar: avatar });
  };
  setDocFiles = (docs) => {
    console.log({ docs });
    this.setState({ docFiles: docs });
  };
  setRulesFiles = (docs) => {
    console.log({ docs });
    this.setState({ rulesFiles: docs });
  };
  Loading = () => {
    this.setState({ isLoading: true });
    console.log("isLoading", this.state.isLoading);
  };

 
  componentDidMount() {
    this.initStates();

    if (this.props.editedGroupId) {
      this.initEditedGroup();
    }
  }

  initEditedGroup() {
    getGroupAbout(this.props.token, this.props.editedGroupId).then((res) => {
      if (res.data.OK) {
        const data = res.data.OK;

        const rules = [];
        for (let item of data.LGP_grouprules) {
          const file = { name: item.name, type: "g", id: item.id };
          rules.push(file);
        }

        const media = [];
        for (let item of data.LGP_media) {
          const file = { name: item.name, type: "m", id: item.id };
          media.push(file);
        }

        this.setState({
          editedGroup: this.props.editedGroupId,
          about: data.LGP_about,
          groupName: data.LGP_name,
          phoneNumber: data.LGP_phone,
          website: data.LGP_web,
          admins: data.LGP_admin.map((x) => x.FK_userID.toString()),
          promocode: data.LGP_promo,
          regTown: data.FK_adr_cityID,
          myState: data.FK_stateID,
          emailAddress: data.LGP_contact,
          isInvite: data.LGP_invite === "y",
          isMemberlist: data.LGP_viewmember === "y",
          isAllowPost: data.LGP_post === "y",
          isConfirm: data.LGP_confirm === "y",
          LGP_shareable: data.LGP_shareable === "y",
          LGP_shareable_other_group: data.LGP_share_to_group === "y",
          LGP_posttopublic: data.LGP_posttopublic === "y",

          isPrivate: data.LGP_public === "n",
          avatar: data.UCT_avatar ? [{ preview: data.UCT_avatar }] : [],
          docFiles: media,
          rulesFiles: rules,
        });

        getMunicipality(data.CIT_plz, data.FK_stateID).then((res) => {
          if (res.data.OK) this.setState({ postcodeCity: res.data.plz[0].n });
          else console.log(res);
        });
      }
    });
  }

  initStates() {
    getStatesAndLanguages(this.props.token).then((res) => {
      const data = res.data.OK;
      this.setState({
        languages: data.lang,
        countries: data.state,
      });
    });
  }

  onCountryChange(e) {
    this.setState({ myState: e.target.value });
    this.setState({ postcodeCity: "", municipalities: [], regTown: null });
    this.postcodeOffset.current=0
  }

  submitForm = (e,confirmedCreate) => {
    const { t } = this.props;

    e?.preventDefault?.();
    if (this.state.saving) return;

    if (this.state.regTown === null) {
      this.setState({
        message: t("invalid_postcode"),
      });
    } else if (!validateEmail(this.state.emailAddress)) {
      this.setState({
        message: t("not_valid_email"),
      });
    } else  if (!confirmedCreate) {
      this.setState({ showConfirmCreateDialog: true });
      return;
    }else{
      this.Loading();

      this.setState({ message: "", saving: true });
      saveGroup(this.props.token, this.state)
        .then((res) => {
          if (res.data.OK) { 
            this.setState({groupCreatesSuccessFully : !this.state.groupCreatesSuccessFully,
              successFullyMsg:res.data.OK
            })
            // window.location.reload(false);
          } else if (res.data.ER) {
            this.setState({ message: res.data.ER, saving: false });
          }
        })
        .finally(() => this.setState({ saving: false, isLoading: false }));
    }
  };

  deleteGroup = () => {
    if (this.state.saving) return;

    this.setState({ message: "", saving: true });
    deleteGroup(this.props.token, this.props.editedGroupId)
      .then((res) => {
        console.log(res.data);
        if (res.data.OK) {
          this.props.history.push("/groups");
        } else if (res.data.ER) {
          this.setState({ message: res.data.ER });
        }
      })
      .finally(() => this.setState({ saving: false }));
  };

  getLocationFromPost(code, state) {
    const { t } = this.props;
    if (this.postcodeOffset.current !== this.totalPostcode.current) {
    getMunicipality(code, state,this.postcodeOffset.current).then((res) => {
      if (res.data.OK && res.data.plz.length > 0) {
        this.setState({ municipalities:  [...this.state.municipalities, ...res.data.plz], noDataFoundMsg: "" });
        this.postcodeOffset.current = res.data.offset
        this.totalPostcode.current = res.data.total
      } else {
        this.setState({
          municipalities: [],
          regTown: null,
          noDataFoundMsg: t("no_result_found"),
        });
      }
    }); 
  }
  }

  render() {
    console.log(this.state);
    const { t } = this.props;
    const myState = this.state.myState ? this.state.myState : 1;
    const states = this.state.countries;
    return (
      <>
        <div className="editgroupcompo ">
          <Form className="newPostForm" onSubmit={(e)=>this.submitForm(e,"")}>
            <div className="row-form-control">
              <div className="avatar-box">
                <FileList
                  id={"mediaUpload"}
                  fileTypes={".png, .jpg, .jpeg"}
                  setFiles={this.setAvatar}
                  files={this.state.avatar}
                  icon="add_a_photo"
                  single={true}
                  previews={true}
                />
              </div>
              <div className="flex-grow-1">
                <Form.Group size="lg" className="form-group" controlId="groupName">
                  <Form.Label>{t("add_edit_group_name") + "*"}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("add_edit_group_name_placeholder")}
                    required
                    value={this.state.groupName}
                    onChange={(e) =>
                      this.setState({ groupName: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group size="lg" className="form-group" controlId="isPrivate">
                  <Checkbox
                    text={t("add_edit_group_type_private")}
                    value={this.state.isPrivate}
                    handleChange={(e) =>
                      this.setState({ isPrivate: !this.state.isPrivate })
                    }
                  />
                </Form.Group>
              </div>
            </div>

            <h5>{t("add_edit_post_general_info")}</h5>

            <Form.Group size="lg" className="form-group" controlId="country">
              <Form.Label>{t("country") + "*"}</Form.Label>
              <Form.Control
                as="select"
                required
                onChange={(e) => this.onCountryChange(e)}
              >
                {states &&
                  Object.keys(states).map(function (keyName, keyIndex) {
                    return (
                      <option selected={myState === keyName} value={keyName}>
                        {states[keyName]}
                      </option>
                    );
                  })}
              </Form.Control>
            </Form.Group>

            <Form.Group
              className="position-relative form-group"
              size="lg"
              controlId="postcodecity"
            >
              <Form.Label>{t("postcode") + "*"}</Form.Label>
              <Form.Control
                autoComplete="off"
                type="text"
                value={this.state.postcodeCity}
                placeholder={t("postcode")}
                rows={4}
                required
                onFocus={() => this.setState({ openDropdownSearch: true })}
                onChange={(e) => {
                  this.setState({ postcodeCity: e.target.value,municipalities: []  });
                  // if (e.target.value.length > 2)
                  this.postcodeOffset.current = 0

                    this.getLocationFromPost(e.target.value, myState);
                }}
              />
              <div className="error-msg">{this.state.noDataFoundMsg}</div>
              {this.state.municipalities && this.state.openDropdownSearch && (
                <DropdownSearch
                  results={this.state.municipalities}
                  className="mx-15"
                  handleClick={(id, name) =>
                    this.setState({
                      regTown: id,
                      postcodeCity: name,
                      openDropdownSearch: false,
                    })
                  } 
                  fetchData={() => this.getLocationFromPost(this.state.postcodeCity, myState)}

                />
              )}
            </Form.Group>

            <Form.Group size="lg" className="form-group" controlId="about">
              <Form.Label>{t("add_edit_group_about")}</Form.Label>
              <Form.Control
                as="textarea"
                value={this.state.about}
                rows={2}
                onChange={(e) => this.setState({ about: e.target.value })}
              />
            </Form.Group>

            <Form.Group size="lg" className="form-group" controlId="about">
              <Form.Label>{t("promo_code")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("promo_code")}
                value={this.state.promocode}
                onChange={(e) => this.setState({ promocode: e.target.value })}
              />
            </Form.Group>

            <h5>Contact</h5>

            <Form.Group size="lg"  className="form-group" controlId="emailaddress">
              <Form.Label>{t("email") + "*"}</Form.Label>
              <Form.Control
                // type="email"
                placeholder=""
                required
                value={this.state.emailAddress}
                onChange={(e) =>
                  this.setState({ emailAddress: e.target.value })
                }
              />
            </Form.Group>

            {/* {this.props.editedGroupId !== null && (
            <>
              <h5>{t("add_edit_group_group_admins_title")}</h5>

              <Form.Group
                size="lg"
                controlId="groupAdmins"
                className="group-admins"
              >
                <DynamicSearch
                  type="admins"
                  list={this.state.admins}
                  defaultValue={this.state.defaultAdmins}
                  handleList={(id) => this.handleAdmin(id)}
                  token={this.props.token}
                />
              </Form.Group>
            </>
          )}  */}

            <Form.Group size="lg" className="form-group" controlId="phonenumber">
              <Form.Label>{t("edit_profile_telephone")}</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={this.state.phoneNumber}
                onChange={(e) => this.setState({ phoneNumber: e.target.value })}
              />
            </Form.Group>

            <Form.Group size="lg" className="form-group" controlId="website">
              <Form.Label>{t("add_edit_group_website")}</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={this.state.website}
                onChange={(e) => this.setState({ website: e.target.value })}
              />
            </Form.Group>

            <h5>{t("add_edit_group_media_and_documents_title")}</h5>

            <FileList
              id={"documentUpload"}
              // fileTypes={
              //   ".png, .jpg, .jpeg, .bmp, .gif, .mov, .mp4, .doc, .docx, .xml, .pdf"
              // }
              setFiles={this.setDocFiles}
              files={this.state.docFiles}
              text={t("add_edit_group_document")}
              icon="attach_file"
            />

            <h5>{t("add_edit_group_group_rules_title")}</h5>

            <FileList
              id={"documentUpload2"}
              // fileTypes={
              //   ".png, .jpg, .jpeg, .bmp, .gif, .mov, .mp4, .doc, .docx, .xml, .pdf"
              // }
              setFiles={this.setRulesFiles}
              files={this.state.rulesFiles}
              text={t("add_edit_post_document")}
              icon="attach_file"
            />

            <h5>{t("add_edit_group_settings_title")}</h5>

            <Form.Group size="lg" className="form-group" controlId="isInvite">
              <Checkbox
                text={t("add_edit_group_checkbox_member_invite_text")}
                value={this.state.isInvite}
                handleChange={(e) =>
                  this.setState({ isInvite: !this.state.isInvite })
                }
              />
            </Form.Group>

            <Form.Group size="lg" className="form-group" controlId="isMemberlist">
              <Checkbox
                text={t("add_edit_group_checkbox_member_enter_memberlist_text")}
                value={this.state.isMemberlist}
                handleChange={(e) =>
                  this.setState({ isMemberlist: !this.state.isMemberlist })
                }
              />
            </Form.Group>

            <Form.Group size="lg" className="form-group" controlId="isAllowPost">
              <Checkbox
                text={t("add_edit_group_checkbox_member_create_posts_text")}
                value={this.state.isAllowPost}
                handleChange={(e) =>
                  this.setState({ isAllowPost: !this.state.isAllowPost })
                }
              />
            </Form.Group>

            <Form.Group size="lg" className="form-group" controlId="isConfirm">
              <Checkbox
                text={t(
                  "add_edit_group_checkbox_member_enter_confirmation_text"
                )}
                value={this.state.isConfirm}
                handleChange={(e) =>
                  this.setState({ isConfirm: !this.state.isConfirm })
                }
              />
            </Form.Group>

            <Form.Group size="lg" className="form-group" controlId="LGP_shareable ">
              <Checkbox
                text={t("add_edit_group_checkbox_member_can_share_grp_post")}
                value={this.state.LGP_shareable}
                handleChange={(e) =>
                  this.setState({
                    LGP_shareable: !this.state.LGP_shareable,
                  })
                }
              />
            </Form.Group>
            <Form.Group size="lg" className="form-group" controlId="LGP_shareable_other_group ">
              <Checkbox
                text={t(
                  "add_edit_group_checkbox_member_can_share_grp_post_in_other_group"
                )}
                value={this.state.LGP_shareable_other_group}
                handleChange={(e) =>
                  this.setState({
                    LGP_shareable_other_group:
                      !this.state.LGP_shareable_other_group,
                  })
                }
              />
            </Form.Group>

            <Form.Group size="lg" className="form-group" controlId="LGP_posttopublic ">
              <Checkbox
                text={t("add_edit_group_checkbox_post_to_public")}
                value={this.state.LGP_posttopublic}
                handleChange={(e) =>
                  this.setState({
                    LGP_posttopublic: !this.state.LGP_posttopublic,
                  })
                }
              />
            </Form.Group>

            <div
              className={
                "actions " +
                (this.props.editedGroupId == null
                  ? "justify-content-end"
                  : "justify-content-space-between")
              }
            >
              {this.props.editedGroupId && (
                <Button
                  text={t("add_edit_group_delete")}
                  type="button"
                  handleClick={() => this.deleteGroup()}
                  disabled={this.state.saving}
                  className="flex-none"
                />
              )}

              <Button
                text={
                  this.props.editedGroupId == null
                    ? t("add_edit_group_create")
                    : t("add_edit_group_update")
                }
                type="submit"
                disabled={this.state.saving}
                className="flex-none"
              />
            </div>
          </Form> 
            {this.state.showConfirmCreateDialog
             &&( <ConfirmCreateGroupDialog 
              create={true}
              deleting={true}
              handleClose={() =>
                this.setState({ showConfirmCreateDialog: false })
              }
              groupName={this.state.groupName}
              onSubmit={() => {
                this.submitForm(null, true);
              }}
             />)

            }  
            {this.state.groupCreatesSuccessFully && (
              <Modal
              show={this.state.groupCreatesSuccessFully}
              // onHide={() => setShow(false)}
              className="custom-modal custom-toaster"
            >
              <IconButton
                clear="true"
                className="closeIcon"
                icon="close"
                handleClick={() => window.location.reload(false)}
              /> 
             {this.state.successFullyMsg} 
              

              {/* <div className="text-center">
          <Button
            text={t("ok")}
            handleClick={() => window.location.reload(false)}
          />
        </div> */}
            </Modal>
            )

            }

          {this.state.message && (
            <span className="formMessage">{this.state.message}</span>
          )}
          {this.state.isLoading && <Loader />}
        </div>
      </>
    );
  }
}

export default withTranslation()(EditGroupComponent);
