import { useEffect } from "react";

export const useInfiniteScroll = (
  scrollRef: React.RefObject<HTMLDivElement>,
  callback: () => void,
) => {
  useEffect(() => {
    const checkScroll = () => {
      if (!scrollRef.current) {
        return;
      }

      const { offsetTop, offsetHeight } = scrollRef.current;
      const scrollPosition = window.pageYOffset + window.innerHeight;
      const offsetBottom = offsetTop + offsetHeight;

      if (scrollPosition >= offsetBottom - 100) {
        callback();
      }
    };

    window.addEventListener("scroll", checkScroll);
    return () => window.removeEventListener("scroll", checkScroll);
  }, [scrollRef, callback]);
};
