import React, { Component } from "react";
import { Form, Modal } from "react-bootstrap";
import Button from "../elements/buttons/Button";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./Groups.scss";
import {
  joinGroup,
  searchPublicGroupsbyPromo,
  searchPublicGroupsbyRadius,
  fetchGroupRules,
  downloadFromUrl,
} from "../../api/groups";
import IconLink from "../elements/buttons/IconLink";
import { connect } from "react-redux";
import { mapStateToProps } from "lib/store/mapStateToProps";
import { withTranslation } from "react-i18next";
import IconButton from "../elements/buttons/IconButton";
import { useNavigate, useLocation } from "react-router-dom";
import { withRouter } from "../customNavigate/withRouter";
import BackButton from "../commons/BackButton";
import { t } from "i18next";
import ActionBar from "components/actionBar";

class JoinGroup extends Component {
  state = {
    promoCode: "",
    currentDistance: 1000,
    groups: [],
    query: "",
    showConfirmModal: false,
    groupData: "",
    showRulesModal: false,
    rules: null, 
    messageModal: null,
    showModal: false,
  };

  constructor(props) {
    super(props);
    this.minDistance = 0;
    this.maxDistance = 2000;
  }

  componentDidMount() {
    this.getGroups();
  }

  setCode(code) {
    this.setState({ promoCode: code });
  }

  onSliderChange = (e) => {
    this.setState({ currentDistance: e });
    this.getGroups();
  };

  showConfirmModal = (group) => {
    this.setState({
      groupData: group,
      showConfirmModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      showConfirmModal: false,
    });
  };  

  closeModal1 = () =>{
    this.setState({
      showModal:false
    })
  } 

  closeRulesModal = () => {
    this.setState({
      showRulesModal: false,
    });
  };

  getRulesData = async () => {
    this.setState({
      showRulesModal: true,
    });
    try {
      const response = await fetchGroupRules(
        this.props.token,
        this.state.groupData.id
      );
      console.log("res", response.data);

      if (response.data) {
        const data = response.data;
        if (data.ER) {
          this.setState({
            rules: "Error group does not exist.",
          });
        } else {
          this.setState({
            rules: data.OK,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  getDocumentsView(data) {
    return (
      <div className="doc">
        <span class="icon-row">
          <i className="material-icons mr-5">attach_file</i>
          {data.LGM_img_name}
          <a onClick={() => this.onDownloadClick(data)}>
            <i className="material-icons material-icons-outlined ml-15 greenIcon">
              get_app
            </i>
          </a>
        </span>
      </div>
    );
  }

  onDownloadClick(data) {
    // console.log(data);
    downloadFromUrl(this.props.token, data);
  }

  getGroups() {
    searchPublicGroupsbyRadius(
      this.props.token,
      this.props.query,
      this.state.currentDistance
    ).then((res) => {
      if (res.data.OK) {
        this.setState({ groups: res.data.groups });
      }
    });
  }

  componentDidUpdate() {
    if (this.state.query != this.props.query) {
      console.log(this.props.query);
      this.setState({ query: this.props.query });
      this.getGroups();
    }
  }

  joinGroupBtn(group) {
    joinGroup(this.props.token, group.id, this.state.promoCode).then((res) => {
      //   console.log(res.data);
      if (res.data.OK) {
        group.joined = true;
        group.joinedText = res.data.OK;
        if (group.joinedText === "joined") {
          group.joinedText = "btn_title_joined";  
          this.setState({
            showModal: true,
            messageModal: t('enter_group_screen_joined_group_text'),
          });
        } else{
          group.joinedText = "btn_title_join_pending";   
          this.setState({
            showModal: true,
            messageModal:t('enter_group_screen_sent_join_request_text'),
          }); 
        } 
        this.closeModal();
        this.forceUpdate();
      }
    });
  }

  searchGroupForm(e) {
    e.preventDefault();
    searchPublicGroupsbyPromo(
      this.props.token,
      this.state.currentDistance,
      this.state.promoCode
    ).then((res) => {
      if (res.data.OK) {
        this.setState({ groups: res.data.groups });
      }
    });
  }

  render() {
    const { t } = this.props;
    const { history } = this.props;
    return (
      <>
        <div className="join-group">
          <ActionBar containerStyle={{border:0}} />
          {/* <div className="join-private">
            <h2>{t("search_by_group_name_and_promocode")}</h2>
            <Form
              className="private-group-form"
              onSubmit={(e) => this.searchGroupForm(e)}
            >
              <Form.Group size="lg" className="form-group" controlId="promoCode">
                <Form.Control
                  autoFocus
                  type="text"
                  required
                  placeholder={t("enter_the_promocode_or_group_name")}
                  value={this.state.promoCode}
                  onChange={(e) => this.setCode(e.target.value)}
                />
              </Form.Group>
              <Button
                text={t("enter_group_search_button_title")}
                type="submit"
              />
            </Form>
          </div> */}

          <div className="join-public">
            <h2>{t("enter_group_screen_title_public_groups_section")}</h2>
            <p>
              {t("enter_group_screen_radius_text")}{" "}
              <strong>{this.state.currentDistance}</strong> km
            </p>
            <div className="slider-wrapper">
              <Slider
                min={this.minDistance}
                max={this.maxDistance}
                defaultValue={this.maxDistance / 2}
                onChange={(e) => this.setState({ currentDistance: e })}
                onAfterChange={(e) => this.onSliderChange(e)}
              />
              <div className="slider-labels">
                <span>{this.minDistance} km</span>
                <span>
                  {this.maxDistance / 2}
                  <br />
                  km
                </span>
                <span>{this.maxDistance} km</span>
              </div>
            </div>

            <div className="groups-listing">
              {this.state.groups.map((group, i) => (
                <div className="single-group" key={i}>
                  <div className="single-group-header">
                    <img className="avatar"
                      src={
                        group.userpic ? group.userpic : "/images/profile.png"
                      }
                    />
                    <span className="bold">{group.name}</span>
                  </div>
                  <div className="single-group-buttons">
                    <Button
                      name="join"
                      clear={true}
                      disabled={group.joined||group.pending==="1"}
                      text={
                        (group.joined ||group.pending==="1" )
                          ?group.pending==="1" ? t("btn_title_join_pending") : t(`${group.joinedText}`)
                          : t("join_group")
                      }
                      handleClick={() => this.showConfirmModal(group)}
                    />
                    {/* */}
                    <IconLink
                      clear={true}
                      link={
                        this.state.promoCode === ""
                          ? `/group/${group.id}/about`
                          : `/group/${group.id}/about?pcode=${this.state.promoCode}`
                      }
                      text={t("enter_group_screen_group_info")}
                      className={"info"}
                      textClassName={"infoText"}
                      handleClick={() => {}}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {
            <Modal
              centered
              show={this.state.showConfirmModal}
              onHide={this.closeModal}
              backdrop="static"
              contentClassName="box"
            >
              <Modal.Body>
                <h2>{t("enter_group_modal_option")}</h2>

                <>
                  <p>
                    {t("confirm_title")}
                    <b className="pl-1">{this.state.groupData.name} ?</b>
                  </p>

                  <div>
                    {t("confirm_subtitle")}
                    <b>
                      <a onClick={() => this.getRulesData()} className="pl-1">
                        {t("group_rules")}{" "}
                      </a>
                    </b>
                    <span>({t("open_with_click")})</span>
                  </div>

                  <div className="report-actions pt-3">
                    <Button
                      clear="true"
                      text={t("cancel_alert")}
                      handleClick={this.closeModal}
                    />
                    <Button
                      text={t("enter")}
                      handleClick={() =>
                        this.joinGroupBtn(this.state.groupData)
                      }
                    />
                  </div>
                </>
              </Modal.Body>
            </Modal>
          }

          <Modal
            centered
            show={this.state.showRulesModal}
            onHide={this.closeRulesModal}
            backdrop="static"
          >
            <Modal.Body>
              <h2>{t("add_edit_group_rules_title")}</h2>

              {this.state.rules === "Error group does not exist." ? (
                <p>{t("no_rules_added")}</p>
              ) : (
                this.state.rules !== null &&
                this.state.rules.length > 0 && (
                  <div className="docs-wrapper">
                    {this.state.rules.map((item, i) =>
                      this.getDocumentsView(item)
                    )}
                  </div>
                )
              )}

              <div className="float-right">
                <Button
                  clear="true"
                  text={t("close")}
                  handleClick={this.closeRulesModal}
                />
              </div>
            </Modal.Body>
          </Modal> 

          <Modal
            show={this.state.showModal}
            onHide={() => this.closeModal1()}
            className="custom-modal"
          >
          <IconButton
            clear="true"
            className="closeIcon"
            icon="close"
            handleClick={() => this.closeModal1()}
          />

          {this.state.messageModal}
          </Modal>
        </div>
      </>
    );
  }
}

export default withTranslation()(connect(mapStateToProps)(JoinGroup));
