/* eslint-disable default-case */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.scss";
import Button from "../../components/elements/buttons/Button";
import { Form, Modal } from "react-bootstrap";
import { getMunicipality, getStatesAndLanguages } from "../../api/user";
import { completeData } from "lib/context/authActions";
import { useAuthDispatch, useAuthState } from "lib/context/authContext";
import IconLink from "../../components/elements/buttons/IconLink";
import Checkbox from "../../components/elements/checkbox";
import DropdownSearch from "../../components/elements/dropdown-search";
import { useTranslation } from "react-i18next";
import IconButton from "../../components/elements/buttons/IconButton";

function CompleteData({ ...props }) {
  const [states, setContries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [openDropdownSearch, setOpenDropdownSearch] = useState(false);
  const [data, setData] = useState({ myLang: 1, myState: 1 });
  const [message, setMessage] = useState("");
  const [link, setLink] = useState("");

  const [isGDPR, setGDPR] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showErrorModal, setErrorModal] = useState(false);
   
  const postcodeOffset = useRef();
  const totalPostcode = useRef();
  const fbData = props.fb;
  const token = props.token;
  const authDispatch = useAuthDispatch();
  const history = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getStatesAndLanguages().then((res) => {
      if (res.data.OK) {
        setContries(res.data.OK.state);
        setLanguages(res.data.OK.lang);
      }
    });
  };

  const handleGDPR = () => {
    setGDPR(!isGDPR);
  };

  const validateForm = async (e) => {
    e.preventDefault();
    console.log('called');
    // setMessage("");
    if (!isGDPR || fbData) {
      setErrorModal(true)
      setMessage(t("did_not_agree"));
      return;
    }

    completeData(authDispatch, token, data).then((res) => {
      if (res.ER) {
        setMessage(res.ER);
      } else if (res.OK) {
       history("/");
      }
    });
  };

  const getLocationFromPost = (code, state) => { 
    if (postcodeOffset.current !== totalPostcode.current) {

    getMunicipality(code, state).then((res) => {
      if (res.data.OK && res.data.plz.length > 0) {
        setMunicipalities((prev)=>[...prev,...res.data.plz]); 
        postcodeOffset.current = res.data.offset
        totalPostcode.current = res.data.total
      } else {
        setMunicipalities([]);
      }
    }); 
  }
  };

  const onTermsClick = (param) => {
    switch (param) {
      case "terms":
        if (i18n.language !== "en-GB") {
          const language = i18n.language.split("-")[0];

          setLink("/agb_" + language);
        } else setLink("/agb_en");
        break;
      case "privacy":
        if (i18n.language !== "en-GB") {
          const language = i18n.language.split("-")[0];

          setLink("/privacy_" + language);
        } else setLink("/privacy_en");
        break;
    }

    setShowTermsModal(true);
  };

  return (
    <div className="loginPage">
      <div className="loginBox">
        <h2>
          {t("complete_data_title_primary") +
            " " +
            t("complete_data_title_secondary")}
        </h2>
        <Form className="loginForm" onSubmit={(e) => validateForm(e)}>
          {fbData && (
            <>
              <Form.Group size="lg" controlId="firstname">
                <Form.Label>{t("name")}</Form.Label>
                <Form.Control readOnly type="text" value={fbData.first_name} />
              </Form.Group>

              <Form.Group size="lg" controlId="lastname">
                <Form.Label>{t("lastname")}</Form.Label>
                <Form.Control readOnly type="text" value={fbData.last_name} />
              </Form.Group>

              <Form.Group size="lg" controlId="email">
                <Form.Label>{t("email")}</Form.Label>
                <Form.Control type="email" value={fbData.email} readOnly />
              </Form.Group>
            </>
          )}

          <Form.Group size="lg" controlId="country">
            <Form.Label>{t("country") + "*"}</Form.Label>
            <Form.Control
              as="select"
              required
              onChange={(e) => {
                setData({ ...data, myState: e.target.value });
                setMunicipalities([])
                postcodeOffset.current=0;
                getLocationFromPost(data.postcode, e.target.value);
              }}
            >
              {states &&
                Object.keys(states).map(function (keyName, keyIndex) {
                  return (
                    <option selected={data.myState === keyName} value={keyName}>
                      {states[keyName]}
                    </option>
                  );
                })}
            </Form.Control>
          </Form.Group>

          <Form.Group
            size="lg"
            className="position-relative"
            controlId="postcode"
          >
            <Form.Label>{t("postcode") + "*"}</Form.Label>
            <Form.Control
              required
              type="text"
              autoComplete="off"
              value={data.postcodeCity}
              onFocus={() => setOpenDropdownSearch(true)}
              onChange={(e) => {
                setData({ ...data, postcodeCity: e.target.value  }); 
                setMunicipalities([])
                // if (e.target.value.length > 2)
                  getLocationFromPost(e.target.value, data.myState);

              }}
            />
            {municipalities && openDropdownSearch && (
              <DropdownSearch
                results={municipalities}
                className="mx-15"
                handleClick={(id, name) => {
                  setData({
                    ...data,
                    regTown: id,
                    postcodeCity: name,
                  });
                  setOpenDropdownSearch(false);
                }} 
                fetchData={() => getLocationFromPost(data.postcodeCity, data.myState)}

              />
            )}
          </Form.Group>

          <Form.Group size="lg" controlId="language">
            <Form.Label>{t("language") + "*"}</Form.Label>

            <Form.Control
              as="select"
              required
              onChange={(e) => setData({ ...data, myLang: e.target.value })}
            >
              {languages &&
                Object.keys(languages).map(function (keyName, keyIndex) {
                  return (
                    <option selected={data.myLang === keyName} value={keyName}>
                      {languages[keyName]}
                    </option>
                  );
                })}
            </Form.Control>
          </Form.Group>

          <Form.Group size="lg" controlId="promocode">
            <Form.Label>{t("promo_code")}</Form.Label>
            <Form.Control
              type="text"
              value={data.promocode}
              onChange={(e) => setData({ ...data, promocode: e.target.value })}
            />
          </Form.Group>

          {fbData && (
            <>
              <Form.Group size="lg" controlId="agree" className="mb-0">
                <Checkbox
                  text={t("accept_terms_and_privacy")}
                  value={data.isGDPR}
                  required={true}
                  handleChange={(e) => setData(!isGDPR)}
                />
              </Form.Group>
            </>
          )}

          <Form.Group size="lg" controlId="agree" className="mb-0">
            <Checkbox
              text={t("accept_terms_and_privacy")}
              value={isGDPR}
              required={true}
              className="tc"
              handleChange={(e) => handleGDPR()}
            />
            <p className="termText">
              <IconLink
                text={t("terms_of_service")}
                clear={true}
                handleClick={() => onTermsClick("terms")}
              />{" "}
              <span>{t("and")}</span>{" "}
              <IconLink
                text={t("privacy_policy")}
                clear={true}
                handleClick={() => onTermsClick("privacy")}
              />
            </p>
          </Form.Group>

          <Button text={t("login")} type="submit" className="w-100 mt-30" />
        </Form>

        <IconLink
          link="/login"
          text={t("login_back")}
          clear={true}
          className="w-100 d-block text-center mt-30"
          handleClick={() => window.location.reload()}
        />

        {/* {message && <span className="formMessage">{message}</span>} */}

        <Modal
        show={showErrorModal}
        onHide={() => setErrorModal(false)}
        className="custom-modal"
      >
        <IconButton
          clear="true"
          className="closeIcon"
          icon="close"
          handleClick={() => setErrorModal(false)}
        />
        <div className="text-uppercase">{message}</div>
      </Modal>
      </div>
      {/* <ModalTerms
          show={showTermsModal}
          close={() => this.setShowTermsModal(false)}
          link={link}
        /> */}

      <Modal
        centered
        show={showTermsModal}
        onHide={() => setShowTermsModal(false)}
      >
        <Modal.Body class="custom-modalbody">
          <iframe
            src={link}
            frameBorder={0}
            width={"100%"}
            className={"iframe"}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CompleteData;
