import { LoaderIcon as Icon } from "components/icons/Loader";
import styled from "styled-components";

export const LoaderIconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const LoaderIcon = styled(Icon)`
  width: 40px;
  height: 40px;

  @keyframes Icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  -webkit-animation: Icon-spin 2s infinite linear;
  animation: Icon-spin 2s infinite linear;
`;
