import React from "react";
import "./UserCard.scss";
import IconButton from "../../elements/buttons/IconButton";
import { useTranslation } from "react-i18next";
function LastMessage({ item }) {
  const { t, i18n } = useTranslation();


  const attachementIconWithText = (item) => {
    let icon,text;
    if(item.LCM_attachment_type === 'a'){
      icon = 'mic';
      text =t("audio_title");
    }else if(item.LCM_attachment_type === 'p'){
      icon = 'photo_camera';
      text =  t("image");
    }else if(item.LCM_attachment_type === 'v'){
      icon = 'photo_camera';
      text =  t("video_title");
    }else if(item.LCM_attachment_type === 'd'){
      icon = 'attachment';
      text = t("document_title");
    }else if(item.LCM_attachment_type === 'c'){
      icon = 'contacts';
      text =  t("contact_title");
    }
    return (
    <IconButton 
      text={text}
      icon={icon}
      clear={true}
      className="last-message"
    />
    )
  }

  return (
    item.LC_message_type === 'attachment' ? 
    <span>
      {attachementIconWithText(item)}
    </span>
    : (
    <span className="truncate-text">
      {item.LCM_text && item.LCM_text.substring(0, 175)}
      {item.LCM_text.length > 175 && "..."}
    </span>
    )
  );
}
export default LastMessage;
