const defaultStyle = {
    control: {
      fontSize: 16,
      width: "100%",
      border:0,
      lineHeight: 1.5,
      padding: "5 0",
      height: "auto",
      resize: "none",
    },
    '&multiLine': {
      control: {
        minHeight: 80,
      },
      highlighter: {
        padding: 0,
      },
    },
    '&singleLine': {
      display: 'inline-block',
      width: 180,
  
      highlighter: {
        padding: 1,
        border: '2px inset transparent'
      },
      input: {
        padding: 1,
        border: '2px inset'
      }
    },
    suggestions: {
      marginTop:24,
      list: {
        backgroundColor: 'white',
        fontSize: 14
      },
      item: {
        padding: 5,
        width:"100%",
        '&focused': {
          backgroundColor: '#cee4e5'
        }
      }
    }
  };
  
  export default defaultStyle;
  