import React, { Component } from "react";
import "../sharePostDialog/sharePostDialog.scss";
import { Modal, Toast } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import IconButton from "../../elements/buttons/IconButton";
import {
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { FileCopyRounded } from "@mui/icons-material";
// import {LinkedIn} from '@material-ui/icons';

import LinkedIn from "../../../assets/linkedin_icon.svg";
class SharePostDialog extends React.Component {
  state = {
    message: "'Have a look on : \n'",
    content: "",
    showSuccessToast: false,
  };

  componentDidMount() {}

  submitForm = (e) => {};

  copyToClipBoard = () => {
    const el = document.createElement("textarea");
        console.log(this.props.shareUrlType,this.props.shareUrl,"url");
    if (this.props.shareUrlType !== "group") {
      // const postId = this.props.shareUrl.split(",")[1];
      // const url = new URL(this.props.shareUrl);
      // const link = `${url.origin}/`;
      el.value = this.props.shareUrl;
    } else {
      el.value = this.props.shareUrl;
      // let index = shareUrl.indexOf("/Gj9J");
      // let firstHalfString = shareUrl.slice(0, index).concat("/#/");
      // let secondHalfString = shareUrl.slice(index + 1, shareUrl.length);
      // let finalString = firstHalfString.concat(secondHalfString);
      // el.value = finalString;
    }

    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.handleToast();
  };

  handleToast = () => {
    this.setState({
      showSuccessToast: true,
    });
  };

  getModalTitle = () => {
    let type = this.props.shareUrlType;
    const { t } = this.props;

    switch (type) {
      case "group":
        return t("share_group");

      case "media":
        return t("share_message_title");

      default:
        return t("share_post");
    }
  };
  getSharedTitle = () => {
    let type = this.props.shareUrlType;
    const { t } = this.props;

    switch (type) {
      case "group":
        return this.state.title;

      case "media":
        return "";

      default:
        return this.state.title;
    }
  };

  linkdInExtraProps() {
    let type = this.props.shareUrlType;

    switch (type) {
      case "group":
        return this.state.title;

      case "media":
        return "";

      default:
        return this.state.title;
    }
  }

  getSharedLinkedinUrl = () => {
    
    let shareUrl = this.props.shareUrl;
    let postId = shareUrl.split(",")[1];

    let splittedUrl1 = shareUrl.split("/")[0];
    let splittedUrl2 = shareUrl.split("/")[2];
    // let url=splittedUrl1+"//"+splittedUrl2+"/#"+"/post/unauth/"+postId

    let url
    if( this?.props?.typeChat === "bubble"){
      url = this.props.shareUrl
    }
    else{
      url = `https://locciwebnew.oursitedemo.com/#/post/unauth/${postId}`;
    }
    return encodeURIComponent(url);

    return;
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Modal
          centered
          show={this.props.showModal}
          onHide={this.props.handleClose}
          contentClassName="share-post-modal"
        >
          <Modal.Body>
            <div className="modal-header">
              <h2>{this.getModalTitle()}</h2>
              <IconButton
                clear="true"
                icon="close"
                handleClick={this.props.handleClose}
              />
            </div>
            <TwitterShareButton
              url={this.props.shareUrl}
              title={this.getSharedTitle()}
              className="share-button"
              onShareWindowClose={() => this.props.handleClose()}
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <FacebookShareButton
              url={this.props.shareUrl}
              title={this.getSharedTitle()}
              
              className="share-button"
              onShareWindowClose={() => this.props.handleClose()}
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>

            <img
              src={LinkedIn}
              className="share-button linkdlin"
              onClick={() => {
                window.open(`http://www.linkedin.com/shareArticle?mini=true&url=
                ${this.getSharedLinkedinUrl()}`);
                this.props.handleClose();
              }}
            />
            <WhatsappShareButton
              url={this.props.shareUrl}
              title={this.getSharedTitle()}
              className="share-button"
              onShareWindowClose={() => this.props.handleClose()}
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            {this.props.shareUrlType !== "media" && (
              <FileCopyRounded
                className="share-button copy"
                onClick={() => this.copyToClipBoard()}
              />
            )}
          </Modal.Body>
          <Toast
            style={{
              position: "absolute",
            width: "100%",
            maxWidth: "300px",
            right: "calc(50% - 150px)",
            }}
            onClose={() => this.setState({ showSuccessToast: false })}
            show={this.state.showSuccessToast}
            delay={2000}
            autohide
          >
            <Toast.Body>{t("link-copied")}</Toast.Body>
          </Toast>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(SharePostDialog);
