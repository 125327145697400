import React, { Component, useTransition } from "react";
import { Link } from "react-router-dom";
import { getMyProfile, getProfile } from "../../api/user";;
import "./Navbar.scss";
import NavbarActions from "./NavbarActions";
import { getMyGroups, leaveGroup } from "../../api/groups";
import { mapStateToProps } from "lib/store/mapStateToProps";
import * as GlobalStore from 'lib/store/global';
import LocciLogos from "../../../src/assets/locciLogo.png";
import locciIcon from "../../assets/newsIcon.png";

import { useTranslation, withTranslation } from "react-i18next";
import i18n from "i18next";
import { connect } from "react-redux";
import { withRouter } from "../customNavigate/withRouter";
import IconButton from "components/elements/buttons/IconButton";
import NavbarItems from "./NavbarItems";
import NavbarProfileDropdown from "./NavbarProfileDropdown";
import { getProfileFromAPIResponse } from "lib/context/authActions";

class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      profile: {},
      groups: [],
      regionalAvatar: '',
      isSearch: false,
    };
  }

  componentDidMount() {
    if (this.props.token){
      this.fetchMyGroupd();  
      this.getMyProfile();
    }

    this.props.publicFeedIcon(null);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token) {
      this.getMyProfile();
      
    }      
    if (prevProps.profile !== this.props.profile) {
      this.setState({ profile: this.props.profile });
    } 
  }

  getMyProfile() {
    getProfile(this.props.token).then((res) => {
      this.setState({ profile: res.data.OK });
      const profile = getProfileFromAPIResponse(res.data);
      localStorage.setItem('profile', JSON.stringify(profile));
      this.props.isPublicPersonalize(this.state.profile.LCU_IsPublicPersonalize == 'y')
    });
  }

  fetchMyGroupd() {
    
    getMyGroups(this.props.token, this.props.query).then((res) => {
      if (res.data.OK) {
        let regionalAvatar = res.data.LOP_avatar ? res.data.LOP_avatar : locciIcon;

        this.props.publicFeedIcon(regionalAvatar);

        this.setState({ groups: res.data.grp });

        // if (this.state.groups === undefined || this.state.groups === [])
        //   this.setState({ noDataTitle: "empty_grp_list" });
      }
    });
  } 

  render() {
    document.title = i18n.t("app_title");
    const { t } = this.props;

    return (
      <div className="site-navbar">
        <div className="navbar-container">
          <div className="navbar-left-side navbar-logo">
              <Link to={this.props.token ? "/" : "/login"}>
                <span id="NavTitle" className="navbar-title">
                  <img src={LocciLogos} />
                </span>
              </Link>
          </div>

          <div className="navbar-content">
            {this.props.token && this.state.profile ? (
              <NavbarItems
                data={this.state.profile}
                token={this.props.token}
              />
            ) : (
              <></>
            )}
          </div>

          <div className="navbar-right-side">
          {this.props.token && this.state.profile ? (
            <NavbarProfileDropdown
              data={this.state.profile}
              token={this.props.token}
            />
          ) : (
            <></>
          )}
          </div>
        </div>
        {/* {this.props.token && this.state.profile && (
          <div className="site-navbar-bottom">
            <div className="bottom-row"> 
              {(this.props.location.pathname === "/" ||
                (this.props.matchPath && this.props?.showAddPost ) ||
                (this.props.location.pathname === "/public-feed" && this?.props?.showPublicPostRight) ||
                this.props.location.pathname === "/inbox") && 
              (
                  <span className="addPost">
                    <i className="material-icons white">control_point</i>
                  </span>
              )}
              {this.props.token && this.state.profile ? (
                <NavbarActions
                  data={this.state.profile}
                  myGroups={this.state.groups}
                  token={this.props.token}
                  onPublicFeedToggle={() => this.getMyProfile()}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        )} */}
      </div>
    );
  }
}

const mapDispatchToProps = {
  search: GlobalStore.search,
  publicFeedIcon: GlobalStore.setPublicFeedIcon,
  isPublicPersonalize: GlobalStore.setIsPublicPersonalize
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar)));

//export default Navbar;
// export default withTranslation()(Navbar);
