
import Compressor from 'compressorjs';

export const compressImage = (file) => {
  return new Promise((resolve, reject) => {
      new Compressor(file, {
          quality: 0.8, // Adjust compression quality as needed
          success(compressedFile) {
              resolve(compressedFile);
          },
          error(error) {
              console.error('Compression error:', error);
              reject(error);
          },
      });
  });
};