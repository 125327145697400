// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDSZMpjUe2_MHtIR6UbIblJHfh8W3JnTyo",
  authDomain: "loci-ed7b0.firebaseapp.com",
  databaseURL: "https://loci-ed7b0.firebaseio.com",
  projectId: "loci-ed7b0",
  storageBucket: "loci-ed7b0.appspot.com",
  messagingSenderId: "862402958442",
  appId: "1:862402958442:web:1de956042930d1e74ae613"
};

// Initialize Firebase
let firebaseApp,messaging;
try {
firebaseApp = initializeApp(firebaseConfig);
messaging = getMessaging(firebaseApp);
// const analytics = getAnalytics(firebaseApp);
}
catch(e) {
  console.error("e",e)
}
// const messaging={}


export const getFirebaseToken = (setTokenFound) => {
    return getToken(messaging, {vapidKey: 'BIbqwBHAavlEodZpyXaV0_jlv8ZRDZfljg55yMxAwYI4biCGReg0N-YjjJmgXWhhbPVA5IWy9quflm0B1nGlioI'}).then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        setTokenFound(currentToken);
       
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound('');
        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
  }



export const onMessageListener = (cb) => messaging? onMessage(messaging,cb): ()=>{};