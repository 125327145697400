import { Card } from "components/shared/card";
import { HandleLoadingState } from "components/shared/handle-loading-state";
import SinglePost from "components/single-group/feed/SinglePost";
import { logError } from "lib/utils/logError";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { getPostDataWithoutAuth } from "../../../api/posts";
import Redirect from "../../customNavigate/redirect";
import Loader from "../single-post/PostLoader/postLoader";

type Props = {
  token: string;
};

const UnauthPost = ({ token }: Props) => {
  const params = useParams<{ id: string }>();

  const [post, setPost] = useState<any>(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    getPostDataById();
  }, [params.id]);

  async function getPostDataById() {
    try {
      const response = await getPostDataWithoutAuth(params.id);
      if (response.data.OK) {
        const data = response.data.OK;
        setLoading(false);
        setPost(data);
      }
    } catch (error) {
      logError(error);
    }
  }
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>LOCCI</title>
        <meta name='description' content={post?.LCP_mess} />
        <meta property='og:title' content={post?.LCP_subject} />
        <meta property='og:image' content={post?.USE_pic} />
      </Helmet>

      {token ? (
        <Redirect to={{ pathname: `/post/${params.id}` }} />
      ) : (
        <div className='feed main-content mr-0'>
          <HandleLoadingState loading={isLoading} loadingPlaceholder={<Loader />}>
            <div className='main-content-narrow'>
              {post && (
                <Card>
                  <SinglePost
                    token={token}
                    post={post}
                    isMember={false}
                    analysePostAction={() => {}}
                    sharePostAction={() => {}}
                    editPostAction={() => {}}
                    deletePostAction={() => {}}
                    reportPostAction={() => {}}
                    userDetailsAction={() => {}}
                    groupInfoAction={() => {}}
                  />
                </Card>
              )}
            </div>
          </HandleLoadingState>
        </div>
      )}
    </>
  );
};

export default UnauthPost;
