import "bootstrap/dist/css/bootstrap.min.css";
import store, { persistor } from "lib/store";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import Theme from "styles/theme/Theme";
import App from "./App";
import "./i18n";
import { AuthProvider } from "./lib/context/authContext";
import GlobalStateProvider from "./lib/context/global-state/GlobalStateProvider";
import reportWebVitals from "./reportWebVitals";

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <HashRouter>
    <Theme>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthProvider>
            <GlobalStateProvider>
              <App />
            </GlobalStateProvider>
          </AuthProvider>
        </PersistGate>
      </Provider>
    </Theme>
  </HashRouter>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
