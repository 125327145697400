import React, { Component, useState } from "react";
import "../report-post/ReportPost.scss";
import Button from "../../elements/buttons/Button";
import { Form, Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";

class ConfirmPostDialog extends Component {
  state = {
    message: "",
    content: "",
  };

  componentDidMount() {}

  submitForm = () => {
    this.props.onSubmit();
    this.props.handleClose();
  };
  render() {
    const { t } = this.props;
    console.log('props',this.props); 
    return (
      <>
        <Modal
          centered
          show={this.props.deleting}
          onHide={this.props.handleClose}
          contentClassName="confirm_post_modal"
        >
          <Modal.Body>
            <h2>{t("confirm_post")}</h2>
            <>
              {(this.props.groupName && !this.props?.create) ?  (
                <p>
                  {this?.props?.groupId===0 ? t("share_post_confirm_regional") : 
                  t("share_post_confirm_group")} {this?.props?.groupId !==0 && this.props.groupName + "?"}
                  
                </p>
              ) : (this.props.groupName ? <p>{t("add_edit_post_send_confirm_group")+" "+this.props.groupName+"?"}</p> :
                <p>{t("add_edit_post_send_confirm")}</p>
              )}
              <div className="report-actions">
                <Button
                  clear="true"
                  text={t("cancel_alert")}
                  handleClick={this.props.handleClose}
                />
                <Button
                  text={t("member_request_screen_confirm_button_text")}
                  handleClick={this.submitForm}
                />
              </div>
            </>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(ConfirmPostDialog);
