import styled, { css } from "styled-components";

export const ChatWidget = styled.aside`
    display:flex;
    flex-direction: column;
    width:100%;
    background-color: rgb(253, 252, 255);
    border-radius: 10px 10px 0 0;
    width: 100%;
    overflow: hidden;
    transition: height 0.5s ease;
    margin-top: 40px;
`;

export const ChatHeader = styled.div`
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eeeeee;
    cursor: pointer;
    
    .header-actions {
        display:flex;
        justify-content: flex-end;
        align-items:center;
        gap: 10px;
    }
`;

export const ChatContainer = styled.div<{$show?:boolean}>`
    ${(props) => 
        props.$show 
        ? "display: flex" 
        : "display: none"
    };
    padding-top: 10px;
    flex-direction: column;
    overflow-y: auto;
`;

export const ChatList = styled.div`
    display:flex;
    flex-direction: column;
    width:100%;
    padding: 0;
`;

export const ChatListItem = styled.div<{$unread?: string; $isSelected?:boolean;}>`
    display: flex;
    gap: 10px;
    height:64px;
    padding: 5px 10px;
    border-bottom: 1px solid #eeeeee;
    cursor:pointer;

    ${(props) => 
        props.$unread 
        && !props.$isSelected
        && parseInt(props.$unread) > 0 
        &&  "background-color: #f3fbff"
    };

    .avatar {
        border-radius: 4px !important;
    }

    .card-content {
        flex: 1;
        overflow:hidden;

        h3 {
            font-size: 0.9rem;
            font-weight: 700;
            flex: 1;
            margin-bottom:0;
        }

        .small {
            font-size: 0.825rem;
        }

        .bubble-message {
            font-size: 0.825rem;
            margin: 0;
            overflow:hidden;
            max-height: 33px;
            line-height: 1rem;
            text-overflow: ellipsis;
            word-break: break-word;
            width: 100%;
            display: inline-flex;
            align-items: flex-start;
        }
    }

    ${(props) => 
        props.$isSelected
        &&  css`
            background-color: var(--secondary-color);
            color: white;
        `
    };
`;

export const NewBadge = styled.div`
    width:12px;
    height:12px;
    background: var(--primary-color);
    border-radius:50%;
`;