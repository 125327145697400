export const HeartIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 21 19'
    className='fill-current'
    {...props}
  >
    <path
      fill='var(--secondary-color)'
      d='M10.5 16C8.167 14.667 3.4 11.2 3 8c-.5-4 3-6 5.5-5 1.53.612 1.987 1.165 2.043 1.615C10.716 3.848 11.453 3 13.5 3c3 0 5 1.5 4.5 5-.4 2.8-5.166 6.5-7.5 8Z'
    />
  </svg>
);
