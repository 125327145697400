import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Button from "../elements/buttons/Button";

const GlobalConfirmDialogue = (props) => {
  const { t } = useTranslation();

  return (
    <Modal
      centered
      show={props.show}
      onHide={() => props.closeModal()}
      contentClassName="global-confirmation-modal"
    >
      <Modal.Body>
        <h2>{t(props?.title)}</h2>
        {props?.dynamicContent ? (
          <p>
            {t(props?.content)} <b>{props?.userName}</b> {t(props?.secondContent)} {props?.name && <b>{" " + props?.name}</b>}?{" "}
          </p>
        ) : (
          <p>

            {t(props?.content)}{props?.name && <b>{" " + props?.name}</b>}?{" "}
          </p>
        )}
        {/* {props?.subContent && <p>{t(props?.subContent)}</p>} */}

        <div className="report-actions">
          <Button
            clear="true"
            text={t("cancel_alert")}
            handleClick={() => props.closeModal()}
          />
          <Button
            
            text={t("ok")}
            handleClick={() => props.onSubmit()}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default GlobalConfirmDialogue;
