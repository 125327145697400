import React, { Component, useState } from "react";
import "../report-post/ReportPost.scss";
import Button from "../../elements/buttons/Button";
import { Form, Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import IconButton from "../../elements/buttons/IconButton";
import "./analysePost.scss";
import classNames from "classnames";
import AnalyseTabData from "./analyseTabData";
import CommentTabData from "./commentTabData";
import FollowTabData from "./followTabData";
import LikeTabData from "./likeTabData";
import { getAnlyseData } from "../../../api/posts";
import { data } from "jquery";
import UserDetails from "../../user-details";

class AnalysePostDialog extends Component {
  state = {
    currentTabName: "ANALYSE_TAB",
    commentsData: [],
    followData: [],
    likeData: [],
    token: "",
  };

  setCurrentTab(tabName) {
    this.setState({
      currentTabName: tabName,
    });
  }

  componentDidMount() {

    this.getData();
  }

  async getData() {
    try {
      const response = await getAnlyseData(this.props.token, this.props.postID);
      if (response.data) {
        const data = response.data;

        this.setState({
          commentsData: data.comment,
          followData: data.favorite,
          likeData: data.likes,
          token: data.TMBP_ACC_TOKEN,
        });

        // console.log('comments',this.state.commentsData);
      }
      // console.log('res',response);
    } catch (e) {
      console.log(e);
    }
  }

  submitForm = (e) => {
    e.preventDefault();
    this.props.handleClose();
  };

  clickOnName = (userId) => {
    this.setState({
      showUserDetails: true,
      userId: userId,
    });
  };
  render() {
    const { t } = this.props;
    return (
      <>
        <Modal
          centered
          show={this.props.analysing}
          onHide={this.props.handleClose}
          scrollable
          className="analyseModal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-100 text-center">
              <b>{t("post_activities_title_primary")}</b>
              {t("post_activities_title_secondary")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="no-padding">
            <div className="icon-cont">
              <div
                className={classNames(
                  "icon-div",
                  this.state.currentTabName === "ANALYSE_TAB" ? "active" : ""
                )}
                onClick={() => this.setCurrentTab("ANALYSE_TAB")}
              >
                <IconButton
                  icon="assessment"
                  clear={true}
                  className={classNames(
                    this.state.currentTabName === "ANALYSE_TAB"
                      ? "active"
                      : "inactive"
                  )}
                  text={""}
                />
                <div className="count">
                  {this.state.commentsData.length +
                    this.state.followData.length + this.state.likeData.length}
                </div>
              </div>
              <div
                className={classNames(
                  "icon-div",
                  this.state.currentTabName === "COMMENT_TAB" ? "active" : ""
                )}
                onClick={() => this.setCurrentTab("COMMENT_TAB")}
              >
                <IconButton
                  icon="chat"
                  outlined={true}
                  clear={true}
                  className={classNames(
                    this.state.currentTabName === "COMMENT_TAB"
                      ? "active"
                      : "inactive"
                  )}
                />
                <div className="count">{this.state.commentsData.length}</div>
              </div>

              <div
                className={classNames(
                  "icon-div",
                  this.state.currentTabName === "LIKE_TAB" ? "active" : ""
                )}
                onClick={() => this.setCurrentTab("LIKE_TAB")}
              >
                <IconButton
                  icon="thumb_up"
                  className={classNames(
                    this.state.currentTabName === "LIKE_TAB"
                      ? "active"
                      : "inactive"
                  )}
                  clear={true}
                />
                <div className="count">{this.state.likeData.length}</div>
              </div>

              <div
                className={classNames(
                  "icon-div",
                  this.state.currentTabName === "FOLLOW_TAB" ? "active" : ""
                )}
                onClick={() => this.setCurrentTab("FOLLOW_TAB")}
              >
                <IconButton
                  icon="star_border"
                  className={classNames(
                    this.state.currentTabName === "FOLLOW_TAB"
                      ? "active"
                      : "inactive"
                  )}
                  clear={true}
                />
                <div className="count">{this.state.followData.length}</div>
              </div>
            </div>

            <div className="tabData">
              {this.state.currentTabName === "ANALYSE_TAB" && (
                <AnalyseTabData
                  parentMethod={(param) => this.setCurrentTab(param)}
                  comments={this.state.commentsData}
                  favourites={this.state.followData}
                  likes={this.state.likeData}
                  token={this.state.token}
                  postId={this.props.postID}
                  // clickOnName={(data) => this.clickOnName(data)}
                />
              )}

              {this.state.currentTabName === "COMMENT_TAB" && (
                <CommentTabData
                  comments={this.state.commentsData}
                  token={this.state.token}
                  originFrom="child"
                  postId={this.props.postID}
                />
              )}

              {this.state.currentTabName === "LIKE_TAB" && (
                <LikeTabData
                  likes={this.state.likeData}
                  token={this.state.token}
                  originFrom="child"
                  postId={this.props.postID}
                />
              )}

              {this.state.currentTabName === "FOLLOW_TAB" && (
                <FollowTabData
                  favourites={this.state.followData}
                  token={this.state.token}
                  originFrom="child"
                  postId={this.props.postID}
                />
              )}
            </div>
          </Modal.Body>
        </Modal>

        {this.state.showUserDetails && (
          <UserDetails
            id={this.state.userId}
            {...this.props}
            handleClose={() => this.setState({ showUserDetails: false })}
          />
        )}
      </>
    );
  }
}
export default withTranslation()(AnalysePostDialog);
