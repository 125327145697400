import React, { useState } from "react";
import "./Login.scss";
import Button from "../../components/elements/buttons/Button";
import { Form, Modal } from "react-bootstrap";
import { resetPassword } from "../../api/user";
import IconLink from "../../components/elements/buttons/IconLink";
import { useTranslation } from "react-i18next";
import IconButton from "../../components/elements/buttons/IconButton";

function ForgotPassword(props) {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [modal, setModal] = useState(false);

  const validateForm = async (e) => {
    e.preventDefault();
    setMessage("");

    resetPassword(email).then((res) => {
      if (res.data.OK) {
        setMessage("password_reset_confirm");
        setModal(true);
      } else {
        setMessage(res.data.ER);
        setModal(true)}
    });
  };

  return (
    <div className="loginPage">
      <div className="loginBox">
        <Form className="loginForm" onSubmit={validateForm}>
          <Form.Group size="lg" controlId="email">
            <Form.Label>{t("email")}</Form.Label>
            <Form.Control
              autoFocus
              type="email"
              placeholder={t("login_email_placeholder")}
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Text className="text-muted">
              {t("lost_password_description")}
            </Form.Text>
          </Form.Group>

          <Button text={t("send_button")} type="submit" className="w-100" />
        </Form>

        <IconLink
          link="/login"
          text={t("login_back")}
          clear={true}
          className="w-100 d-block text-center mt-30"
        />

        {/* <span className="formMessage">{message}</span> */}
      </div>
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        className="custom-modal"
      >
        <IconButton
          clear="true"
          className="closeIcon"
          icon="close"
          handleClick={() => setModal(false)}
        />
        {t(message)}
      </Modal>
    </div>
  );
}

export default ForgotPassword;
