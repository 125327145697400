import { API_BASE_URL, API_BASE_HEADERS } from "../global";

import axios from "./AxiosProvider"


export function joinGroup(
  token,
  groupID = null,
  promo = null,
  inviteCode = null,
  newUser = '0'
) {
  const postData = new FormData();
  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("action", "37");
  postData.append("groupID", groupID);
  postData.append("newUser", newUser);
  postData.append("promo", promo === null || promo === undefined ? "" : promo);
  postData.append(
    "invitationID",
    inviteCode === null || inviteCode === undefined ? "" : inviteCode
  );

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}



export function getMyGroups(token, search = "") {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: "20",
      search: search,
    },
  });
}

export function getMyDefaultGroup(token) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: "67",
      Lang: 'en',
    },
  });
}

export function getGroupsWithPost(token,postID) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: "76",
      Lang: 'en',
      postID
    },
  });
}

export function saveGroup(token, data) {
  const postData = new FormData();
  postData.append("TMBP_ACC_TOKEN", token);
  
  let lang = localStorage.getItem('i18nextLng') ?? "en";

  postData.append("Lang", lang);
  
  if (data.editedGroup) {
    postData.append("action", "32");
    postData.append("NewMod", data.editedGroup);
    const docsToDelete = data.docFiles.filter((x) => x.delete);
    const rulesToDelete = data.rulesFiles.filter((x) => x.delete);
    for (var i = 0; i < docsToDelete.length; i++) {
      deleteGroupMedia(token, docsToDelete[i].id, data.editedGroup);
    }
    for (var i = 0; i < rulesToDelete.length; i++) {
      deleteGroupMedia(token, rulesToDelete[i].id, data.editedGroup);
    }

    postData.append("LGP_invite", data.isInvite ? "y" : "n");
    postData.append("LGP_viewmember", data.isMemberlist ? "y" : "n");
    postData.append("LGP_post", data.isAllowPost ? "y" : "n");
    postData.append("LGP_confirm", data.isConfirm ? "y" : "n");
    postData.append("LGP_shareable", data.LGP_shareable ? "y" : "n");
    postData.append("LGP_share_to_group", data.LGP_shareable_other_group ? "y" : "n");
    postData.append("LGP_posttopublic", data.LGP_posttopublic ? "y" : "n");
      
    
  } else {
    postData.append("action", "28");
  
    postData.append("LGP_invite", data.isInvite ? "y" : "n");
    postData.append("LGP_viewmember", data.isMemberlist ? "y" : "n");
    postData.append("LGP_post", data.isAllowPost ? "y" : "n");
    postData.append("LGP_confirm", data.isConfirm ? "y" : "n");
    postData.append("LGP_shareable", data.LGP_shareable ? "y" : "n");
    postData.append("LGP_share_to_group", data.LGP_shareable_other_group ? "y" : "n");
    postData.append("LGP_posttopublic", data.LGP_posttopublic ? "y" : "n");
  }

  if (data.avatar && data.avatar.length > 0) {
    let avatar=data.avatar.find(me=>!me.delete) 
    console.log(avatar,"aaa");
    if(avatar){
    postData.append("APR_file", avatar.file); 
    }else{
      postData.append("LGP_delavatar" , 'y')
    }
  }
  //if (data.isPrivate)
  postData.append("LGP_promo", data.promocode ? data.promocode.trim() : '');

  postData.append("LGP_public", !data.isPrivate ? "y" : "n");
  postData.append("LGP_name", data.groupName);
  postData.append("RegTownPlz", data.regTown);
  postData.append("LGP_about", data.about);
  postData.append("LGP_contact", data.emailAddress);
  postData.append("LGP_phone", data.phoneNumber);
  postData.append("LGP_web", data.website);

  


  const docs = data.docFiles.filter((x) => x.file);
  for (var i = 0; i < docs.length; i++)
    postData.append("LGP_docs[]", docs[i].file);

  const rules = data.rulesFiles.filter((x) => x.file);
  for (var i = 0; i < rules.length; i++)
    postData.append("LGP_groupdocs[]", rules[i].file);

  if (data.admins.length > 0) {
    postData.append("LGP_admin[]", data.admins.toString());
  }

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export function searchPublicGroupsbyRadius(token, query, radius) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: "34",
      offset: 0,
      radius: radius,
      search: query ? query : "",
    },
  });
}

export function searchPublicGroupsbyPromo(token, radius, promo) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: "34",
      offset: 0,
      promo: promo,
      radius: radius,
    },
  });
}

export function leaveGroup(token, groupID) {
  const postData = new FormData();
  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("action", "38");
  postData.append("groupID", groupID);

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export function mappPostInGroup(token, groupID,postId) {
  const postData = new FormData();
  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("action", "78");
  postData.append("grpID", groupID);
  postData.append("postID", postId);

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export function getGroupData(token, groupId) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: "31A",
      pID: groupId,
    },
  });
}
export function getGroupAttachmentURL(token, attachmentId) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: "17A",
      id: attachmentId,
    },
  });
}

export function downloadFromUrl(token, data) {
  const link = document.createElement("a");
  const encodedToken = encodeURIComponent(token);
  link.href = `${API_BASE_URL}?TMBP_ACC_TOKEN=${encodedToken}&id=${data.id === undefined ? data.loci_group_mediaID : data.id
    }&action=17A`;
  link.setAttribute("download", data.name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function downloadFromUnauthURL(token, data) {
  const link = document.createElement("a");
  const encodedToken = encodeURIComponent(token);
  link.href = `${API_BASE_URL}?TMBP_ACC_TOKEN=${encodedToken}&id=${data.id === undefined ? data.loci_group_mediaID : data.id
    }&action=17B`;
  link.setAttribute("download", data.name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}



export function getGroupMembers(token, groupId, search = "") {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: "42",
      groupId: groupId,
      search: search
    },
  });
}

export function removeMemberFromGroup(token, groupId, memberId) {
  const postData = new FormData();
  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("action", "45");
  postData.append("groupId", groupId);
  postData.append("FK_userID", memberId);

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}
export function getGroupAbout(token, groupID) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: "31A",
      Lang: "en",
      pID: groupID,
    },
  });
}

export function getJoinRequestsForGroup(token, groupId) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: 41,
      Lang: "en",
      groupId: groupId,
    },
  });
}

export function confirmPendingUser(token, groupId, userId) {
  const postData = new FormData();
  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("action", "40");
  postData.append("groupId", groupId);
  postData.append("FK_userID", userId);

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export function declinePendingUser(token, groupId, memberId) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: 47,
      // Lang: localStorage.getItem("i18nextLng"),
      groupId: groupId,
      FK_userID: memberId,
    },
  });
}

export async function getGroupPermissions(token, groupId) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: '52A',
      Lang: "en",
      groupID: groupId,
    },
  });
}

export async function fetchGroupRules(token, groupId) {
  const postData = new FormData();
  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("action", "56");
  postData.append("Lang", "en");
  postData.append("pID", groupId);

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export async function deleteGroup(token, groupId) {
  const postData = new FormData();
  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("action", "39");
  postData.append("groupID", groupId);

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}
export async function deleteGroupMedia(token, id, groupId) {
  const postData = new FormData();
  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("action", "33");
  postData.append("groupID", groupId);
  postData.append("Del", id);
  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export async function checkGroupCode(token, inviteCode,onlyView) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: 59,
      LOS_invite_id: inviteCode,
      onlyView: onlyView
    },
  });
}

export async function checkGroupAlias(token, groupAlias) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: 79,
      LCP_alias: groupAlias
    },
  });
}     

