import { API_BASE_URL, API_BASE_HEADERS } from '../../global'
import { getMyProfile } from '../../api/user';
import { changeLaguage } from '../../i18n';
import axios from '../../api/AxiosProvider.js';
import { setGCMToken } from '../../api/common.js';
// const axios = require('axios');
export const getProfileFromAPIResponse = (response) => {
  return {
      userId: response.userID,
      name: response.OK.USE_firstname,
      surname: response.OK.USE_surename,
      pic: response.OK.USE_pic,
      premium: response.OK.LCU_mayor === 'y',
      community: response.OK.LCU_community === 'y',
      language: response.OK.FK_languageID, 
      ...response.OK
  };
}

export async function loginUser(dispatch, data) {
  let lang = localStorage.getItem('i18nextLng') ?? "en";

  const loginData = new FormData();
  loginData.append('LOGIN_email', data.email.trim())
  loginData.append('LOGIN_password', data.password)
  loginData.append('Lang', lang.split("-")[0])
  loginData.append('action', 'L')

  dispatch({ type: 'REQUEST_LOGIN' });
  const request = await axios.post(API_BASE_URL, loginData, { headers: API_BASE_HEADERS })  

  if (request.data?.OK && request.data.Plz != null && request.data.Plz != '0') {
    completeLoginWithToken(dispatch, request.data.TMBP_ACC_TOKEN, request.data)
  }
  else {
    dispatch({ type: 'LOGIN_ERROR', error: "Auth Error" });
  }
  return request.data;
}
async function registerGCMToken (token) {
  const gcmToken=localStorage.getItem("gcmtoken");
  try {
    const response = await setGCMToken(token, gcmToken)
    if (response.data.OK)
      return true 
  }
  catch (e) {
    console.log(e)
  }

}
  
export async function sendEmail(email){  
  let lang = localStorage.getItem('i18nextLng') ?? "en";
    const mailData = new FormData();
    mailData.append('LOGIN_email',email) 
    mailData.append('Lang', lang.split("-")[0])
    mailData.append('action','XV') 

    const request = await axios.post(API_BASE_URL,mailData,{ headers: API_BASE_HEADERS }) 
      
      return request?.data
    
}

export async function completeData(dispatch, token, data) { 
  const postData = new FormData();
  postData.append('TMBP_ACC_TOKEN', token);
  postData.append('LastPlz', data.postcodeCity);
  postData.append('LastTown', data.regTown);
  postData.append('LastLang', data.myLang);
  postData.append('LastState', data.myState);
  if (data.promocode)
    postData.append('LastPromo', data.promocode);
  postData.append('action', 'T');

  dispatch({ type: 'REQUEST_LOGIN' });
  const request = await axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS })  
  if (request.data?.OK) {

    completeLoginWithToken(dispatch, request.data.TMBP_ACC_TOKEN, request.data)
  }
  return request.data;
}

export const completeLoginWithToken = async (dispatch, token, data) => {
  var myProfileResponse = await getMyProfile(token);
  var profile = getProfileFromAPIResponse(myProfileResponse.data);
  changeLaguage(profile.language)
  dispatch({ type: 'LOGIN_SUCCESS', payload: data, profile: profile });
  registerGCMToken(token);

  localStorage.setItem('user', JSON.stringify(data));
  localStorage.setItem('profile', JSON.stringify(profile));
}

export async function loginUserWithFacebook(dispatch, data) {

  const loginData = new FormData();
  loginData.append('USE_firstname', data.first_name)
  loginData.append('USE_surename', data.last_name)
  loginData.append('USE_email', data.email)
  loginData.append('USE_fbpic', data.picture.data.url)
  loginData.append('USE_fbid', data.userID)
  loginData.append('Lang', 'en')
  loginData.append('action', 'V')

  dispatch({ type: 'REQUEST_LOGIN' });
  const request = await axios.post(API_BASE_URL, loginData, { headers: API_BASE_HEADERS })

  if (request.data?.OK) {
    completeLoginWithToken(dispatch, request.data.TMBP_ACC_TOKEN, request.data)
  }
  else {
    dispatch({ type: 'LOGIN_ERROR', error: "Auth Error" });
  }
  return request.data;
}

export async function logoutUser(dispatch) {
  dispatch({ type: 'LOGOUT' });
  localStorage.removeItem('user');
  localStorage.removeItem('profile');
  localStorage.removeItem('i18nextLng');
  localStorage.removeItem('ga');
  changeLaguage();
}
