import React, { useState, useEffect } from 'react'
import { Modal } from "react-bootstrap";
import { getGroupPermissions, leaveGroup, getMyDefaultGroup } from "../../api/groups";
import { useAuthState } from 'lib/context/authContext';
import IconLink from '../elements/buttons/IconLink';
import "./Groups.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ToastModal from '../commons/ToastModal';
import GlobalConfirmDialogue from '../commons/GlobalConfirmDialogue';

export default function GroupPortal(props) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const userDetails = useAuthState();
    const [showModal, setShowModal] = useState(props.showGroupPortal);
    const [perms, setPerms] = useState([]);
    const [defaultGrpId, setDefaultGrpId] = useState(null)
    const [showConfirm, setShowConfirm] = useState(false)
    const [exitGroup, setExitGroup] = useState(false)


    useEffect(() => {
        getData()
    }, [props.groupId]);

    useEffect(() => {
        getDefaultGroup()
    }, [])

    const onCloseModal = () => {
        props.handleClose();
        setShowModal(false)
    }

    const getDefaultGroup = () => {
        getMyDefaultGroup(props.token).then((res) => {
            if (res.data.OK) {
                setDefaultGrpId(res.data.data)
            }
        });
    }


    const getData = async () => {
        var res = await getGroupPermissions(userDetails.token, props.groupId).then(res => {
            if (res.data.OK) {
                var resData = res.data.data;
                var groupPerms = {
                    isAdmin: resData.admin == "1",
                    allowPost: resData.LGP_post === 'y',
                    isSuperAdmin: resData.superAdmin == "1",
                    canInvite: resData.LGP_invite === 'y',
                    canViewMembers: resData.LGP_viewmember === 'y',
                    canPost: resData.LGP_viewmember === 'y',
                    canConfirmInvite: resData.LGP_confirm === 'y'
                }
                setPerms(groupPerms);
            }
        });


    }

    const onLeaveGroupClick = async () => {

        try {
            const res = await leaveGroup(userDetails.token, props.groupId);
            console.log(res);
            if (res?.OK) {
                setShowConfirm(true);
                //history("/groups");
            }
            else {
                alert(res.ER);
            }
        }
        catch (e) {
            alert(e)
        }
    }

    return (
        <>
            <Modal centered show={showModal} onHide={onCloseModal}>
                <Modal.Header closeButton >
                    <Modal.Title>{t('group_portal_nav_title_primary') + ' ' + t('group_portal_nav_title_secondary')}</Modal.Title>
                 </Modal.Header>
                <Modal.Body className='group-body-padding'  >
                    {/* <div className={"groupPortalIconBtnHolder"}>
                        <IconLink clear={true} link={"/group/" + props.groupId} text={t('group_portal_nav_back')} icon={"keyboard_return"} className={"text"} handleClick={onCloseModal} />
                    </div> */}
                    {/* <div className={"groupPortalIconBtnHolder"}>
                        <div className="border-bottom">
                        </div>
                    </div> */}
                    <div className={"groupPortalIconBtnHolder"}>
                        <IconLink clear={true} link={"/group/" + props.groupId + "/about"} text={t('group_portal_nav_about_us')} icon={"error_outline"} className={"text"} handleClick={() => { }} />
                    </div>

                    {
                        perms.canInvite &&
                        <div className={"groupPortalIconBtnHolder"}>
                            <IconLink clear={true} link={"/group/" + props.groupId + "/invite"} text={t('group_portal_nav_invite')} icon={"person_add_alt"} className={"text"} handleClick={() => { }} />
                        </div>
                    }

                    {
                        perms.canViewMembers &&
                        <div className={"groupPortalIconBtnHolder"}>
                            <IconLink clear={true} link={"/group/" + props.groupId + "/members"} text={t('group_portal_nav_members')} icon={"people_outline"} className={"text"} handleClick={() => { }} />
                        </div>
                    }
                    {
                        perms.canConfirmInvite &&
                        <div className={"groupPortalIconBtnHolder"}>
                            <IconLink clear={true} link={"/group/" + props.groupId + "/requests"} text={t('group_portal_nav_req')} icon={"error_outline"} className={"text"} handleClick={() => { }} />
                        </div>
                    }

                    {
                        !props.isGroupAdmin ?
                        <div className={"groupPortalIconBtnHolder"}>
                            <IconLink clear={true} link={"/group-settings/" + props.groupId} text={t('settings')} icon={"settings"} className={"text"} handleClick={() => { }} />
                        </div> : 
                        <div className={"groupPortalIconBtnHolder"}>
                            <IconLink clear={true} handleClick={props.openSettingModal} text={t('settings')} icon={"settings"} className={"text"}  />
                        </div>
                    }

                    <div className={"groupPortalIconBtnHolder"}>
                        <div className="border-bottom">
                        </div>
                    </div>
                    {defaultGrpId != props.groupId && <IconLink clear={true} text={t('group_portal_leave_group')} icon={"exit_to_app"} className={"text"} disabled={perms.isSuperAdmin}

                        // handleClick={() => onLeaveGroupClick()}
                        handleClick={() => { 
                            setExitGroup(true)
                            // alert(t("grp-leave-msg"))
                            // setShowConfirm(true)
                          
                        }}
                    />}
                </Modal.Body>
            </Modal> 
            {exitGroup && (
          <GlobalConfirmDialogue
            show={exitGroup}
            title={"group_portal_leave_modal_leave_button_text"}
            content={"group_portal_leave_group_text"}
            name={""}
            closeModal={() =>
              setExitGroup(false)
            }
            onSubmit={() => {
            leaveGroup(userDetails.token, props.groupId); 
             setExitGroup(false)
            navigate(-1);

            }}
          />
        )}
            {showConfirm && <ToastModal msg={"grp-leave-msg"} />}
        </>
    )
}
