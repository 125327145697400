import React, { Component, useEffect, useState } from "react";
import {
  Dropdown,
  Form,
  Spinner,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import IconButton from "../elements/buttons/IconButton";
import { logoutUser } from "lib/context/authActions";
import CustomToggleButtton from "../elements/buttons/CustomToggleButton";

import { useNavigate, useLocation } from "react-router-dom";
import IconLink from "../elements/buttons/IconLink";
import { connect, useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { mapStateToProps } from "lib/store/mapStateToProps";
import { useAuthDispatch } from "lib/context/authContext";
import { useTranslation } from "react-i18next";
import { Box, Tooltip, Typography } from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
// import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";

import locciIcon from "../../assets/newsIcon.png";
import { getMyDefaultGroup, getMyGroups } from "../../api/groups";
import { applyFilterOnPosts } from "../../api/posts";
import filterIcon from "../../assets/menu.png";
import { notificationCount } from "../../api/common";
import { getMyProfile } from "../../api/user";
import { main_menu } from "../sidebar/menu";
import SidebarLink from "../elements/sidebar-link";
import { useAppDispatch, useAppSelector } from "lib/store";
import * as GlobalStore from 'lib/store/global';

function NavbarProfileDropdown({ location, query, data, token, onPublicFeedToggle }) {

    const authDispatch = useAuthDispatch();
    const dispatch = useAppDispatch();
    const [isSearch, setIsSearch] = useState(false);
    const [loc, setLoc] = useState("");
    const [navQuery, setQuery] = useState("");
    const history = useNavigate();
    const [customClass, setCustomClass] = useState("");
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [myGroups, setMyGroups] = useState([]);

    const [defaultGrpId, setDefaultGrpId] = useState(null);
    const [count, setCount] = useState("+");
    const numbeOfNotifications = useAppSelector(GlobalStore.selectNumbeOfNotifications)
    const refreshNotification = useAppSelector(GlobalStore.selectRefreshNotification)
    const showToggleSearch = useAppSelector(GlobalStore.selectShowToggleSearch)
    const showRegionalData = useAppSelector(GlobalStore.selectShowRegionalData)
    const showPublicPostRight = useAppSelector(GlobalStore.selectShowPublicPostRight)
    const publicFeedIcon = useAppSelector(GlobalStore.selectPublicFeedIcon)
    const locations = useLocation();

    const { t, i18n } = useTranslation();
    const [showPostFilter, setShowPostFilter] = useState(false);

    const [selectAll, setSelectAll] = useState(false);
    const [loader, setLoader] = useState(false);
    const [isPersonalized, toggleIsPersonalized] = useState(true);
    const [showRegionalFeed, setShowRegionalFedd] = useState(true);

    const logout = async (e) => {
        try {
          await logoutUser(authDispatch)
            .then((res) => {
                history("/login");
            })
            .catch((err) => {
              console.log(err);
            });
        } catch (error) {
          console.log(error);
        }
    };

    return (
        <Dropdown>
            <Dropdown.Toggle id="dropdown-profile" className="btn btn-clear">
            <img
                className="img-borderr"
                src={
                data.USE_pic
                    ? data.USE_pic
                    : data.LCU_fbpic
                    ? data.LCU_fbpic
                    : "/images/profile.png"
                }
            />
            {/* <i className="material-icons white ml-5">keyboard_arrow_down</i> */}
            </Dropdown.Toggle>

            <Dropdown.Menu>
            <IconLink
                text={t("view_profile_title_primary")}
                icon="face"
                clear="true"
                className={classNames({
                "link-active": location === "/my-profile",
                })}
                link="/my-profile"
            />
            <IconLink
                text={t("settings")}
                icon="settings"
                clear="true"
                className={classNames({ "link-active": location === "/settings" })}
                link="/settings"
            />
            
            <IconLink
                text={t("followers")}
                icon="person"
                clear="true"
                className={classNames({ "link-active": location === "/followers", "mobileMenu": true })}
                link="/followers"
            />
            <IconLink
                text={t("add_edit_post_general_info")}
                icon="assignment"
                clear="true"
                className={classNames({ "link-active": location === "/general" })}
                link="/general"
            />
            <IconLink
                text={t("blacklist")}
                icon="block"
                clear="true"
                className={classNames({ "link-active": location === "/blacklist", "mobileMenu": true })}
                link="/blacklist"
            />
            <IconLink
                text={t("FAQ_support")}
                icon="contact_support"
                clear="true"
                className={classNames({
                    "link-active": location === "/FAQAndSupport",
                })}
                link="/FAQAndSupport"
            />

            <IconButton
                text={t("log_out")}
                icon="close"
                clear="true"
                handleClick={logout}
                className="link icon-link"
            />
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default connect(mapStateToProps)(NavbarProfileDropdown);