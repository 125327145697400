import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const DETECTION_OPTIONS = {
  order: ["localStorage", "navigator"],
  caches: ["localStorage"],
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: DETECTION_OPTIONS,
    fallbackLng: "de",
    interpolation: {
      escapeValue: false,
    },
  });

export const determineLanguageAPI = (apiLanguageNumber: string) => {
  switch (apiLanguageNumber) {
    case "1":
      return "en";
    case "2":
      return "de";
    case "4":
      return "it";
    case "8":
      return "hu";
    case "13":
      return "es";
    case "15":
      return "ru";
    case "21":
      return "ro";
    case "25":
      return "ca";
    case "27":
      return "cs";
    default:
      return undefined;
  }
};

export const changeLaguage = (languageFromAPI: string) => {
  if (languageFromAPI) {
    const locale = determineLanguageAPI(languageFromAPI);
    i18n.changeLanguage(locale);
  } else {
    i18n.changeLanguage();
  }
};

export default i18n;
