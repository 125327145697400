import React, { Component } from "react";
import {
  getGroupAbout,
  getGroupPermissions,
  getGroupAttachmentURL,
  downloadFromUrl,
} from "../../api/groups";

import PerfectScrollbar from "react-perfect-scrollbar";
import Checkbox from "../elements/checkbox";
import IconButton from "../elements/buttons/IconButton";
import EditGroup from "./edit-group";
import GroupPortal from "./GroupPortal";
import { Container, Row, Col, Card, Image, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import BackButton from "../commons/BackButton";
import { API_BASE_URL, API_BASE_HEADERS } from "../../global";
import profileImage from "../../assets/apple_logo.png";
import "./viewgroupnew.scss"; 
import { withRouter } from "../customNavigate/withRouter";


const axios = require("axios");

class ViewGroupNew extends Component {
  state = {
    data: {},
    loading: true,
    editing: false,
    showGroupPortal: false,
    groupId: null,
    isShownInModal: false,
    allowGroupPortal: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.callGetGroupDataAPI();
  }
  onDownloadClick(data) {
    downloadFromUrl(this.props.token, data);
  }

  callGetGroupDataAPI() {
    let groupId = this.props?.match.params.id;
    console.log(groupId);
    if (!groupId) {
      groupId = this.props.groupId;
      this.setState({ isShownInModal: true, allowGroupPortal: false });
    } else {
      getGroupPermissions(this.props.token, groupId).then((res) => {
        if (res.data.OK) {
          this.setState({ allowGroupPortal: res.data.data.member == "1" });
        }
      });
    }
    this.setState({ groupId: groupId });
    getGroupAbout(this.props.token, groupId).then((res) => {
      res.data.OK
        ? this.setState({ data: res.data.OK, loading: false })
        : this.setState({ loading: false });
    });
  }
  getDocumentsView(data) {
    console.log(JSON.stringify(data));
    return (
      <div className="media-box  d-flex justify-content-between">
        <div>
          {" "}
          <i className="material-icons">attach_file</i>
        </div>
        <div>{data.name}</div>
        <div>
          {" "}
          <a onClick={() => this.onDownloadClick(data)}>
            <i className="material-icons material-icons-outlined ml-15 greenIcon">
              get_app
            </i>
          </a>
        </div>
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const data = this.state.data;
    let classNames = "view-group ";
    if (!this.state.isShownInModal) {
      classNames += "main-content ";
    }
    return (
      <>
        {/* <div> */}
        {/* <div className="back-cont">
            <BackButton />
          </div> */}

        {/* <div> */}
        {this.state.allowGroupPortal && (
          <>
            <div className="newPostBtnHolder">
              <IconButton
                icon="groups"
                text={
                  t("group_portal_nav_title_primary") +
                  " " +
                  t("group_portal_nav_title_secondary")
                }
                type="button"
                handleClick={() => this.setState({ showGroupPortal: true })}
              />
              {data.superAdmin === "1" && (
                <IconButton
                  icon="edit"
                  text={
                    t("edit_group_screen_primary") +
                    " " +
                    t("edit_group_screen_secondary")
                  }
                  type="button"
                  handleClick={() => this.setState({ editing: true })}
                />
              )}
            </div>
          </>
        )}

        <Container className={classNames} fluid>
          <Row>
            <Col lg={3}>
              <Card className="group-profileCard shadow  mb-5 bg-white">
                <div className="card-header"></div>
                <Card.Body>
                  <Image
                    src={
                      data.UCT_avatar ? data.UCT_avatar : "/images/profile.png"
                    }
                    
                    className="profile-image"
                  />
                  <div className="grpname pt-3">
                    <span>{data.CIT_plz}</span>
                    <span className="pl-1">{data.LGP_name}</span>
                  </div>
                  <div class="createdat">
                    <span> {t("view_group_since")}</span>
                    <span className="pl-1">{data.created}</span>
                  </div>
                  <div className="pt-3">
                    <Button className="newbtn">Join Group</Button>
                  </div>
                </Card.Body>
                <Card.Footer className="bg-white">
                  <Row>
                    <Col className="col-4 nopadding text-center rb">
                      <div className="data-label">Group Posts</div>
                      <div className="data-value">{data.totalGroupPost}</div>
                    </Col>
                    <Col className=" col-4 nopadding text-center rb">
                      <div className="data-label">Members</div>
                      <div className="data-value">{data.totalGroupMember}</div>
                    </Col>
                    <Col className=" col-4 nopadding text-center rb">
                      <div className="data-label">Shared Posts</div>
                      <div className="data-value">{data.totalSharedPost}</div>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
            <Col lg={9}>
              <Card className="group-aboutusCard">
                <Card.Title>{t("view_group_title")}</Card.Title>
                <Card.Text>
                  {data.LGP_about ? data.LGP_about : "n.a."}
                </Card.Text>
              </Card>
              <Card className="group-contactCard">
                <div className="contact-heading">
                  {" "}
                  {t("add_edit_group_rules_contact")}
                </div>
                <Card.Body>
                  <div className="contact-section">
                    <div className="d-sm-flex">
                      <div className="d-flex  col-6 nopadding">
                        <div className="data-title">
                          {" "}
                          {t("view_group_email")}:
                        </div>
                        <div>
                          <span className="data-value">{data.LGP_contact}</span>
                        </div>
                      </div>
                      <div className="d-flex col-6 nopadding">
                        <div className="data-title">
                          {" "}
                          {t("add_edit_group_website")}:
                        </div>
                        <div>
                          <span className="data-value">
                            {" "}
                            {data.LGP_web ? data.LGP_web : "n.a."}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="d-sm-flex pt-3">
                      <div className="d-flex  col-6 nopadding">
                        <div className="data-title">
                          {" "}
                          {t("edit_profile_telephone")}:
                        </div>
                        <div>
                          <span className="data-value">
                            {" "}
                            {data.LGP_phone ? data.LGP_phone : "n.a."}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex  col-6 nopadding">
                        <div className="data-title">Address:</div>
                        <div className="data-value">h124 new york</div>
                      </div>
                    </div>
                  </div>
                  <div className="media-section">
                    <Row className="pt-3">
                      <div className="col-lg-6 col-sm-12 ">
                        <div className="heading">
                          {" "}
                          {t("add_edit_group_media_and_documents_title")}
                        </div>
                        {data?.LGP_media && data.LGP_media.length > 0 && (
                          <div className="media-box-cont">
                            {data.LGP_media.map((item, i) =>
                              this.getDocumentsView(item)
                            )}
                          </div>
                        )}
                      </div>

                      <div className="col-lg-6 sm-12 ">
                        <div className="heading">
                          {" "}
                          {t("add_edit_group_group_rules_title")}
                        </div>

                        {data?.LGP_grouprules &&
                          data.LGP_grouprules.length > 0 && (
                            <div className="media-box-cont">
                              {data.LGP_grouprules.map((item, i) =>
                                this.getDocumentsView(item)
                              )}
                            </div>
                          )}
                      </div>
                    </Row>
                  </div>

                  <div className="setting-section mt-4">
                    <div className="heading">
                      {t("add_edit_group_settings_title")}
                    </div>
                    <div className="setting-cont">
                      <div className="ctitle">
                        {" "}
                        {data?.LGP_public === "y"
                          ? t("add_edit_group_type_public")
                          : t("add_edit_group_type_private")}
                      </div>
                      <div className="csubtitle">
                        {" "}
                        {t("add_edit_group_group_admins_title") + ":"}
                      </div>
                      <div className="row admin-data pt-3">
                        {data.LGP_admin?.map((item, i) => (
                          <div className="col-md-4">
                            <Image src={item.USE_pic} roundedCircle />
                            <span className="pl-20">{item.name}</span>
                          </div>
                        ))}
                      </div>

                      <div className="admin-per pt-3">
                        <div className="item">
                          <Checkbox
                            text={t(
                              "add_edit_group_checkbox_member_invite_text"
                            )}
                            value={data.LGP_invite === "y"}
                            handleChange={(e) => {}}
                          />
                        </div>
                        <div className="item">
                          <Checkbox
                            text={t(
                              "add_edit_group_checkbox_member_enter_memberlist_text"
                            )}
                            value={data.LGP_viewmember === "y"}
                            handleChange={(e) => {}}
                          />
                        </div>
                        <div className="item">
                          <Checkbox
                            text={t(
                              "add_edit_group_checkbox_member_create_posts_text"
                            )}
                            value={data.LGP_post === "y"}
                            handleChange={(e) => {}}
                          />
                        </div>
                        <div className="item">
                          <Checkbox
                            text={t(
                              "add_edit_group_checkbox_member_enter_confirmation_text"
                            )}
                            value={data.LGP_confirm === "y"}
                            handleChange={(e) => {}}
                          />
                        </div>

                        <div className="item">
                          <Checkbox
                            text={t(
                              "add_edit_group_checkbox_member_can_share_grp_post"
                            )}
                            value={data.LGP_shareable === "y"}
                            handleChange={(e) => {}}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {this.state.editing && (
            <EditGroup
              token={this.props.token}
              editing={this.state.editing}
              editedGroupId={this.state.groupId}
              history={this.props.history}
              stopEdit={() =>
                this.setState({
                  editing: false,
                  editedGroupId: null,
                })
              }
            />
          )}
        </Container>

        {this.state.showGroupPortal && (
          <GroupPortal
            showGroupPortal={this.state.showGroupPortal}
            handleClose={() => this.setState({ showGroupPortal: false })}
            groupId={this.state.groupId}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(withRouter(ViewGroupNew));
