import React from "react";
import "./UserCard.scss";
import { dotDateFormatter } from "lib/utils/Commons";

function NotificationData({item}) {

  return (
    <div className="notification-data"> 
      <div className="title">
      {item.title ? item.title  : ""}
      </div>
      <div className="message">
      {item.mess ? item.mess  : ""}
      </div>
      <div className="date">
      {item.date ? dotDateFormatter(item.date): ""}
      

      </div>
       
    </div>
  );
}
export default NotificationData;
