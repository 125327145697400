import React, { Component } from "react";
import "rc-slider/assets/index.css";
import UserCard from "../elements/cards/UserCard";
import { connect } from "react-redux";
import { mapStateToProps } from "lib/store/mapStateToProps";
import { withTranslation } from "react-i18next";
import Loader from "../../components/posts/single-post/PostLoader/postLoader.js";
import UserDetails from "../user-details";
import { getMyCitizens } from "../../api/user";
import moment from "moment";


class MyCitizensList extends Component {
  state = {
    newChat: false,
    list: [],
    query: "",
    isLoading: true,

    showUserDetails: false,
    userId: null,
    filteredList: [],
  };

  
  componentDidMount() {
    this.loadData();
  }

  

  loadData() {

    getMyCitizens(this.props.token).then((res) => {
      if (res.data.citizen) {
        const data = res.data.citizen;
        let modifiedData;
        let userDetails = JSON.parse(localStorage.getItem("profile"));
        if (userDetails) {
          // console.log(userDetails.userId);
          modifiedData = data.filter(
            (me) => me.FK_userID !== userDetails.userId
          );
        }
        this.setState({
          list: modifiedData,
          filteredList: modifiedData,
          totalUsers: modifiedData.length,
            isLoading: false,
        });
      }
    });
  }

  componentDidUpdate() {
    if (this.state.query != this.props.query) {
      this.setState({ query: this.props.query });

      this.searchData();

    }
  }

  clickOnCard = (userId) => {
    this.setState({
      userId: userId,
      showUserDetails: true,
    });
  };

  searchData() {
    const result = this.state.list.filter((me) => {
      return me.USE_firstname.concat(me.USE_surename)
        .toLowerCase()
        .includes(this.props.query.toLowerCase());
    });
    // console.log({ result });
    this.setState({ filteredList: result });
  }

  render() {
    const { t } = this.props;
    return (
      <>
       
        <div className="inbox main-content minHeight">
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <div className="main-content-narrow">
            {this.state.filteredList &&
              this.state.filteredList?.length>0 ? this.state.filteredList.map((x, i) => (
                <UserCard
                  item={x}
                  handleClick={() => this.clickOnCard(x.FK_referer_userID)}
                  className="cursor"
                >
                  <span className="joinedOn">
                    {`${t("joined_locci_on")} ${moment(x.URR_date).format('DD/MM/YYYY HH:mm')}`}
                  </span>
                </UserCard>
              )) :<div class="text-center">{t("no_citizens")}</div>

            }

          


          </div>
              )}
        </div>
    

        {this.state.showUserDetails && (
          <UserDetails
            id={this.state.userId}
            {...this.props}
            handleClose={() => this.setState({ showUserDetails: false })}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(connect(mapStateToProps)(MyCitizensList));
