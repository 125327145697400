import React, { useMemo } from 'react';
import { useLocation, useNavigate, useParams, useMatch } from 'react-router-dom';

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams(); 
    let match = useMatch("/group/:id")  

    if(match){
      match=true
    }else{
      match=false
    } 

    const history = useMemo(() => ({
      push: (route) => { 
        navigate(route);
      },
      replace: (route) => {
        navigate(route, { replace: true });
      }, 
      go:(route) =>{  
        window.location.reload()
      },
      goBack: () => {
        navigate(-1); // This will take you one step back in the history stack
      },
      location
    }), [navigate]);

    return (
      <Component
        {...props}
        location={location}
        params={params}
        navigate={navigate}
        history={history} 
        matchPath={match}
        match={{params}}
      />
    );
  }

  return ComponentWithRouterProp;
}
