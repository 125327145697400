import { useParams } from "react-router-dom";
import Feed from "../feed";

type Props = {
  token: string;
};

export default function UserPosts({ token }: Props) {
  const params = useParams<{ id: string }>();

  if (!params?.id) return null;

  return <Feed token={token} userId={params.id} />;
}
