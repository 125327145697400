
import React, { useReducer } from "react";
import { setGCMToken } from "../../api/common";

let user = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : "";
let token = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user")).TMBP_ACC_TOKEN
  : "";
let profile = localStorage.getItem("profile")
  ? JSON.parse(localStorage.getItem("profile"))
  : "";

export const initialState = {
  userDetails: "" || user,
  profile: "" || profile,
  token: "" || token,
  loading: false,
  errorMessage: null
};

async function removeGCMToken(initialState,token) {
  try {
    const response = await setGCMToken(token,"")
    if (response.data.OK)
      console.log('success');
      return {
        ...initialState,
        user: "",
        token: "",
        profile:""
      };
  }
  catch (e) {
    console.log(e)
  }

}

export const AuthReducer = (initialState, action) => {
  console.log(initialState);
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...initialState,
        loading: true
      };
    case "LOGIN_SUCCESS":
      return {
        ...initialState,
        user: action.payload.data,
        token: action.payload.TMBP_ACC_TOKEN,
        profile: action.profile,
        loading: false
      };
    case "LOGOUT":
    return  removeGCMToken( initialState,initialState.token)
    

    case "LOGIN_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error
      };

      case  "UPDATE_PROFILE" : 
       console.log("calling context",action.profile);
       return {
        ...initialState,
        profile:action.profile,
      }

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};