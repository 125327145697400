import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import IconButton from "../../elements/buttons/IconButton";
// import ChatUserScreen from "../../inbox/index";
import AudioRecorder from "audio-recorder-polyfill";
import mpegEncoder from "audio-recorder-polyfill/mpeg-encoder";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import Button from "../../elements/buttons/Button";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { ngxBase64 } from "lib/utils/Commons";
import gif from "../../../assets/16581-audio.gif";
import gifPause from "../../../assets/audiogif.png";

AudioRecorder.encoder = mpegEncoder;
AudioRecorder.prototype.mimeType = "audio/mpeg";
window.MediaRecorder = AudioRecorder;
let recorder = null;
let recordingChuks = [];
let startTime = 0;
let totalTime = 0;
let gumStream = null;

const AudioRecording = (props) => {
  // const [recorder, setRecorder] = useState(null);
  const { t, i18n } = useTranslation();

  const [audioSrc, setAudioSrc] = useState();
  const [recordStart, setRecordStart] = useState(false);
  const [audioBase64, setAudioBase64] = useState("");
  const [play, setPlay] = useState(false);
  const [recordTimer, setRecordTimer] = useState(0);
  const audioTag = useRef(null);
  const progressTag = useRef(null);

  const record = () => {
    console.log("mmmm",[play,recordStart]);
    if (recordStart) {
      setPlay(false);
      console.log("called stop recording");
      stopRecording();
    } else {
      recordingChuks = [];
      setAudioBase64("");
      console.log("called start recording");
      startRecording();
    }
    setRecordStart(!recordStart);
  };
  const playAudio = () => {
    if (play) {
      audioTag.current.pause();
      audioTag.current.removeEventListener("timeupdate", (e) => {
        updateProgressBar(e);
      });
      audioTag.current.removeEventListener("ended", (e) => {
        endedAudio(e);
      });
    } else {
      audioTag.current.play();
      audioTag.current.addEventListener("timeupdate", (e) => {
        updateProgressBar(e);
      });
      audioTag.current.addEventListener("ended", (e) => {
        endedAudio(e);
      });
    }
    setPlay(!play);
  };

  const updateProgressBar = (e) => {
    if (progressTag && progressTag.current) {
      progressTag.current.style.width =
        (e.target.currentTime / e.target.duration) * 100 + "%";
    }
  };

  const endedAudio = (e) => {
    if (progressTag && progressTag.current) {
      progressTag.current.style.width = "100%";
    }
    setPlay(false);
  };

  const startRecording = () => {
    setPlay(false);
    setAudioSrc("");
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      console.log("sjdflksdjf", stream);
      gumStream = stream;
      recorder = new MediaRecorder(stream);
      console.log("recorder", recorder);
      if (recorder) {
        recorder.addEventListener("dataavailable", async (e) => {
          console.log("dataavailable", e.data);
          const base64 = await ngxBase64(e.data);
          setAudioBase64(base64);
          setAudioSrc(window.URL.createObjectURL(e.data));
        });
        recorder.start();
        startTime = new Date().getTime();
      }
    });
  };
  const stopRecording = async () => {
    // Stop recording
    recorder.stop();
    totalTime = new Date().getTime() - startTime;

    console.log('"ET', new Date().getTime(), "TT", totalTime);

    // Remove “recording” icon from browser tab
    if (recorder.stream) {
      recorder.stream.getTracks().forEach((i) => i.stop());
    } else if (gumStream) {
      gumStream.getTracks().forEach((i) => i.stop());
    }
    let blob = new Blob(recordingChuks, { type: "audio/ogg; codecs=opus" });

    // recorder.requestData();
  };

  useEffect(() => {
    // if(!window.MediaRecorder){
    // alert(t('mediarecorder_error_title'))
    // }
  }, []);

  const recordingAvail = () => {};

  const send = () => {
    if (audioBase64) {
      let sendableBase64 = audioBase64.split(",")[1];
      let payload = [];

      if (recorder && recorder.state == "recording") {
        recorder.stop();
      }

      if (recorder.stream) {
        recorder.stream.getTracks().forEach((i) => i.stop());
      } else if (gumStream) {
        gumStream.getTracks().forEach((i) => i.stop());
      }

      function urltoFile(url, filename, mimeType) {
        return fetch(url)
          .then(function (res) {
            return res.arrayBuffer();
          })
          .then(function (buf) {
            return new File([buf], filename, { type: mimeType });
          });
      }

      //Usage example:
      urltoFile(audioBase64, "audio.mp3", "audio/mp3").then(function (file) {
        let myFile = { file: file, type: "a", audioTime: totalTime };

        payload.push(myFile);
        props.onSendClick(payload);
        startTime = 0;
        totalTime = 0;
      });
    }
  };

  const handleCloseModel = () => {
    if (play && audioTag.current) {
      audioTag.current.pause();
      audioTag.current.removeEventListener("timeupdate", (e) => {
        updateProgressBar(e);
      });
      audioTag.current.removeEventListener("ended", (e) => {
        endedAudio(e);
      });
    }
    if (recorder) {
      if(recorder.state == "recording"){
        recorder.stop();
      }
      if (recorder.stream) {
        recorder.stream.getTracks().forEach((i) => i.stop());
      } else if (gumStream) {
        gumStream.getTracks().forEach((i) => i.stop());
      }
      props.handleClose();
    } else {
      props.handleClose();
    }
  };

  const displayTimer = (d) => {
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);
    const mDisplay = m > 0 ? (m < 10 ? "0" : "") + m + ":" : "00:";
    const sDisplay = s > 0 ? (s < 10 ? "0" : "") + s : "00";
    return mDisplay + "" + sDisplay;
  };

  return (
    <Modal centered show onHide={handleCloseModel} className="custom-modal">
      <IconButton
        clear="true"
        className="closeIcon"
        icon="close"
        handleClick={handleCloseModel}
      />

      <>
        {!window.MediaRecorder ? (
          <div>{t("mediarecorder_error_title")}</div>
        ) : (
          <div>
            <h4>{t("record_audio_title")}</h4>

            <div className="d-flex justify-content-center mt-3">
              <div
                className={classNames("record-timer gif-img", {
                  pulses: recordStart,
                })}
              >
                <img src={recordStart ? gif : gifPause} />
                {/* {displayTimer(recordTimer)} */}
              </div>
            </div>

            <div className="d-flex justify-content-around mt-3">
              <Button
                clear="true"
                text={t("cancel_alert")}
                handleClick={handleCloseModel}
              />

              {/* </div> */}

              <div>
                <IconButton
                  clear="true"
                  className="pause-btn text-danger"
                  icon={recordStart ? "stop" : "radio_button_checked"}
                  handleClick={record}
                />
              </div>
              <div>
                <IconButton
                  clear="true"
                  className="play-btn text-success"
                  icon={play ? "pause_circle_outline" : "play_circle_outline"}
                  handleClick={playAudio}
                  disabled={audioSrc ? false : true}
                />
              </div>
              <Button clear="true" text={t("send")} handleClick={send} />
            </div>
            <audio
              ref={audioTag}
              controls={true}
              className="record-audio-tag"
              src={audioSrc}
            ></audio>
          </div>
        )}
      </>
    </Modal>
  );
};

export default AudioRecording;
