import React, { Component, useState } from "react";
// import "./UserDetails.scss";
import { withTranslation } from "react-i18next";
import { Form, Modal } from "react-bootstrap";
import { blockUser, getUserProfile } from "../../api/user";
import IconButton from "../elements/buttons/IconButton";
import IconLink from "../elements/buttons/IconLink";

import moment from "moment";
import { favoritePost } from "../../api/posts";
import HtmlParser from "html-react-parser";

class UserDetails extends Component {
  state = {
    message: "",
    data: {},
  };

  componentDidMount() {
    if (this.props.id) {
      getUserProfile(this.props.token, this.props.id).then((res) => {
        if (res.data.OK) {
          this.setState({ data: res.data.OK });
        }
      });
    }
  }

  favoritePostAction() {
    favoritePost(
      this.props.token,
      this.props.postId,
      !this.state.data.Fav
    ).then((res) => {
      console.log(res);
      if (res.data.OK) {
        getUserProfile(this.props.token, this.props.id).then((res) => {
          if (res.data.OK) {
            this.setState({ data: res.data.OK });
          }
        });
      }
    });
  }

  blockUserAction() {
    blockUser(
      this.props.token,
      this.state.data.userID,
      !this.state.data.Blocked
    ).then((res) => {
      if (res.data.OK) {
        getUserProfile(this.props.token, this.props.id).then((res) => {
          if (res.data.OK) {
            this.setState({ data: res.data.OK });
          }
        });
      }
    });
  }
  render() {
    const data = this.state.data;
    const { t } = this.props;
    return (
      <>
        <Modal centered show onHide={this.props.handleClose}>
          <Modal.Body className="user-details">
            <IconButton
              clear="true"
              className="icon-mr-0 close-modal"
              icon="close"
              handleClick={this.props.handleClose}
            />

            <div className="user-details-content">
              <div className="user-details-header">
                <img
                  src={data.USE_pic ? data.USE_pic : "/images/profile.png"}
                />
                <div className="header">
                  <span className="name">
                    {data.USE_firstname} {data.USE_surename}
                  </span>
                  <span className="plz">{data.USE_plz}</span>
                  <span className="member">
                    {t("view_profile_since")} {data.USE_created}
                  </span>
                </div>
              </div>

              {data.myself == "n" && (
                <div className="actions">
                  <IconLink
                    icon="mail"
                    text={t("view_profile_message")}
                    link={"/chat/" + data.userID}
                    outlined={true}
                  />

                  <IconButton
                    icon="favorite"
                    clear={true}
                    text={
                      data.Fav
                        ? t("view_profile_unfollow")
                        : t("view_profile_follow")
                    }
                    outlined={true}
                    handleClick={() => this.favoritePostAction()}
                  />
                  <IconButton
                    icon="person_off"
                    clear={true}
                    text={
                      data.Blocked
                        ? t("view_profile_unblock")
                        : t("view_profile_block")
                    }
                    outlined={true}
                    handleClick={() => this.blockUserAction()}
                  />
                  <IconLink
                    icon="present_to_all"
                    text={t("view_profile_public_posts")}
                    link={`/user-posts/` + data.userID}
                    outlined={true}
                  />
                </div>
              )}
              <div className="border-bottom"> </div>

              <h2 className="mt-20">{t("view_profile_about_title")}</h2>

              <div className="about-me">
                {this.state.data?.myself === "y" && (
                  <>
                    <div className="info">
                      <span className="">{t("view_profile_sex")}</span>
                      <span className="">{data.USE_sex}</span>
                    </div>

                    <div className="info">
                      <span className="">{t("view_profile_age")}</span>
                      <span className="">
                        {moment(data.USE_birth).isValid()
                          ? moment().diff(data.USE_birth, "years")
                          : "/"}
                      </span>
                    </div>

                    <div className="info">
                      <span className="">{t("view_profile_email")}</span>
                      <span className="">{data.USE_email}</span>
                    </div>

                    <div className="info">
                      <span className="">{t("view_profile_telephone")}</span>
                      <span className="">
                        {data.USE_mobile ? data.USE_mobile : "/"}
                      </span>
                    </div>
                  </>
                )}

                <div className="info">
                  <span className="">{t("view_profile_description")}</span>
                  <span className="">
                    {data.LCU_mess ? HtmlParser(data.LCU_mess) : "/"}
                  </span>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(UserDetails);
