import { useTranslation } from "react-i18next";
import styled from "styled-components";

type InfoCardProps = {
    data?: any;
}

const InfoCard = ({data} : InfoCardProps) => {
    const { t } = useTranslation();

    const groupDataStats = [
        { id: 1, label: t("group_members_screen_title_primary"), count: data?.totalGroupMember },
        { id: 2, label: t("posts"), count: data?.totalGroupPost },
    ];

    return (
        <>
            <AvatarWrapper>
                <img className="profile-avatar cursor"
                    loading='lazy'
                    src={data?.UCT_avatar ? data?.UCT_avatar : "/images/profile.png"}
                    alt={data?.LGP_name}
                />
            </AvatarWrapper>
            
            <Title title={data?.LGP_name}>{data?.LGP_name}</Title>
            
            <Badge>
                <i className="material-icons">groups</i>
                {data?.LGP_public === "n" ? <span>{t("add_edit_group_type_private")}</span> : <span>{t("add_edit_group_type_public")}</span> }
            </Badge>

            {groupDataStats.map( (item) => (
                <Statistics key={item.id}>
                    <span>{item.count}</span> {item.label}
                </Statistics>
            ) )}

            
        </>
    );
};

const AvatarWrapper = styled.div`
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
    display: flex;
    width: 64px;
    height: 64px;
    overflow: hidden;
    z-index: 1;

    .profile-avatar {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
    }
`;

const Title = styled.h2`
  color: #000;
  margin-top: 24px;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 1.125rem;
  text-align: center;
`;

const Statistics = styled.div`
    text-align:center;
    font-size: 0.9rem;
    margin-top: 4px;

    span {
        font-weight: 700;
    }
`;

const Badge = styled.div`
    display:flex;
    gap: 10px;
    background-color: rgb(238, 238, 238);
    align-item: center;
    padding: 4px 8px;
    font-size: 0.9rem;
    margin-bottom: 5px;
    border-radius: 4px;

    i {
        font-size: 20px;
    }
`;

export default InfoCard;