import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Button from "../elements/buttons/Button";



const DeleteModal=(props)=>{
  const { t } = useTranslation();

    return (
        <Modal
        centered
        show={props.show}
        onHide={() => props.closeModal()}
        contentClassName="delete-confirmation-modal"
      >
        <Modal.Body>
          <h2>{t(props?.title)}</h2>
          <p>{props?.subContent===undefined? t(props?.content):props?.content}</p>
          {props?.subContent && <p>{t(props?.subContent)}</p>}

          <div className="report-actions">
            <Button
              clear="true"
              text={t("close")}
              handleClick={() => 
                props.closeModal()
              }
            />
            <Button
              clear="true"

              text={t("delete")}
              handleClick={()=> props.onSubmit()}
            />
          </div>
        </Modal.Body>
      </Modal>
    )
}

export default DeleteModal