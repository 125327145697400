import noImage from "assets/placeholder.jpg";
import { useState } from "react";

type Props = {
  placeholder?: React.ReactNode | string;
} & React.ImgHTMLAttributes<HTMLImageElement>;

export const Image = ({ src, placeholder, ...props }: Props) => {
  const [hasLoadError, setHasLoadError] = useState(false);

  const handleLoadError = () => {
    setHasLoadError(true);
  };

  if (hasLoadError || !src) {
    if (typeof placeholder === "string") {
      return <img src={placeholder} {...props} />;
    }
    return <>{placeholder || <img src={noImage} {...props} />}</>;
  }

  return <img src={src} {...props} onError={handleLoadError} />;
};
