import React from "react";
import {
    Form,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "lib/store";
import * as GlobalStore from "lib/store/global";
import { SearchInputWrapper } from "./style";

const SearchInput = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <>
        <SearchInputWrapper>
            <Form.Control
                type="text"
                autoFocus
                // value={query}
                placeholder={t("enter_search_text") + "..."}
                onChange={(e) => dispatch(GlobalStore.search(e.target.value))}
            />

        </SearchInputWrapper>
    </>
  );
};

export default SearchInput;