import React, { Component } from "react";
import "./Button.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import { Tooltip, tooltipClasses } from "@mui/material";

const DarkTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    borderRadius: '13px',
    fontSize: '14px',
    fontWeight: '500',
    padding: '6px 19px',
  },
}));

function IconButton(props) {
  const { t, i18n } = useTranslation();
  return (
    <>
      {props.tooltipLabel ? 
        <DarkTooltip title={t(props.tooltipLabel)} placement={props.tooltipPosition}>
          <button
            name={props.name}
            className={classNames("btn", "btn-icon", props.className, {
              "icon-btn-clear": props.clear,
            })}
            style={{ ...(props.styles || {}), justifyContent: props.icon === 'groups' && 'center' }}
            type={props.type ? props.type : "button"}
            onClick={props?.handleClick}
            disabled={props.disabled}
          >
            {props.icon === 'groups' ?
              <i
                className={classNames("material-icons ", {
                  "material-icons-outlined": props.outlined,
                })}

              >
                {props.icon}
              </i> :
              <>
                {!props.iconRight && (
                  <span className="iconleftspan">
                    <i
                      className={classNames("material-icons ", {
                        "material-icons-outlined": props.outlined,
                      })}

                    >
                      {props.icon}
                    </i>
                  </span>
                )}
                {props.text ? (
                  props.translateText ? (
                    <span className={props.iconRight ? "iconrightspan" : "iconleftspan"}>
                      {t(props.text)}
                    </span>
                  ) : (
                    <span className={props.iconRight ? "iconrightspan" : "iconleftspan"}>
                      {props.text}
                    </span>
                  )
                ) : null}

                {props.iconRight && (
                  <span className="iconrightspan">
                    <i
                      className={classNames("material-icons ", {
                        "material-icons-outlined": props.outlined,
                      })}
                      onClick={props?.iconClick}
                    >
                      {props.icon}
                    </i>
                  </span>
                )}
                {props.image && (
                  <span className={props.fitContain ? null : "pl-2"}>
                    <img src={props.image} style={{ width: '30px', height: '30px', objectFit: props.fitContain ? 'contain' : null }} />
                  </span>
                )}
              </>
            }

          </button>
        </DarkTooltip> :
          <button
          name={props.name}
          className={classNames("btn", "btn-icon", props.className, {
            "icon-btn-clear": props.clear,
          })}
          style={{ ...(props.styles || {}), justifyContent: props.icon === 'groups' && 'center' }}
          type={props.type ? props.type : "button"}
          onClick={props?.handleClick}
          disabled={props.disabled}
        >
          {props.icon === 'groups' ?
            <i
              className={classNames("material-icons ", {
                "material-icons-outlined": props.outlined,
              })}

            >
              {props.icon}
            </i> :
            <>
              {!props.iconRight && (
                <span className="iconleftspan">
                  <i
                    className={classNames("material-icons ", {
                      "material-icons-outlined": props.outlined,
                    })}

                  >
                    {props.icon}
                  </i>
                </span>
              )}
              {props.text ? (
                props.translateText ? (
                  <span className={props.iconRight ? "iconrightspan" : "iconleftspan"}>
                    {t(props.text)}
                  </span>
                ) : (
                  <span className={props.iconRight ? "iconrightspan" : "iconleftspan"}>
                    {props.text}
                  </span>
                )
              ) : null}

              {props.iconRight && (
                <span className="iconrightspan">
                  <i
                    className={classNames("material-icons ", {
                      "material-icons-outlined": props.outlined,
                    })}
                    onClick={props?.iconClick}
                  >
                    {props.icon}
                  </i>
                </span>
              )}
              {props.image && (
                <span className={props.fitContain ? null : "pl-2"}>
                  <img src={props.image} style={{ width: '30px', height: '30px', objectFit: props.fitContain ? 'contain' : null }} />
                </span>
              )}
            </>
          }

        </button>
      
      }
    </>

  );
}
export default IconButton;
