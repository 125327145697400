import React, { Component } from "react";
import IconLink from "../buttons/IconLink";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

function SidebarLink({ data, active, handleClick,outline }) {

  const { t } = useTranslation();
  return (
    <IconLink
      link={data.path}
      text={t(data.name)}
      icon={data.icon}
      outlined ={outline}
      className={classNames(data.class, {"link-active" : active})}
      handleClick={handleClick}
    />
  );
}
export default SidebarLink;
