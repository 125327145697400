import { Link } from 'react-router-dom';
import './Button.scss';

function IconLink(props) {
  if (props.disabled) {
    return (
      <Link name={props.name} className={"link icon-link link-disabled " + (props.className ? props.className : "")} onClick={() => { }}>
        {
          props.icon && <i className={"material-icons link-disabled " + (props.outlined ? "material-icons-outlined" : "")}>{props.icon}</i>
        }
        <span >{props.text}</span>
      </Link>
    )
  }
  else { 
    const linkProps = {
      pathname: props.link,
      search : `${props.query}`
    };

    return (
      <Link name={props.name} to = {props.query?linkProps:props.link}   className={"link icon-link " + (props.className ? props.className : "")} onClick={props.handleClick}>
        {
          props.icon && <i className={"material-icons " + (props.outlined ? "material-icons-outlined" : "")}>{props.icon}</i>
        }
        <span  className={props.textClassName ? props.textClassName : ""}>{props.text}</span>
      </Link>
    );
  }
}
export default IconLink;