import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import IconButton from "../elements/buttons/IconButton";
export default function BackButton(props) {
  const history = useNavigate();
  const { t, i18n } = useTranslation();
  return (
    <IconButton
      icon="arrow_back"
      text={t('nav_back')}
      clear={true}
      className="pb-1 back"
      handleClick={() => history(-1)}
    />
  );
}
