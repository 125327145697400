import React, { useEffect, useState, useRef } from "react";
import IconButton from "../../elements/buttons/IconButton";
import { Modal } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from "react-i18next";
import "./manageParticipants.scss";
import Button from "../../elements/buttons/Button";
import { getGroupAdmins } from "../../../api/common";
import GlobalConfirmDialogue from "../../commons/GlobalConfirmDialogue";


const AddParticipantsModal = (props) => {
  const { t, i18n } = useTranslation();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true)
  const [scroolLoading, setScrollLoading] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [allParticipants, setAllParticipants] = useState([]);
  const page = useRef(1); // Use useRef to keep track of the current page
  const myRef = useRef(null)
  const searchRef = useRef(null)

  console.log(props.participantData, "xxx");

  useEffect(() => {
    searchRef.current = searchText
    getInboxList()
    const divElement = myRef.current;

    if (divElement) {
      myRef.current.scrollTop = 0
    }
  }, [searchText])

  const onUserClick = (x) => {
    if (selectedUsers?.length === 0) setSelectedUsers([...selectedUsers, x]);
    else {
      if (!selectedUsers?.find((me) => me.FK_userID === x.FK_userID)) {
        setSelectedUsers([...selectedUsers, x]);
      } else {
        let result = selectedUsers?.filter(
          (me) => me.FK_userID !== x.FK_userID
        );
        setSelectedUsers(result);
      }
    }
  };

  const onRemoveIcon = (id) => {
    let result = selectedUsers.filter((me) => me.FK_userID !== id);
    setSelectedUsers([...result]);
  };

  const searchByName = (value) => {
    let data = props?.userList?.filter((dt) => {
      if (
        (
          dt?.USE_firstname?.toLowerCase() +
          " " +
          dt?.USE_surename?.toLowerCase()
        )?.includes(value?.toLowerCase())
      ) {
        return dt;
      }
    });
    setAllParticipants(data);
  };

  const getInboxList = () => {
    getGroupAdmins(props.token, searchRef.current, 20, page.current)
      .then((res) => {
        if (res.data.row) {
          const data = res.data.row;
          const userDetails = JSON.parse(localStorage.getItem("profile")) || {};
          const filteredData = data.filter((me) => me.FK_userID !== userDetails.userId);
  
          const arr = props.participantData.map((dt) => dt?.userID);
          const modiArr = filteredData.filter((dt) => !arr.includes(dt?.FK_userID));
  
          setAllParticipants((prev) => (page.current !== 1 ? [...prev, ...modiArr] : modiArr));
        }
      })
      .finally(() => {
        setLoading(false);
        setScrollLoading(false);
      });
  };
  

  const handleAddParticipants = () => {
    setShowModal(false);
    props?.addParticipants(selectedUsers);
  };

  const handleScroll = async () => {
    // Check if the user has scrolled to the bottom of the page 
    try {

      if (
        myRef.current.clientHeight + myRef.current.scrollTop + 1 >
        myRef.current.scrollHeight
      ) {
        page.current += 1; // Increment the page for the next API call
        setScrollLoading(true)
        getInboxList(); // Call fetchData when the user reaches the bottom
      }
    } catch (error) {
      console.log(error, "ids");
    }

  };

  useEffect(() => {
    // Attach the scroll event listener when the component mounts
    // console.log(window.innerHeight, document.documentElement.scrollTop, document.documentElement.scrollHeight, "ids");

    const divElement = myRef.current;

    if (divElement) {
      console.log("ids");
      divElement.addEventListener('scroll', handleScroll);
      console.log(myRef, "ids");
    }

    // Remove the event listener when the component unmounts
    return () => {
      if (divElement) {
        divElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [myRef.current]);

  //  const filterParticipants = (data) => {
  //     let arr = [];
  //     data?.map((dt) => {
  //       arr.push(dt?.userID);
  //     });

  //     let modiArr = [];

  //     const mapUsers = () =>{
  //       this.state.allUsersSecond?.map((dt) => {
  //         if (!arr.includes(dt?.FK_userID)) {
  //           modiArr.push(dt);
  //         }
  //       });
  //     }

  //     if(this.state.allUsersSecond.length>0){
  //       mapUsers();
  //     } else  {
  //       let interval=setInterval(()=>{
  //         if(this.state.allUsersSecond.length>0) {
  //           clearInterval(interval);
  //           mapUsers();
  //         }
  //       },500);
  //     }
  //     this.setState({ allUsers: modiArr });
  //   };

  return (
    <>
      <Modal
        centered
        show={props.show}
        onHide={() => props.closeModal()}
        backdrop="static"
        contentClassName="participants-list-popup"
      >
        <Modal.Body>
          <IconButton
            clear="true"
            className="icon-mr-4 close-modal"
            icon="close"
            handleClick={() => props.closeModal()}
          />

          <div className="addParticipants">
            <div className="participantsheading">{t("add-participants")}</div>

            <div className="search">
              <input
                className="form-control"
                type="text"
                placeholder={t("enter_search_text")}
                aria-label="Search"
                //  value={searchText}
                autoFocus
                onChange={(e) => {
                  setSearchText(e.target.value);
                  page.current = 1;
                }}
              // onKeyUp={() => this.searchData()}
              />
            </div>

            <div className="added-usersBox">
              {selectedUsers?.length < 1 ? (
                <h5 className="addParty">{t("add-participants")}</h5>
              ) : (
                <>
                  <h5 className="">
                    {selectedUsers?.length + " " + (selectedUsers?.length > 1 ? t("participants-added") : t("participant-added"))}
                  </h5>
                  <div className="added-users">

                    {selectedUsers.map((me) => (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div className="img-cont">
                          <img src={me?.USE_pic ?? "/images/profile.png"} />

                          <i
                            className="material-icons material-icons"
                            onClick={() => onRemoveIcon(me.FK_userID)}
                          >
                            {"disabled_by_default"}
                          </i>
                        </div>
                        <span className="imageText">{me?.USE_firstname}</span>
                      </div>
                    ))}
                  </div>

                </>
              )}
            </div>
            {loading && (
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '20px', bottom: '0px  ' }}
              ><Spinner /></div>)}
            <div className="addParticipantsList" ref={myRef} >

              {allParticipants?.map((item) => (
                <div
                  className="participantsBox"
                  onClick={() => onUserClick(item)}
                >
                  <img src={item?.USE_pic ?? "/images/profile.png"} />
                  <span className="participantName">
                    {" "}
                    {item?.USE_firstname} {item?.USE_surename}
                  </span>
                  {selectedUsers?.some(
                    (me) => me?.FK_userID === item?.FK_userID
                  ) && (
                      <i className="material-icons material-icons">
                        {"check_circle"}
                      </i>
                    )}
                </div>
              ))}
            </div>

            <div className="btn-cont" style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '8px', marginBottom: 0, minHeight: '60px', alignItems: 'center' }}>
              {scroolLoading ?
                <Spinner /> :
                <Button
                  disabled={selectedUsers?.length === 0}
                  className="confirm-btn"
                  text={t("confirm")}
                  handleClick={() => setShowModal(true)}
                />
              }
            </div>
          </div>
          {showModal && (
            <GlobalConfirmDialogue
              show={showModal}
              title={"add-participants"}
              content={"add_participants_group_chat_mesaage"}
              name={props.name}
              closeModal={() => setShowModal(false)}
              onSubmit={() => handleAddParticipants(false)}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddParticipantsModal;
