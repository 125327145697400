import React, { Component } from "react";
import { getGroupAdmins } from "../../../api/common";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./DynamicSearch.scss";
import PerfectScrollbar from "react-perfect-scrollbar";
import Checkbox from "../checkbox";
import { getGroupMembers, getMyGroups } from "../../../api/groups";

//it isnt dynamic yet, list of group admins api
class DynamicSearch extends Component {
  state = {
    all: [],
    search: "",
    type: null,
    searchQuery: "",
    filteredAll: [],
  };

  componentDidMount() {
    let type = this.props.type;
    this.setState({ type: type });
    if (type === "admins") {
      getGroupMembers(this.props.token, this.props.groupId).then((res) => {
        if (res.data.OK) {
          this.setState({
            all: res.data.members,
            filteredAll: res.data.members,
          });
        }
      });
    } else if (type === "groups") {
      getMyGroups(this.props.token).then((res) => {
        if (res.data.OK) {
          var grps = res.data.grp.filter((x) => x.id !== this.props.groupId && x.allow_post==="1");
          this.setState({ all: grps, filteredAll: grps });
        }
      });
    }
  }

  searchData() {
    if (this.state.searchQuery === "" || this.state.searchQuery.length < 1) {
      this.setState({ filteredAll: this.state.all });
    } else {
      const result = this.state.all.filter((me) => {
        return me.USE_firstname.concat(me.USE_surename)
          .toLowerCase()
          .includes(this.state.searchQuery.toLowerCase());
      });
      this.setState({ filteredAll: result });
    }
  }

  render() {
    return (
      <div className="dynamic-search">
        {this.state.type === "admins" && (
          <div className="col-12">
            <input
              className="form-control bg-light"
              type="text"
              placeholder="Search"
              aria-label="Search"
              value={this.state.searchQuery}
              onChange={(e) => this.setState({ searchQuery: e.target.value })}
              onKeyUp={() => this.searchData()}
            />
          </div>
        )}
        <ul>
          <PerfectScrollbar>
            {this?.state?.filteredAll?.map((x, i) => (
              <li key={i}>
                {this.state.type === "admins" && (
                  <Checkbox
                    value={this.props.list.includes(x.FK_userID)}
                    handleChange={() => this.props.handleList(x.FK_userID)}
                    text={x.USE_firstname + " " + x.USE_surename}
                  />
                )}
                {this.state.type === "groups" && this.props.list && (
                  <Checkbox             
                    value={this.props.list.includes(x.id)}
                    handleChange={() => this.props.handleList(x.id)}
                    text={x.name}
                  />
                )}
              </li>
            ))}
          </PerfectScrollbar>
        </ul>
      </div>
    );
  }
}

export default DynamicSearch;
