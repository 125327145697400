import React, { useEffect, useState } from "react";
import "./Bubble.scss";
import { Dropdown, Modal, Spinner, Toast, ToastBody } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SharePostDialog from "../../posts/sharePostDialog/sharePostDialog";
import IconButton from "../buttons/IconButton";
import customVideoThumb from "../../../assets/video_player_icon.png";
import linkifyHtml from "linkify-html";
import ReactHtmlParser from "html-react-parser";
import { getAttchamentIcon } from "lib/utils/Commons";
import moment from "moment";
import Button from "../buttons/Button";

// import Loader from "../../../components/posts/single-post/PostLoader/postLoader.js";

function Bubble({
  data,
  owned,
  index,
  deleteMessage,
  setSelectedMsg,
  forwardClick,
  userId,
  userData,
  onLightBoxClick,
  parentMsgClick,
  onSaveFiles,
  colors,
  GroupChat,
}) {
  const { t, i18n } = useTranslation();
  var classes = owned ? "owned" : "";
  const [isParentMsgVisibile, setParentMsgVisible] = useState(false);
  const [parentMsg, setParentMsg] = useState("");
  const [showCopyToast, setCopyToast] = useState(false);

  const [show, setShow] = useState(false);

  const [sharePost, setSharePost] = useState(false);

  const [currentUser, setCurrentUser] = useState("");

  const [msgType, setMsgType] = useState("");

  const [showDeleteOptionModal, setDeleteOptionModal] = useState(false);
  const [isOwnMsg, setOwnMsg] = useState(false);
  const [isMsgDeleted, setMsgDelete] = useState(false);

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("profile"));
    setCurrentUser(userData);
  }, []);

  useEffect(() => {}, [data.loci_messID]);

  function onDeleteMessage() {
    if (data.LCM_from != userId) {
      setOwnMsg(true);
      setDeleteOptionModal(true);
    } else {
      setOwnMsg(false);
      setDeleteOptionModal(true);
    }
    setShow(false);
  }

  const deleteMsgConfirm = () => {
    alert("aaa");
  };
  const deleteForMe = (type) => {
    deleteMessage(index,type);
    setDeleteOptionModal(false);
    setMsgDelete(true);
    setCopyToast(true);
  };

  const onSaveClick = (data, attachmentId) => {
    console.log('check atta', data)
    onSaveFiles(data, attachmentId);
  };

  const onReplyClick = (data) => {
    setSelectedMsg(data);
    setShow(false);
  };

  const copyToClipBoard = async (data) => {
    // console.log(data);
    let text = "";
    if (
      data.LC_message_type == "attachment" &&
      data.LCM_attachment.length > 0
    ) {
      data.LCM_attachment.map((i) => {
        if (i.url !== undefined) {
          text += i.url + "\n";
        }
      });
      text = text.slice(0, -1);
    } else {
      text = data.LCM_text;
    }
    const el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setMsgDelete(false);
    setCopyToast(true);
    setShow(false);
  };

  const onForwardClick = (data) => {
    forwardClick(data);
    setShow(false);
    setMsgType("forward");
  };

  const onShareClick = (data) => {
    setSharePost(true);
    setShow(false);
  };

  const openLightBox = (data, index) => {
    onLightBoxClick(data, index);
  };

  const onParentMsgClick = (id) => {
    parentMsgClick(id);
  };

  const getDynamicClass = (index, attach) => {
    var type = "";
    if (attach?.length > 0) {
      type = attach[0].atype;
    }

    switch (index) {
      case 1:
        return `one ${type}`;
      case 2:
        return "two";
      case 3:
        return "three";
      case 4:
        return "four";
      default:
        return "more";
    }
  };

  const ShowImageView = ({data, item, i}) => {

    return (
      <>
        {/* {data.LCM_attachment.length >= 4 && i <= 3 && ( */}
        {data.LCM_attachment.length >= 0 && i <= 3 && (
          <div
            className={
              data.LCM_attachment.length > 1
                ? "col-6 imgcont"
                : "col-12 imgcont"
            }
            onClick={() => openLightBox(data.LCM_attachment, i)}
          >
            {item?.atype === "p" && (
              <img src={item.url.replace("chat_", "s_chat_")} alt="" />
            )}
            {item?.atype === "v" && (
              <img
                src={item.fthumbnail ? item.fthumbnail : customVideoThumb}
                alt=""
              />
            )}
            {i === 3 && data.LCM_attachment.length > 4 && (
              <div className="more-media-content cursor">
                <span>
                  {" "}
                  {data.LCM_attachment.length - 4 === 0
                    ? null
                    : `+ ${data.LCM_attachment.length - 4}`}
                </span>
              </div>
            )}
            {item?.atype === "v" && (
              <i className="play-btn material-icons">play_circle_outline</i>
            )}
          </div>
        )}
      </>
    );
  };

  const bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return (bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i];
  };

  return (
    <>
      <div
        id={"mid" + data.loci_messID}
        key={"midkey" + data.loci_messID}
        className={"message-bubble-wrapper " + classes}
      >
        {data.loading ? (
          <span className=" message-bubble message-loader-div">
            <Spinner animation="border" role="status" color="var(--primary-color)">
              <span className="sr-only">Loading...</span>
            </Spinner>
            {/* <Loader/> */}
          </span>
        ) : (
          <>
            <span className="message-bubble">
              {!owned && GroupChat && (
                <p style={{ color: colors }} className="firstName">
                  {data.USE_firstname}
                </p>
              )}

              {data.LC_reply_id &&
                data.LC_reply_id !== "0" &&
                data.LC_forward_id === "0" && (
                  <div
                    className="reply-msg-cont cursor"
                    onClick={() => onParentMsgClick(data.LC_reply_id)}
                  >
                    <div className="textCont">
                      <div className="sendername text-uppercase">
                        {data.LCM_replied_userID == currentUser.userId
                          ? t("you_title")
                          : data?.LCM_replied_USE_firstname || ""}
                      </div>
                      <div style={{ wordBreak: "break-word" }} className="msg">
                        <div>{getAttchamentIcon(data)}</div>
                      </div>
                    </div>
                  </div>
                )}
              {/* for forward msg design */}
              {data.LC_forward_id && data.LC_forward_id !== "0" && (
                <div className="forward">
                  <span className="reverseicon">
                    <i className="material-icons mr-5 black">reply</i>
                    {t("title_forwarded_msg")}
                  </span>
                </div>
              )}
              {/* Image Design */}
              <div
                className={`send-img-cont ${getDynamicClass(
                  data.LCM_attachment?.length,
                  data.LCM_attachment
                )}`}
              >
                <div className="row no-gutters">
                  {data.LCM_attachment?.map((item, i) =>
                    item.atype === "p" || item.atype === "v"
                      ? <ShowImageView data={data} item={item} i={i} key={`imgview${item.attachmentID}${i}`} />
                      : null
                  )}
                </div>
              </div>

              {/* File Share deign */}

              <div>
                {data.LCM_attachment.map((item, i) =>
                  item.atype === "d" ? (
                    <a>
                      <div className="file-msg-cont text-center" onClick={() => {onSaveClick(data, item.attachmentID)}}>
                        <div className="textCont">
                          <div className="filesize">
                            {bytesToSize(item.fproperties)}
                          </div>
                          <span className="iconspan">
                            <i className="material-icons mr-5 black">
                              insert_drive_file
                            </i>
                          </span>
                          <div className="text-break filename">
                            {" "}
                            {item.fname}
                          </div>
                        </div>
                      </div>
                    </a>
                  ) : null
                )}
              </div>

              {/* <div className="mt-2 caption">{data.LCM_text}</div> */}

              {/* Conatct share design */}

              <div>
                {data.LCM_attachment.map((data, i) =>
                  data.atype === "c" ? (
                    <a key={`contact${data.loci_messID}${i}`}>
                      <div className="file-msg-cont text-center" onClick={() => {console.log("checkt", data); onSaveClick(data)}}>
                        <div className="textCont">
                          {/* <div className="filesize">{data.fproperties} KB</div> */}
                          <span className="iconspan">
                            <i className="material-icons mr-5">contacts</i>
                          </span>
                          <div className="text-break filename">
                            {data.fname}
                          </div>
                        </div>
                      </div>
                    </a>
                  ) : null
                )}
              </div>

              <div>
                {data.LCM_attachment.map((item, i) =>
                  item.atype === "a" ? (
                    <div
                      key={"audiodiv" + data.loci_messID + "" + i}
                      className="d-flex align-items-center audio-cont"
                    >
                      {/* <div>
                      <i className=" material-icons">mic</i>
                    </div>
                    <div>{displayTimer(item.fproperties)}</div> */}
                      <audio controls preload="auto">
                        <source src={item.url} type="audio/mpeg" />
                      </audio>
                    </div>
                  ) : null
                )}
              </div>

              <div className="w-100">
                {ReactHtmlParser(
                  linkifyHtml(data.LCM_text, {
                    nl2br: true,
                    target: {
                      url: "_blank",
                    },
                  })
                )}
              </div>

              <div className="message-bubble-date">
                {moment(data.LCM_date).format(" HH:mm")}
              </div>
            </span>

            <div className="menuoption">
              <Dropdown show={show} onToggle={() => setShow(!show)}>
                <Dropdown.Toggle
                  id="dropdown-profile"
                  as="button"
                  className="btn btn-clear"
                >
                  <i className="material-icons green">keyboard_arrow_down</i>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropMenus">
                  {data.LCM_attachment && data.LCM_attachment.length > 0 && (
                    <IconButton
                      text={t("save")}
                      clear="true"
                      handleClick={() => {console.log("checku", data); onSaveClick(data)}}
                      // className="fs-3"
                      icon="save"
                      iconRight={true}
                      outlined={true}
                    />
                  )}

                  <IconButton
                    text={t("reply_menu_option")}
                    clear="true"
                    handleClick={() => onReplyClick(data)}
                    // className="fs-3"
                    icon="reply"
                    iconRight={true}
                  />
                  {/* </a> */}

                  <IconButton
                    text={t("copy_menu_option")}
                    clear="true"
                    handleClick={() => copyToClipBoard(data)}
                    // className="fs-3"
                    icon="file_copy"
                    iconRight={true}
                    outlined={true}
                  />
                  <IconButton
                    text={t("forward_menu_option")}
                    clear="true"
                    handleClick={() => onForwardClick(data)}
                    className="reverseicon"
                    icon="reply"
                    iconRight={true}
                  />
                
                  <IconButton
                    text={t("delete_menu_option")}
                    clear="true"
                    handleClick={() => onDeleteMessage()}
                    // className="fs-3"
                    icon="delete"
                    iconRight={true}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </>
        )}
        {/* {"mid" + data.loci_messID} */}
        {/* <MessageConsumer> 
      {(data)=>{
        return <div>Hello {data.msg}</div>
      }}
    </MessageConsumer> */}

        {/* )} */}
        {sharePost && (
          <SharePostDialog
            typeChat={"bubble"}
            showModal={sharePost}
            shareUrl={
              data.LCM_attachment.length > 0
                ? data.LCM_attachment.map((me) => me.url).join("\n")
                : data.LCM_text
            }
            shareUrlSocial={
              data.LCM_attachment.length > 0
                ? data.LCM_attachment.map((me) => me.url).join("\n \n")
                : data.LCM_text
            }
            shareUrlType="media"
            handleClose={() => setSharePost(false)}
          />
        )}

        <Toast
          style={{
            position: "absolute",
            width: "100%",
            maxWidth: "300px",
            right: "calc(50% - 150px)",
          }}
          show={showCopyToast}
          onClose={() => setCopyToast(false)}
          delay={3000}
          autohide
        >
          <ToastBody>
            {isMsgDeleted ? t("msg_deleted_title") : t("msg_copied_title")}
          </ToastBody>
        </Toast>
      </div>

      <Modal
        show={showDeleteOptionModal}
        onHide={() => setDeleteOptionModal(false)}
        contentClassName="forPadding"
        className="custom-modal"
      >
        <IconButton
          clear="true"
          className="closeIcon"
          icon="close"
          handleClick={() => setDeleteOptionModal(false)}
        />
        <h3 style={{marginBottom:"30px"}}>{t("delete_chat_message")}</h3>
        <div className="d-flex justify-content-between forButtons">
          <div
            className="delete-options"
            onClick={() => setDeleteOptionModal(false)}
          >
            {t("cancel_alert")}
          </div>
          

         
           <Button
            text={t("delete_for_me")}
            handleClick={() => deleteForMe("me")}
          />

          {owned && <Button
            text={t("delete_for_all")}
            handleClick={() => deleteForMe("all")}
          />}

        </div>
        {/* {isOwnMsg && (
          <div
            className="delete-options"
            onClick={() => setDeleteOptionModal(false)}
          >
            {t("delete_for_everyone_title")}
          </div>
        )} */}
      </Modal>
    </>
  );
}
export default Bubble;
