import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { dotDateFormatter } from "lib/utils/Commons";
import UserDetails from "../../user-details";

function LikeTabData(props) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    console.log("list", props.likes);
  }, [props.likes]);

  const [showUserDetails, setShowUserDetails] = useState(false);
  const [userId, setUserId] = useState(null);

  const clickOnName = (params) => {
    if (props.originFrom === "child") {
      setUserId(params);
      setShowUserDetails(true);
    } else {
      props.clickOnName(params);
    }
  };
  return (
    <>
      <div className="comment-section">
        <div className="heading">
          <h3>{t("activity_post_likes_text")}</h3>
        </div>
        {props.likes !== undefined && props.likes.length > 0 ? (
          <div className="comment-section">
            {props.likes.map((like) => (
              <div className="data-section">
                <div
                  className="left cursor"
                  onClick={() => clickOnName(like.FK_userID)}
                >
                  <div className="img-cont">
                    <img
                      src={
                        like.USE_pic !== ""
                          ? like.USE_pic
                          : "/images/profile.png"
                      }
                    />
                  </div>
                  <div className="text-data">
                    <div className="subheader">
                      {like.USE_firstname + " " + like.USE_surename}
                    </div>
                    {/* <div className="comment">is Likeing the</div> */}
                  </div>
                </div>
                <div className="right">
                  {dotDateFormatter(like.LOL_date)}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>{t("No_data_found")}</div>
        )}
      </div>

      {showUserDetails && (
        <UserDetails
          id={userId}
          {...props}
          handleClose={() => setShowUserDetails(false)}
        />
      )}
    </>
  );
}

export default LikeTabData;
