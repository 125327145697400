import * as Styled from "./styled";

interface Props {
  loading?: boolean;
  loadingPlaceholder?: JSX.Element;
  children: React.ReactNode;
}

export const HandleLoadingState = ({ loading, loadingPlaceholder, children }: Props) => {
  if (loading) {
    return (
      <>
        {loadingPlaceholder || (
          <Styled.LoaderIconWrapper>
            <Styled.LoaderIcon />{" "}
          </Styled.LoaderIconWrapper>
        )}
      </>
    );
  }
  return <>{children}</>;
};
