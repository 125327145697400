export type PostData = {
  GROUP_CIT_plz: string;
  GROUP_STA_name: string;
  STA_name: string;
  stateID: string;
  FK_user_categoryID: string;
  loci_postID: string | number;
  FK_userID: string | number;
  LCP_subject: string;
  LCP_mess: string;
  LCP_comment: string;
  LCP_join: string;
  LCP_img: string;
  LCP_date: string;
  LCP_video: string;
  LCP_mayor: string;
  LCP_intern: string;
  USE_firstname: string;
  USE_surename: string;
  USE_plz: string;
  USE_pic: string;
  LCU_fbpic: string;
  FK_loci_postID: string;
  LCP_admin_post: string;
  LCP_post_visible_admin: string;
  LCU_community: string;
  LCP_community: string;
  LCP_community_visible: string;
  display: string;
  comAnz: string;
  mycomAnz: string;
  likeAnz: string;
  mylikeAnz: string;
  followAnz: string;
  myfollowAnz: string;
  LCP_pending: string;
  OrgGroupId: string;
  LGP_shareable: string;
  GroupAdminShare: string;
  LCP_origin: string;
  LCP_media: PostMedia[];
  LCP_docs: PostDoc[];
  my: string;
  Share: string;
  LDC_name: string;
  UCT_name?: string;
  superAdmin?: string;
  LCP_cta:any;
  mentions?: Mentions;
};

export type PostDoc = {
  loci_docID: number;
  doc: string;
  file: string;
  size: number;
};

export type PostMedia = {
  loci_imageID: number;
  img: string;
  type: PostMediaType;
  thumb: string;
};

export interface MentionDetails {
  ID: string;
  display_name: string;
  type: string;
  action: string;
}

export type Mentions = {
  [key: string]: MentionDetails;
};

export enum PostMediaType {
  PHOTO = "p",
  VIDEO = "v",
}
