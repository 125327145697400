import React, { Component } from "react";
import "./UserDetails.scss";
import { Card, Col, Container, Image, Row } from "react-bootstrap";

import { withTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { blockUser, getUserProfile } from "../../api/user";
import IconButton from "../elements/buttons/IconButton";
import IconLink from "../elements/buttons/IconLink";

import moment from "moment";
import { favoritePost, reportUser } from "../../api/posts";
import HtmlParser from "html-react-parser";
import Loader from "../../components/posts/single-post/PostLoader/postLoader.js";
import Lightbox from "../elements/lightbox";
import { withRouter } from "../customNavigate/withRouter";
import linkifyHtml from "linkify-html";
import ReactHtmlParser from "html-react-parser";
import {  dotDateFormatter } from "lib/utils/Commons";
import ReportPost from "../posts/report-post";  
import { connect } from "react-redux";
import  { mapStateToProps } from "lib/store/mapStateToProps";

class UserDetails extends Component {
  state = {
    message: "",
    data: {},
    loading: true,

    isLightboxOpen: false,
    lightboxMedia: [],
    lightboxIndex: 0,
    reportUser:false,
    isReported:false
  };

  componentDidMount() {
    console.log("props in UD-", this.props);
    if (this.props.id) {
      getUserProfile(this.props.token, this.props.id).then((res) => {
        if (res.data.OK) {
          this.setState({ data: res.data.OK, loading: false });
        }
      });
    }
  }

  favoritePostAction() {
    favoritePost(
      this.props.token,
      this.props.postId,
      !this.state.data.Fav
    ).then((res) => {
      console.log(res);
      if (res.data.OK) {
        getUserProfile(this.props.token, this.props.id).then((res) => {
          if (res.data.OK) {
            this.setState({ data: res.data.OK });
          }
        });
      }
    });
  }

  blockUserAction() {
    blockUser(
      this.props.token,
      this.state.data.userID,
      !this.state.data.Blocked
    ).then((res) => {
      if (res.data.OK) {
        getUserProfile(this.props.token, this.props.id).then((res) => {
          if (res.data.OK) {
            this.setState({ data: res.data.OK });
          }
        });
      }
    });
  }

 

  openLightBox = (imageUrl, lightboxIndex = 0) => {
    let media = [{ type: "p", img: imageUrl }];
    if (media && media.length > 0 && imageUrl !== "") {
      this.setState({
        lightboxMedia: media,
        lightboxIndex: lightboxIndex,
        isLightboxOpen: true,
      });
    }
  };

  onPostsClick = (userId) => {   
    console.log("sed");
    this.props.history.push("/user-posts/" + userId);
  };

  onMsgClick = (data, id) => {
    console.log(data, id,"sed")
    if (data === undefined){ 
      console.log("sed");
      this.props.history.push("/chat/" + id);
    } 
    else{
      this.props.handleClose();
    }
  };

  reportPostAction=async(message)=> {
    console.log('props',this.props,message);
    try {
      const response=await reportUser(this.props.token,this.props.id,message)
      console.log(response);
      const data=response.data
      if(data.OK===1) {
        this.setState({ isReported: true });

      }
    }
    catch(e) {
alert(e)
    }
  }  
 
  render() {
    const data = this.state.data;
    const { t } = this.props; 
    console.log(this.props.showRegionalData,"sxsx");
    return (
      <>
      <div className="user-details-modal">
        <Modal
          centered
          show
          onHide={this.props.handleClose}
          className={
            !this.state.isLightboxOpen
              ? "custom-modal"
              : "custom-modal dynamiczindex"
          }
        >
          <IconButton
            clear="true"
            className="closeIcon"
            icon="close"
            handleClick={this.props.handleClose}
          />
          <Modal.Body className="user-details">
            <Container fluid>
              {this.state.loading ? (
                <Loader />
              ) : (
                <Row>
                  <Col md={12}>
                    <Card className="group-profileCard shadow mb-5 bg-white">
                      <div className="card-header"></div>
                      <Card.Body>
                        <Image
                          src={
                            data.USE_pic ? data.USE_pic : "/images/profile.png"
                          }
                          
                          className="profile-image cursor"
                          onClick={() => this.openLightBox(data.USE_pic)}
                        />

                        <div className="grpname">
                          {" "}
                          {data.USE_firstname} {data.USE_surename}
                        </div>
                        <div className="add">
                          {data.USE_plz} <span> {data.CIT_name}</span>,
                          <span className="pl-1">{data.STA_name}</span>
                        </div>
                        <div class="createdat">
                          <span> {t("view_profile_since")} </span>
                          <span className="pl-1">
                            {dotDateFormatter(data.USE_created)}
                          </span>
                        </div>
                        {/* <div className="pt-3">
                        <Button
                          className="newbtn"
                          onClick={() => this.favoritePostAction()}
                        >
                          {data.Fav
                            ? t("view_profile_unfollow")
                            : t("view_profile_follow")}
                        </Button>
                      </div> */}
                      </Card.Body>
                      <Card.Footer className="bg-white">
                        <Row>
                         {this.props.showRegionalData && <Col
                            className="col-4 nopadding text-center rb"
                            // onClick={() => this.onPostsClick(data.userID)}
                          >
                            <div className="data-label ">
                              {t("my_posts_name_secondary")}
                            </div>
                            <div className="data-value ">
                              {data.Posts}
                            </div>
                          </Col>}
                          <Col className={`${this.props.showRegionalData ? 'col-4' : 'col-6'}  nopadding text-center rb`}>
                            <div className="data-label">
                              {t("activity_post_likes_text")}
                            </div>
                            <div className="data-value">{data.Favamount}</div>
                          </Col>
                          <Col className={`${this.props.showRegionalData ? 'col-4' : 'col-6'}  nopadding text-center rb`}>
                            <div className="data-label">{t("followers")}</div>
                            <div className="data-value">{data.Followers}</div>
                          </Col>
                        </Row>
                      </Card.Footer>
                    </Card>
                  </Col>
                  <Col md={12}>
                    {data.myself === "n" && (
                      <Card className="profileCard">
                        <Card.Body>
                          {/* <div className="ctitle">
                            {t("get_in_touch_with")} <b>{data.USE_firstname}</b>
                          </div> */}
                          <div className="row btn-container">
                           {this.props.showRegionalData && <div className="col-sm-6 ">
                              <IconLink
                                icon="mail_outline"
                                text={t("posts")}
                                type="button"
                                outlined={true}
                                clear={true}
                                className="custom-outlined-btn"
                                link={`/user-posts/` + data.userID}
                              />
                            </div>}
                            <div className={`col-sm-6 ${this.props.showRegionalData ? '' : 'pt-4'}`}>
                              <IconLink
                                icon="mail_outline"
                                text={t("commons_alert_message")}
                                type="button"
                                outlined={true}
                                clear={true}
                                className="custom-outlined-btn"
                                // handleClick={() =>
                                //   this.onMsgClick(
                                //     this.props.sourcePage,
                                //     data.userID
                                //   )
                                // }   
                                link={`/chat/` + data.userID}
                              />
                            </div>
                            {this.props?.showFollowOption && (
                              <div className="col-sm-6 pt-4 ">
                                <IconButton
                                  icon="person"
                                  text={
                                    data.Fav
                                      ? t("view_profile_unfollow")
                                      : t("view_profile_follow")
                                  }
                                  type="button"
                                  outlined={data.Fav ? false : true}
                                  clear={true}
                                  className="custom-outlined-btn"
                                  handleClick={() => this.favoritePostAction()}
                                />
                              </div>
                            )}
                            <div className="col-sm-6 pt-4 ">
                              <IconButton
                                icon={data.Blocked ? "remove_circle" : "block"}
                                text={
                                  data.Blocked
                                    ? t("view_profile_unblock")
                                    : t("view_profile_block")
                                }
                                type="button"
                                outlined={data.Blocked ? false : true}
                                clear={true}
                                className="custom-outlined-btn"
                                handleClick={() => this.blockUserAction()}
                              />
                            </div>

                            <div className="col-sm-6 pt-4">
                              <IconButton
                                icon={"report_icon"}
                                text={
                                  
                                  t("report_post_button")
                                }
                                type="button"
                                outlined={true}
                                clear={true}
                                className="custom-outlined-btn"
                                handleClick={() => this.setState({
                                  reportUser:true
                                })}
                              />
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    )}

                    <Card className="group-contactCard">
                      {/* {this.state.data?.myself === "y" && (
                     
                    )} */}
                      <Card.Body>
                        {this.state.data?.myself === "y" && (
                          <div>
                            <Card.Title>
                              {t("view_profile_about_title")}
                            </Card.Title>
                            <div className="contact-section">
                              <div>
                                {/* <div className="d-flex  nopadding pb-1">
                                  <div className="data-title">
                                    {t("view_profile_sex")}:
                                  </div>
                                  <div>
                                    <span className="data-value ">
                                      {data.USE_sex ? data.USE_sex : "N/A"}
                                    </span>
                                  </div>
                                </div> */}
                                <div className="d-flex nopadding pb-1">
                                  <div className="data-title">
                                    {t("view_profile_email")}:
                                  </div>
                                  <div>
                                    <a
                                      href={`mailto:${data.USE_email}`}
                                      className="data-value"
                                      target="_blank"
                                    >
                                      {data.USE_email}
                                    </a>
                                  </div>
                                </div>
                              </div>

                              <div>
                                {/* <div className="d-flex nopadding pb-1">
                                  <div className="data-title">
                                    {t("view_profile_age")}:
                                  </div>
                                  <div>
                                    <span className="data-value">
                                      {moment(data.USE_birth).isValid()
                                        ? moment().diff(data.USE_birth, "years")
                                        : "/"}
                                    </span>
                                  </div>
                                </div> */}
                                <div className="d-flex  nopadding pb-1">
                                  <div className="data-title">
                                    {t("view_profile_telephone")}:
                                  </div>
                                  <div className="data-value">
                                    {" "}
                                    {data.USE_mobile ? data.USE_mobile : "N/A"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="desc-section">
                          <Row>
                            <div className="col-lg-12">
                              <Card.Title>
                                {t("view_profile_description")}:
                              </Card.Title>
                              <Card.Text>
                                {data.LCU_mess
                                  ? ReactHtmlParser(
                                      linkifyHtml(data.LCU_mess, {
                                        nl2br: true,
                                        target: {
                                          url: "_blank",
                                        },
                                      })
                                    )
                                  : "N/A"}
                              </Card.Text>
                            </div>
                          </Row>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}
            </Container>
          </Modal.Body>
        </Modal>

        {this.state.isLightboxOpen && (
          <Lightbox
            onClose={() => this.setState({ isLightboxOpen: false })}
            media={this.state.lightboxMedia}
            index={this.state.lightboxIndex}
            setIndex={(i) => this.setState({ lightboxIndex: i })}
          />
        )}
      </div>

      {this.state.reportUser && (
            <ReportPost
              reporting={this.state.reportUser}
              handleClose={() => this.setState({ reportUser: false })}
              reportPost={this.reportPostAction}
              isReported={this.state.isReported}
              isReportingUser={this.state.reportUser}
            />
          )}
      </>
    );
  }
}

export default withTranslation()(connect(mapStateToProps)(withRouter(UserDetails)));
