import moment from "moment";
import { string } from "prop-types";
import IconButton from "../../components/elements/buttons/IconButton";

export function getInitials(title) {
  var name = title;
  if (title) {
    var initials = name.match(/\b\w/g) || [];
    initials = (
      (initials.shift() || "") + (initials.pop() || "")
    ).toUpperCase();
    return initials;
  }
  return name;
}

export async function ngxBase64(imageBlob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      resolve(reader.result);
      return reader.result;
    });
    if (imageBlob) {
      reader.readAsDataURL(imageBlob);
    }
    reader.onerror = function (error) {
      return null;
    };
  });
}

export function getAttchamentIcon(data) {
  let showImg = data?.LCM_replied_attachment?.length > 0 ? true : false;
  console.log('status',showImg);
  if (
    data?.LCM_replied_attachment?.length > 0 
  ) {
    const item =
    data.LCM_replied_attachment && data.LCM_replied_attachment[0] !== undefined
        ? data.LCM_replied_attachment[0]
        : "";
    if (item) {
      let messageType = item.atype;
      switch (messageType) {
        case "a":
          return (
            <IconButton
              text={"audio_title"}
              icon={"mic"}
              clear={true}
              className="last-message"
              translateText={true}
            />
          );

        case "d":
          return (
            <IconButton
              text={"document_title"}
              icon={"attachment"}
              clear={true}
              className="last-message"
              translateText={true}
            />
          );
        case "c":
          return (
            <IconButton
              text={"contact_title"}
              icon={"contacts"}
              clear={true}
              className="last-message"
              translateText={true}
            />
          );
        case "p":
          return (
            <IconButton
              text={"camera_title"}
              icon={"photo_camera"}
              clear={true}
              className="last-message"
              image={showImg ? item.url : ""}
              translateText={true}
            />
          );
        case "v":
          return (
            <IconButton
              text={"video_title"}
              icon={"photo_camera"}
              clear={true}
              className="last-message"
              image={showImg ? item.fthumbnail : ""}
              translateText={true}
            />
          );

        default: {
          return data.LCM_replied_message;
        }
      }
    }
  } else return data.LCM_replied_message;
}

export function captureUserMedia(callback) {
  var params = { audio: true, video: true };
  navigator.getUserMedia(params, callback, (error) => {
    console.log(JSON.stringify(error));
  });
}

// export function videoURLtoFile(dataurl, filename) {
//   var arr = dataurl.split(','),
//       mime = arr[0].match(/:(.*?);/)[1],
//       bstr = atob(arr[1]),
//       n = bstr.length,
//       u8arr = new Uint8Array(n);

//   while(n--){
//       u8arr[n] = bstr.charCodeAt(n);
//   }
//   return new File([u8arr], filename, {type:mime});
// }

export const blobToFile = (theBlob, fileName) => {
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
};

export function videoURLtoFile(url, filename, mimeType) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

export const notAllowedFileTypes = [
  "x-php",
  "x-ms-dos-executable",
  "tiff"
];
export const allowedMediaTypes = [
  "png",
  "jpg",
  "jpeg",
  "bmp",
  "mp4",
  "webp",
];



export const dateFormatter=(date)=> {
  if(date.includes("-"))
  return moment(date).format('DD/MM/YYYY');
  else
  return moment(date,'DD.MM.YYYY').format("DD/MM/YYYY")
}



export const dotDateFormatter=(date)=> {    
  console.log();
if(typeof(date) === "string" ) { 
if(date?.includes(".")) {
  return date.replaceAll(".","/")
}else{
  return moment(date).format('DD/MM/YYYY HH:mm');
}
}
else {
 return moment(date).format('DD/MM/YYYY HH:mm'); 
}

}

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
    );
}; 

export const validateLink = (link) => {
  const linkRegex = /^(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]+){1,}(?:\/[^\s]*)?$/;
  return linkRegex.test(link);
};


export const validatePassword=(pass)=>{
  return pass.trim().length>7
}

// application/x-php
//application/x-ms-dos-executable
// image/tiff
