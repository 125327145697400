import { Card } from "components/shared/card";
import { GroupSettingsData } from "lib/types/groupTypes";
import { InfoTabFiles } from "./InfoTabFiles";
import { FeedContainer } from "./style";

type Props = {
  data: GroupSettingsData | null;
};

export const MediaTab = ({ data }: Props) => {
  return (
    <FeedContainer $width="100%">
      <Card>
          <InfoTabFiles data={data} />
      </Card>
    </FeedContainer>
  );
};