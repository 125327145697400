import React from "react";
import "./Button.scss";
import classNames from "classnames";
function Button(props) {

  return (
    <button
      name={props.name}
      className={classNames("btn", "btn-normal", props.className, {"btn-clear" : props.clear})}
      disabled={props.disabled}
      type={props.type ? props.type : "button"}
      onClick={props?.handleClick}
    >
      <span>{props.text}</span>
    </button>
  );
}
export default Button;
