import { getPostData } from "api/posts";
import { logError } from "lib/utils/logError";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Feed from "../../feed";

type Props = {
  token: string;
};

function ViewSinglePost({ token }: Props) {
  const params = useParams<{ id: string }>();
  const [groupId, setGroupId] = useState();

  useEffect(() => {
    if (!params.id) return;
    (async () => {
      try {
        const response = await getPostData(token, params.id);
        if (response.data.OK) {
          const data = response.data.OK;
          setGroupId(data?.FK_user_categoryID);
        }
      } catch (error) {
        logError(error);
      }
    })();
  }, [params]);

  if (!params.id || !groupId) return null;

  return (
    <Feed singlePost token={token} postId={params.id} groupId={groupId === "0" ? "" : groupId} />
  );
}

export default ViewSinglePost;
