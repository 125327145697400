import React, { Component } from "react";
import {  Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import IconButton from "../buttons/IconButton";




const ShowInfoModal=({status,onModalClose,text})=> {
  const { t } = useTranslation();

    return (
        <>
        <Modal
          show={status}
          onHide={() => onModalClose()}
          className="custom-modal"
        >
          <IconButton
            clear="true"
            className="closeIcon"
            icon="close"
            handleClick={() => onModalClose()}
          />
          {t(text)}
        </Modal>
        </>
    )
}

export default ShowInfoModal