import React from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./DropdownSearch.scss";
import PerfectScrollbar from "react-perfect-scrollbar";

function DropdownSearch({results, handleClick,fetchData}) { 

  const handleScroll = cotainer => {
   
    const { scrollTop, clientHeight, scrollHeight } = cotainer; 
    console.log(scrollTop, clientHeight, scrollHeight ,"xxx1");

    if (scrollTop === undefined || clientHeight === undefined || scrollHeight === undefined) return; // Check if properties are defined
  
    if (scrollHeight - scrollTop === clientHeight) {
      // When scrolled to the bottom
      fetchData()
    }
  };

  return (
    <div className="dropdown-search-wrapper">
      <PerfectScrollbar onScrollY={container => handleScroll(container)} >
        <ul>

          {
            results.map((x, i) => (
              <li key={i} onClick={() => handleClick(x.id, x.n)}>
                {x.n}
              </li>
              ))
          }
        </ul>
        </PerfectScrollbar>

    </div>
     
 
  );
}

export default DropdownSearch;
