/* eslint-disable jsx-a11y/iframe-has-title */
import { useState, useEffect } from "react";
import { Card, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./generaltab.scss";
import i18n from "i18next";
import BackButton from "../../components/commons/BackButton";

const GeneralContainer = () => {
  const { t } = useTranslation();
  const language = i18n.language.split("-")[0];

  const [key, setKey] = useState("agb");
  const [link, setLink] = useState(`/agb_${language}`);

  const handleTabSelect = (param) => {
    setKey(param);
    handleDynamicLinks(param);
  };

  const handleDynamicLinks = (key) => {
    const language = i18n.language.split("-")[0];

    switch (key) {
      case "agb":
        setLink("/agb_" + language);
        break;
      case "privacy":
        // const language = i18n.language.split("-")[0];
        setLink("/privacy_" + language);

        break;
      case "imprint":
        //   const language = i18n.language.split("-")[0];
        setLink("/imprint_" + language);

        break;
      default:
        break;
    }
  };

  useEffect(() => {
    handleDynamicLinks();
  }, [i18n.language]);

  return (
    <>
    <div className="container">
    {/* <div class="ctaBack">
    <BackButton/>
    </div> */}
    
      <Card className="gdpr-card">
        <Card.Body>
         
          <Tabs 
             justify
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => handleTabSelect(k)}
            unmountOnExit={true} 
            className="tab-content"
          >
            <Tab eventKey="agb" title={t("agb_title_tab_title")}
            //  tabClassName="col-lg-4"
             >
              {link && (
                <div className="html-content ">
                  <iframe
                    src={link}
                    frameBorder={0}
                    width={"100%"}
                    className={"iframe"}
                  />
                </div>
              )}
            </Tab>
            <Tab
              eventKey="imprint"
              title={t("imprint_title_primary")}
              // tabClassName="col-lg-4"
            >
              {link && (
                <div className="html-content">
                  <iframe
                    src={link}
                    frameBorder={0}
                    width={"100%"}
                    className={"iframe"}
                  />
                </div>
              )}
            </Tab>
            <Tab
              eventKey="privacy"
              title={t("privacy_title_primary")}
              // tabClassName="col-lg-4"
            >
              {link && (
                <div className="html-content">
                  <iframe
                    src={link}
                    frameBorder={0}
                    width={"100%"}
                    className={"iframe"}
                  />
                </div>
              )}
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
      </div>
    </>
  );
};

export default GeneralContainer;
