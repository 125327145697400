import React, { useEffect } from "react"; 
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const Redirect = ({to}) =>{    

    console.log(to,"aaa");
    const navigate =  useNavigate() 

    useEffect(()=>{
        redirectTo(to?.pathname)
    },[to])
     
    const redirectTo = (path) =>{ 
            navigate(path)
    }  

    return (
       null
    )
} 

export default Redirect