import React, { createContext, useState, useEffect, useContext } from "react";
import Chat from "../../../components/inbox/chat";

const MessageContext = React.createContext({
  message: {
    msgId: null,
    msg: "",
    msgType: "",
  },
  saveMessageDetails: (value) => {},
});

const MessageProvider=MessageContext.Provider;
const MessageConsumer=MessageContext.Consumer;


export {MessageProvider,MessageConsumer}

// export const useMessageContext = () => useContext(MessageContext);

// export const MessageContextProvider = () => {
//   console.log('called');

//   // console.log('children',children);
//   const [message, setMessage] = useState();

//   const saveMessageDetails = (value) => {
//     console.log("context-data", value);
//     setMessage(value);

//     if (message !== null) {
//       console.log("messge", message);
//       return message;
//     }
//   };

//   const value = {
//     message,
//     saveMessageDetails,
//   };

//   return (
//     <MessageContext.Provider value={value}><Chat/></MessageContext.Provider>
//   );
// };
