import { API_BASE_URL, API_BASE_HEADERS } from "../global";
import axios from 'axios'
// const axios = require("axios");


export function resetPassword(email) {
  const postData = new FormData();

  postData.append("PasEmail", email);
  postData.append("action", "W");

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export function getProfile(token) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: "U",
    },
  });
}

export function getProfileData(token) {
  const reqProfile = axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: "U",
    },
  });

  const reqCountry = axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: 14,
    },
  });

  return axios.all([reqProfile, reqCountry]);
}

export function registerUser(data) {
  const postData = new FormData();

  postData.append("action", "X");
  postData.append("USE_firstname", data.firstname);
  postData.append("USE_surename", data.lastname);
  postData.append("USE_email", data.email);
  postData.append("USE_pass", data.password);
  postData.append("USE_pass2", data.confirmPassword);
  postData.append("USE_promo", data.promocode.trim());
  postData.append("USE_sex", "M");
  postData.append("USE_state", data.myState);
  postData.append("USE_lang", data.myLang);
  postData.append("RegTownPro", data.regTown);
  postData.append("USE_town", data.regTown);
  postData.append("USE_town", data.regTown);
  postData.append("groupPubCode", data.groupPubCode);
  postData.append("invitationID", data.inviteCode || "0");
  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export function changePassword(token, newPass, newPass2) {
  const postData = new FormData();

  postData.append("action", 18);
  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("NewPass", newPass);
  postData.append("NewConPass", newPass2);

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export function getStatesAndLanguages() {
  return axios.get(API_BASE_URL, {
    params: {
      action: 14,
    },
  });
}

export function getSettings(token) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: "Z",
    },
  });
}

export function getGroupSettings(token, id) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: "31A",
      pID: id
    },
  });

}

export function saveSettings(token, data , from=null) {
  const postData = new FormData();

  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("action", "Y");
  postData.append("LCU_info_favorite", data.isNewFromFav ? "y" : "n");
  postData.append("LCU_info_comment", data.isCommentFromFavPosts ? "y" : "n");
  postData.append("LCU_info_likes", data.isNewLike ? "y" : "n");
  postData.append("LCU_info_mail", data.isInbox ? "y" : "n");
  postData.append("LCU_info_follow", data.isNewFollower ? "y" : "n");
  postData.append("LCU_info_region", data.isPostFromRegion ? "y" : "n");
  if(from===null){
  postData.append("LCU_info_group_join", data.isNewMember ? "y" : "n");
  postData.append("LCU_info_group", data.isNewPostInGroup ? "y" : "n");
}
  postData.append("LCU_info_permail", data.isEmailNotification ? "y" : "n");

  postData.append("LCU_radius", data.currentDistance);

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export function saveGroupSettings(token, gId, data,from) {
  console.log('dta', data,gId);

  const postData = new FormData();
  postData.append("grpID", gId)
  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("action", "75");
  if(from!=="grpSetting") {
  // postData.append("gcm_favpost", data.isNewFromFav ? "y" : "n");
  }
  postData.append("gcm_favcomment", data.isCommentFromFavPosts ? "y" : "n");
  postData.append("gcm_like", data.isNewLike ? "y" : "n");
  postData.append("gcm_follow", data.isNewFollower ? "y" : "n");
  postData.append("gcm_member", data.isNewMember ? "y" : "n");
  postData.append("gcm_post", data.isPostFromRegion ? "y" : "n");

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export function saveProfile(token, data) {
  const postData = new FormData();

  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("action", "P");
  postData.append("USE_firstname", data.firstname);
  postData.append("USE_surename", data.lastname);
  // postData.append("USE_birth", data.birthday);
  postData.append("USE_mobile", data.phone);
  // postData.append("USE_sex", data.gender);
  postData.append("USE_email", data.email);
  postData.append("RegTownPro", data.regTown);
  postData.append("USE_state", data.myState);
  postData.append("USE_lang", data.myLang);
  postData.append("USE_mess", data.about);
  if (data.avatar && data.avatar.length > 0) {
  // console.log(data?.avatar?.find(me=>me?.delete===undefined)?.file);
    postData.append("USE_pic",  data?.avatar?.find(me=>me?.delete===undefined)?.file);
  }   

  if(data.avatar && data.avatar.length > 0 && data.avatar[0].delete){
    postData.append("DelUserPic","y")
  }

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export function getMunicipality(postcode, state,offset) {
  return axios.get(API_BASE_URL, {
    params: {
      plz: postcode,
      country: state,
      action: 23,
      offset:offset,
    },
  });
}

export function getFavorites(token, search = "") {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: "Q",
      search: search,
    },
  });
}

export function getFollowers(token, search = "") {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: "6",
      search: search,
    },
  });
}

export function getMyCitizens(token) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: "65",
    },
  });
}

export function deleteUserFromFavorites(token, userID) {
  const postData = new FormData();

  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("action", "O");
  postData.append("FDel", userID);

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export async function getMyProfile(token) {
  return await axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,

      action: "U",
    },
  });
}

export function getUserProfile(token, userID) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,

      action: "5",
      pid: userID,
    },
  });
}

export function getBlacklist(token, search = "") {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,

      action: 27,
      search: search,
    },
  });
}

export function blockUser(token, userID, block) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: 15,
      u: userID,
      type: block ? "a" : "r",
    },
  });
}

export function getNotifications(token, offset) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: 26,
      offset: offset
    },
  });
}

export function getUserPostById(token, userId,offset) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      offset:offset,
      uId: userId,
      action: 55,
    },
  });
}

export function getUsers(token, params) {
  const postData = new FormData();

  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("LOS_radius", params.radius);
  postData.append("LOS_favourite", params.favourite ? "y" : "n");
  postData.append("LOS_follower", params.follower ? "y" : "n");
  postData.append("LOS_mygroup", params.group ? "y" : "n");
  postData.append("search", params.search ? params.search : "");
  postData.append("groupId", params?.groupId);

  postData.append("action", 58);

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export function inviteSelectedUsers(token, params) {
  const postData = new FormData();

  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("LOS_organi", params.groupId);
  postData.append("LOS_invited_userID", params.userIds);
  postData.append("LOS_link_type", "multipletime");
  postData.append("LOS_type", "locci users");
  postData.append("LOS_expiration_date", "");
  postData.append("action", 57);
  postData.append("LOS_always_valid", "y");

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export function shareGroupLink(token, params) {
  const postData = new FormData();

  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("LOS_organi", params.groupId);
  postData.append("LOS_invited_userID", "");
  postData.append("LOS_link_type", params.linkType);
  postData.append("LOS_type", params.linkFor);
  postData.append("LOS_expiration_date", params.linkExpireDate);
  postData.append("action", 57);
  postData.append(
    "LOS_always_valid",
    params.linkAlwaysValid !== undefined ? params.linkAlwaysValid : ""
  );

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export async function automaticLogin(link) {
  const postData = new FormData();
  postData.append("action", "70");
  postData.append("link", link);
  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}
