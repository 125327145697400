import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import "./viewgroupnew.scss";
import profileImage from "../../../src/assets/apple_logo.png";
import Checkbox from "../elements/checkbox";
import { useTranslation } from "react-i18next";

const ViewGroupStatic = () => {
  const { t } = useTranslation();

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg={3}>
            <Card className="group-profileCard shadow  mb-5 bg-white">
              <div className="card-header"></div>
              <Card.Body>
                <Image
                  src={profileImage}
                  
                  className="profile-image"
                />
                <div className="grpname pt-3">
                  <span>100</span>
                  <span className="pl-1">Raj webb</span>
                </div>
                <div class="createdat">
                  <span> Created at </span>
                  <span className="pl-1">12-05-2021</span>
                </div>
                <div className="pt-3">
                  <Button className="newbtn">Join Group</Button>
                </div>
              </Card.Body>
              <Card.Footer className="bg-white">
                <Row>
                  <Col className="col-4 nopadding text-center rb">
                    <div className="data-label">Group Posts</div>
                    <div className="data-value">10</div>
                  </Col>
                  <Col className=" col-4 nopadding text-center rb">
                    <div className="data-label">Members</div>
                    <div className="data-value">10</div>
                  </Col>
                  <Col className=" col-4 nopadding text-center rb">
                    <div className="data-label">Shared Posts</div>
                    <div className="data-value">10</div>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg={9}>
            <Card className="group-aboutusCard">
              <Card.Title>About us</Card.Title>
              <Card.Text>
                Some quick example text to build on the card title and make up
                the bulk of the card's content. Some quick example text to build
                on the card title and make up the bulk of the card's content.
                Some quick example text to build on the card title and make up
                the bulk of the card's content. Some quick example text to build
                on the card title and make up the bulk of the card's content.
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </Card.Text>
            </Card>
            <Card className="group-contactCard">
              <div className="contact-heading">contact</div>
              <Card.Body>
                <div className="contact-section">
                  <div className="d-sm-flex">
                    <div className="d-flex  col-6 nopadding">
                      <div className="data-title">Email:</div>
                      <div>
                        <span className="data-value">gamil</span>
                      </div>
                    </div>
                    <div className="d-flex col-6 nopadding">
                      <div className="data-title">Website:</div>
                      <div>
                        <span className="data-value">Google.com</span>
                      </div>
                    </div>
                  </div>

                  <div className="d-sm-flex pt-3">
                    <div className="d-flex  col-6 nopadding">
                      <div className="data-title">Telephone:</div>
                      <div>
                        <span className="data-value">99999999</span>
                      </div>
                    </div>
                    <div className="d-flex  col-6 nopadding">
                      <div className="data-title">Address:</div>
                      <div className="data-value">h124 new york</div>
                    </div>
                  </div>
                </div>
                <div className="media-section">
                  <Row className="pt-3">
                    <div className="col-lg-6 col-sm-12 ">
                      <div className="heading">Media and Documents</div>
                      <div className="media-box-cont">
                        <div className="media-box  d-flex justify-content-between">
                          <div>icon</div>
                          <div>filename</div>
                          <div>
                            {" "}
                            <i className="material-icons material-icons-outlined ml-15 greenIcon">
                              get_app
                            </i>
                          </div>
                        </div>
                        <div className="media-box  d-flex justify-content-between">
                          <div>icon</div>
                          <div>filename</div>
                          <div>
                            {" "}
                            <i className="material-icons material-icons-outlined ml-15 greenIcon">
                              get_app
                            </i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 sm-12 ">
                      <div className="heading">Group Rules</div>
                      <div className="media-box-cont">
                        <div className="media-box  d-flex justify-content-between">
                          <div>icon</div>
                          <div>filename</div>
                          <div>
                            {" "}
                            <i className="material-icons material-icons-outlined ml-15 greenIcon">
                              get_app
                            </i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>

                <div className="setting-section mt-4">
                  <div className="heading">Setting</div>
                  <div className="setting-cont">
                    <div className="ctitle">public group</div>
                    <div className="csubtitle">group admins</div>
                    <div className="row admin-data pt-3">
                      <div className="col-md-3">
                        <Image src={profileImage} roundedCircle />
                        <span className="pl-20">pattrick brown</span>
                      </div>
                      <div className="col-md-3">
                        <Image src={profileImage} roundedCircle />
                        <span className="pl-20">pattrick brown</span>
                      </div>
                      <div className="col-md-3">
                        <Image src={profileImage} roundedCircle />
                        <span className="pl-20">pattrick brown</span>
                      </div>
                    </div>
                    <div className="admin-per pt-3">
                      <div className="item">
                        <Checkbox
                          text={t("add_edit_group_checkbox_member_invite_text")}
                          // value={data.LGP_invite === "y"}
                          handleChange={(e) => {}}
                        />
                      </div>
                      <div className="item">
                        <Checkbox
                          text={t(
                            "add_edit_group_checkbox_member_enter_memberlist_text"
                          )}
                          // value={data.LGP_viewmember === "y"}
                          handleChange={(e) => {}}
                        />
                      </div>
                      <div className="item">
                        <Checkbox
                          text={t(
                            "add_edit_group_checkbox_member_create_posts_text"
                          )}
                          // value={data.LGP_post === "y"}
                          handleChange={(e) => {}}
                        />
                      </div>
                      <div className="item">
                        <Checkbox
                          text={t(
                            "add_edit_group_checkbox_member_enter_confirmation_text"
                          )}
                          // value={data.LGP_confirm === "y"}
                          handleChange={(e) => {}}
                        />
                      </div>

                      <div className="item">
                        <Checkbox
                          text={t(
                            "add_edit_group_checkbox_member_can_share_grp_post"
                          )}
                          // value={data.LGP_shareable === "y"}
                          handleChange={(e) => {}}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ViewGroupStatic;
