import * as Styled from "./styled";

type Props = {
  children: React.ReactNode;
  cutTop?: boolean;
  cutBottom?: boolean;
  className?: string;
};

export const Card = ({ cutTop, cutBottom, children, className }: Props) => {
  return (
    <Styled.Card $cutTop={cutTop} $cutBottom={cutBottom} className={className}>
      {children}
    </Styled.Card>
  );
};
