import React from "react";

import ChatComponent from "./ChatComponent";
import { withRouter } from "components/customNavigate/withRouter";

class Chat extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;

    return <ChatComponent key={this.props.params.id} chattype={"private"} {...this.props} />;
  }
}
// Chat.contextType = MessageContextProvider;
export default withRouter(Chat);

// compose(
//   withTranslation(),
//   connect(mapStateToProps)
// )(withRouter(withScrollHookHOC(Chat)));
//export default withTranslation()(withRouter(Chat));
