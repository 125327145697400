export function returnUrl(item) {

  const params = new URLSearchParams(item.query); 

  switch (item.type) {
    case "Pgrpjoinpending":
      return `/group/${item.postID}/requests`;
    case "Pgrpjoin":
    case "Pgrpadminpostnotify":
    case   "Pgrpjoinaccept":
      return `/group/${item.postID}`;
    case "Pfollow":
      return `/post/${item.postID}`;
    case "Pmail":
        return `/chat/${params.get('userId')}`;
    case "Pcom":
    case "Plike":
      return `/?p=${params.get('postId')}`;
    case "Plikegrp":
    case "Pcomgrp":
        return `/group/${params.get('groupId')}/?p=${params.get('postId')}`;
    default:
      break;
  }
}

export function getParameterByName(name, url = "") {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
