export const HeartOutlineIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 21 19'
    className='fill-current'
    {...props}
  >
    <path
      fill='currentColor'
      d='M14.086 2.063A4.92 4.92 0 0 0 10.5 3.619 4.914 4.914 0 0 0 2 6.977c0 4.892 7.892 9.695 8.234 9.883a.53.53 0 0 0 .548 0C11.108 16.673 19 11.87 19 6.977a4.92 4.92 0 0 0-4.914-4.914ZM10.5 15.779c-1.373-.876-7.437-4.972-7.437-8.802a3.852 3.852 0 0 1 7.001-2.213.53.53 0 0 0 .869 0 3.851 3.851 0 0 1 7.005 2.213c0 3.827-6.065 7.923-7.438 8.802Z'
    />
  </svg>
);
