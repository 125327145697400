import React, { Component, useState, useEffect } from "react";
import { getGroupAbout } from "../../../api/groups";
import IconButton from "../../elements/buttons/IconButton";
import GroupPortal from "../GroupPortal";
import { Card, Form, Tab, Tabs, Toast } from "react-bootstrap";
import IconButton2 from "@mui/material/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { withTranslation } from "react-i18next";
import BackButton from "../../commons/BackButton";
import Users from "./InviteUsers";
import ShareLink from "./ShareLink";
import './invitegroup.scss'
import { withRouter } from "../../customNavigate/withRouter";
import ActionBar from "components/actionBar";


class InviteToGroup extends Component {
  state = {
    data: {},
    groupId: null,
    showGroupPortal: false,
    showToast: false,
    key: "SHARE_LINK",
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({ groupId: this.props.match.params.id });
    this.callGetGroupDataAPI();
  }

  callGetGroupDataAPI() {
    getGroupAbout(this.props.token, this.props.match.params.id).then((res) => {
      console.log(res.data);
      res.data.OK
        ? this.setState({ data: res.data.OK, loading: false })
        : this.setState({ loading: false });
    });
  }

  handleTabSelect(param) {
    this.setState({
      key: param,
    });
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="container">
          {/* <div class="back-btn-cont">
            <BackButton />
          </div> */}
          <div className="main-content">
            <div className="main-content-narrow">
              <ActionBar
                  leftIcon="arrow_back"
                  onLeftClick={()=> this.props.history.goBack() }
                  centerComponent={<></>}
                />
              <Card className="">
                <Card.Body>
                  <Tabs 
                    justify
                    id="controlled-tab-example"
                    activeKey={this.state.key}
                    onSelect={(k) => this.handleTabSelect(k)}
                    unmountOnExit={true} 
                    className="tab-content"
                  >
                    <Tab
                      eventKey="SHARE_LINK"
                      title={t("share_link")}
                      tabClassName=" text-uppercase"
                    >
                      <ShareLink {...this.props}/>
                      {/* <EditGroupComponent {...props} /> */}
                    </Tab>
                    <Tab
                      eventKey="LOCCI_USERS"
                      title={t("locci_users")}
                      tabClassName=" text-uppercase"
                    >
                      {/* <MyGroups {...props} /> */}
                      <Users {...this.props} />
                    </Tab>
                    
                    <Tab
                      eventKey="PROMOCODE"
                      title={t("enter_group_screen_title_tab_promocode")}
                      tabClassName=" text-uppercase"
                      // tabClassName="col-lg-4"
                    >
                      <div className="main-content code-users-cont">
                        <div className="main-content-narrow whiteBackground">
                          <div className="invite-group">
                            <h4 className="pt-2 pb-2">{t("invite_people_title_promo")}</h4>

                            <div className="inviteContainer">
                              <Form className="form">
                                <Form.Group size="lg" controlId="groupName">
                                  <Form.Control
                                    readOnly
                                    type="text"
                                    placeholder={t("invite_people_promo")}
                                    value={this.state.data?.LGP_promo}
                                  />
                                  <Form.Text className="text-muted">
                                    {t("invite_people_promo_copy")}
                                  </Form.Text>
                                </Form.Group>
                              </Form>
                              <div className="copy">
                                <IconButton2
                                  aria-label="copy"
                                  disabled={!this.state.data.LGP_promo}
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      this.state.data.LGP_promo
                                    );
                                    this.setState({ showToast: true });
                                  }}
                                >
                                  <FileCopyIcon style={{ color: "var(--primary-color)" }} />
                                </IconButton2>
                              </div>
                            </div>
                          </div>
                          <Toast
                            style={{
                              position: "absolute",
                              right: 20,
                              bottom: 60,
                            }}
                            onClose={() => this.setState({ showToast: false })}
                            show={this.state.showToast}
                            delay={2000}
                            autohide
                          >
                            <Toast.Body>
                              {t("invite_people_promo_copied")}
                            </Toast.Body>
                          </Toast>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(InviteToGroup));
