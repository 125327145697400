
import React, { useState } from "react"
import { Button, Col, Row, Toast } from "react-bootstrap"

const NotificationModal=(props)=> {
    const [show, setShow] = useState(true)
    return (
        <>
     <div className="notificationToaster" onClick={props.onNotificationClick}>

        <Toast onClose={() => setShow(false)} show={show} delay={3000} animation style={{
          position: 'fixed',
          top: 80,
        }}>
          <Toast.Header></Toast.Header>
          <Toast.Body>
            <h6>{props?.title}</h6>
            <p>{props?.body}</p>
          </Toast.Body>
        </Toast>
        </div>
   
        </>
    )
}

export default NotificationModal