import styled, { css } from "styled-components";

type Props = {
  loading?: boolean;
};

export const DotFlashing = ({ loading }: Props) => {
  return <StyledDotFlashing $isLoading={loading} />;
};

const StyledDotFlashing = styled.div<{ $isLoading?: boolean }>`
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.secondary};
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  margin: 10px auto;
  display: none;
  ${(props) =>
    props.$isLoading &&
    css`
      display: block;
    `}

  @keyframes dotFlashing {
    0% {
      background-color: ${(props) => props.theme.colors.primary};
    }
    50%,
    100% {
      background-color: ${(props) => props.theme.colors.secondary};
    }
  }

  &:before,
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  &:before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.secondary};
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }
  &:after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.secondary};
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }
`;
