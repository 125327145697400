import styled, { css } from "styled-components";

export const Card = styled.article<{ $cutTop?: boolean; $cutBottom?: boolean }>`
  background-color: #fff;
  padding: 28px 20px 1rem;
  margin-bottom: 5px;
  border-radius: 8px;
  border: 1px solid #eeeeee;

  ${(props) =>
    props.$cutBottom &&
    css`
      border-radius: 8px 8px 0px 0px;
      padding-bottom: 0;
      margin-bottom: 0;
      @media ${({ theme }) => theme.mediaQueries.phone} {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    `}

  ${(props) =>
    props.$cutTop &&
    css`
      border-radius: 0px 0px 8px 8px;
    `}
`;
