import React from "react";
import IconLink from "../buttons/IconLink";
import Button from "../buttons/Button";
import "./UserCard.scss";

function UserData({ item, showOnlyTimestamp, timestampFieldName }) {
  return (
    <span>
      {item.Posts && !showOnlyTimestamp ? item.Posts + " posts, " : ""}
      {item.USE_plz && !showOnlyTimestamp
        ? `${item.USE_plz}  ${item.CIT_name?item.CIT_name:''}  ${item.STA_name?item.STA_name:''}`
        : ""}
      {item.requested ? " " + item[timestampFieldName] : ""}
    </span>
  );
}
export default UserData;
