import { API_BASE_URL, API_BASE_HEADERS, FILE_UPLOAD_URL } from "../global";

import axios from "./AxiosProvider"


export function getInbox(token) { 

  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      Lang: "en",
      action: 2,
    },
  });
}

export function getUsersWithGroups(token,search,page,limit) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      Lang: "en",
      action: '29B',
      search:search,
      page:page,
      limit:limit
    },
  });
}

export function getMessagesWithUser(token, userId, offset, search = "") {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: 46,
      FK_userID: userId,
      offset: offset,
      search: search,
    },
  });
}





export function sendMessage(token, userId, message) {
  const postData = new FormData();

  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("action", 1);
  postData.append("Lang", "en");
  postData.append("MU", userId);
  postData.append("Mmess", message); 

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export function createChatGroup(token, name, userId , file) {
  const postData = new FormData();
  
  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("CAR_avatar", file?.file);
  postData.append("filename", file?.file?.name);
  postData.append("Content-Type", file?.type);
  postData.append("MES_group_name", name);
  postData.append("MES_user_ids", userId);
  postData.append("action", "create_group");

  return axios.post(FILE_UPLOAD_URL, postData, { headers: API_BASE_HEADERS });
}

export function updateChatGroupAvatar(token, roomId, avatar) {
  console.log({avatar});
  const postData = new FormData();
  
  postData.append("action", "update_group_avatar");
  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("LCM_room_id", roomId);
  postData.append("ROOM_avatar", avatar[0].file);
  

  return axios.post(FILE_UPLOAD_URL, postData, { headers: API_BASE_HEADERS });
}




export function setMessageRead(token, messageId) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: 3,
      m: messageId,
    },
  });
}
export function deleteMessageApi(token, messageId) {
  const postData = new FormData();

  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("action", 53);
  postData.append("Lang", "en");
  postData.append("mId", messageId);

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export function uploadAttachmentOfChat(token,messageId,roomId,file,type, thumbnail, audioTime) {
  let lang=localStorage.getItem('i18nextLng')
  const postData = new FormData();
  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("action", "A");
  postData.append("LCM_room_id",roomId);
  postData.append("loci_messID", messageId);
  postData.append("CTA_properties", type==="p" || type==="v"?'': type==="a"?audioTime:file.size);
  postData.append("fthumbnail[]", thumbnail?thumbnail:'')
  postData.append("atype", type);
  postData.append("LC_image[]", file);
  postData.append("Lang", lang);


  return axios.post(FILE_UPLOAD_URL, postData, { headers: API_BASE_HEADERS });
}
