import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { determineLanguageAPI } from "../../i18n";

export const OverUs = (props) => {
  const { t } = useTranslation();
  const [link, setLink] = useState("/overus_en");

  useEffect(() => {
    if (i18n.language !== "en-GB") {
      const language = i18n.language.split("-")[0];

      setLink("/overus_"+ language);

    }
  }, [i18n.language]);

  return (
    <div className="main-content2 gdpr">
      <div className="main-content-narrow">
        <div className="html-content">
          <iframe
            src={link}
            frameBorder={0}
            width={"100%"}
            className={"iframe"}
          />
        </div>
      </div>
    </div>
  );
};

export default OverUs;
