import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Webcam from "webcam-easy";
import IconButton from "../../elements/buttons/IconButton";

import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import Button from "../../elements/buttons/Button";
import { useTranslation } from "react-i18next";

const PhotoClicker = (props) => {
  const { t, i18n } = useTranslation();

  const [image, setImage] = useState(null);
  const [imageData, setImageData] = useState([]);

  const [caption, setCaption] = useState("");

  const onSnap = (dataUri) => {
    // const data = {
    //   fname: "cameraphoto.png",
    //   ftype: "png",
    //   atype: "p",
    //   fproperties: "",
    //   fdata: dataUri.split(",")[1],
    //   fthumbnail: '',
    // };

    // imageData.push(data);

    function urltoFile(data, filename, mimeType) {
      return fetch(data)
        .then(function (res) {
          return res.arrayBuffer();
        })
        .then(function (buf) {
          return new File([buf], filename, { type: mimeType });
        });
    }

    //Usage example:
    urltoFile(dataUri, " photo.png", "image/png").then(function (file) {
      // console.log(file);
      let myfile = {
        file: file,
        type: "p",
      };

      imageData.push(myfile);
    });

    setImage(dataUri);
  };

  const onSendClick = () => {
    // console.log("id", imageData);

    props.onSendClick(imageData, caption);
  };

  return (
    <>
      <Modal
        centered
        show
        onHide={props.closeModal}
        className="custom-modal attachment"
      >
        <IconButton
          clear="true"
          className="closeIcon"
          icon="close"
          handleClick={props.closeModal}
        />

        {image !== null ? (
          <img src={image} />
        ) : (
          <div style={{ maxWidth: "100%", maxHeight: "100%" }}>
            {/*  */}
            <Camera
              idealResolution={{ width: 500 }}
              onTakePhotoAnimationDone={onSnap}
              isFullscreen={false}
            />
          </div>
        )}

        {image !== null && (
          <div>
            <div className="caption p-10">
              <Form>
                <Form.Group size="lg" controlId="topic">
                  <Form.Control
                    autoFocus
                    type="text"
                    placeholder={t("add_caption_title")}
                    value={caption}
                    onChange={(e) => setCaption(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </div>

            <div className="report-actions m-20">
              <Button
                clear="true"
                text={t("cancel_alert")}
                handleClick={props.closeModal}
              />
              <Button
                clear="true"
                text={t("send_button")}
                handleClick={onSendClick}
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default PhotoClicker;
