import React, { ReactNode } from "react";
import * as Styled from "./style";
import SearchInput from "./SearchInput";
import { CSSProperties } from "styled-components";

interface ActionBarProps {
    leftComponent?: ReactNode;
    leftIcon?: string;
    onLeftClick?: () => void;
    centerComponent?: ReactNode;
    rightComponent?: ReactNode;
    rightIcon?: string;
    onRightClick?: () => void;
    sticky?: boolean;
    hideSearch?: boolean;
    style?: CSSProperties;
    containerStyle?: CSSProperties;
}

const ActionBar: React.FC<ActionBarProps> = ({
    leftComponent,
    leftIcon,
    onLeftClick,
    rightComponent,
    centerComponent,
    rightIcon,
    onRightClick,
    style,
    containerStyle,
    sticky = true,
}) => {

    return (
        <Styled.Wrapper $sticky={sticky} style={style} >
            <Styled.Container style={containerStyle} >
                <Styled.LeftComponentWrapper>
                {leftComponent ? (
                    <>{leftComponent}</>
                ) : leftIcon ? (
                <Styled.ButtonIcon $contained onClick={onLeftClick}>
                    <i className="material-icons">{leftIcon}</i>
                </Styled.ButtonIcon>
                ) : <></>}
                </Styled.LeftComponentWrapper>
                <Styled.CenterComponent>
                    {centerComponent ? <>{centerComponent}</> : <SearchInput/>}
                </Styled.CenterComponent>
                <Styled.RightComponentWrapper>
                    {rightComponent ? (
                        <>{rightComponent}</>
                    ) : rightIcon ? (
                    <Styled.ButtonIcon onClick={onRightClick}>
                        <i className="material-icons">{rightIcon}</i>
                    </Styled.ButtonIcon>
                    ) : <></>}
                </Styled.RightComponentWrapper>
            </Styled.Container>
        </Styled.Wrapper>
    );
};
  
export default ActionBar;