import React, { useEffect, useState } from "react";
import IconButton from "../../elements/buttons/IconButton";
import { Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./manageParticipants.scss";
import GlobalConfirmDialogue from "../../commons/GlobalConfirmDialogue";
import UserDetails from "../../user-details";



const AllPaticipantsModal = (props) => {
  const { t, i18n } = useTranslation();
  const [remove, setRemove] = useState(false);
  const [removeID, setRemoveID] = useState("");
  const [removeName, setRemoveName] = useState("");
  const [showUserDetails, setShowUserDetails] = useState(false)
  const [userIdForModal, setUserIdForModal] = useState("")
  

  const [allParticipants, setAllParticipants] = useState(
    props?.participantData || []
  );
  // const [allParticipantsII, setAllParticipantsII] = useState(props?.participantData);

  useEffect(()=>{
    setAllParticipants(props?.participantData)
  },[props?.participantData])

  const deleleParty = (id , name) => {
    setRemove(true);
    setRemoveID(id);
    setRemoveName(name)
  };

  const handleDeleteParticipants = () => {
    setRemove(false);
    props?.deleteParticipants(removeID);
  };

  const searchByName = (value) => {
    let data = props?.participantData?.filter((dt) => {
      if (
        (
          dt?.USE_firstname?.toLowerCase() +
          " " +
          dt?.USE_surename?.toLowerCase()
        )?.includes(value?.toLowerCase())
      ) {
        return dt;
      }
    });
    setAllParticipants(data);
  };

  console.log(props);

  const ShowUserDetails = (id) =>{
    setUserIdForModal(id)
    setShowUserDetails(true)
  }
 
  return (
    <>
      <Modal
        centered
        show={props.show}
        onHide={() => props.closeModal()}
        backdrop="static"
        contentClassName="participants-list-popup"
      >
        <Modal.Body>
          <IconButton
            clear="true"
            className="icon-mr-4 close-modal"
            icon="close"
            handleClick={() => props.closeModal()}
          />

          <div className="manageParticipants">
            <div className="participantsheading">{t("participants")}</div>

            <div className="search">
              <input
                className="form-control"
                type="text"
                placeholder={t("enter_search_text")}
                aria-label="Search"
                //  value={searchText}
                autoFocus
                onChange={(e) => searchByName(e.target.value)}
                // onKeyUp={() => this.searchData()}
              />
            </div>
            {
              <div className="participantsList">
                {props?.loader ? (
                  <div className="sending-overlay">
                    <div class="loader">
                      <Spinner
                        animation="border"
                        role="status"
                        color="var(--primary-color)"
                      ></Spinner>
                    </div>
                  </div>
                ) : (
                  allParticipants?.map((item) => (
                    
                    <div className="participantsBox">
                    {console.log(allParticipants)}
                      <img onClick={()=>ShowUserDetails(item?.userID)}
                      style={{cursor:'pointer'}}
                        src={
                          item?.USE_pic ? item?.USE_pic : "/images/profile.png"
                        }
                      />
                      <div className="admin">
                        <span style={{cursor:'pointer'}} onClick={()=>ShowUserDetails(item?.userID)} className="participantName">
                          {" "}
                          {item?.USE_firstname ? item?.USE_firstname + " " : ""}
                          {item?.USE_surename ? item?.USE_surename : ""}
                        </span>
                        <p>
                          {item?.is_admin && t("admin")}
                          {item?.is_admin && item?.is_me && " | "}
                          {item?.is_me && t("me")}
                        </p>
                      </div>

                      {!item?.is_admin && props?.isAdmin && (
                        <IconButton
                          icon="close"
                          clear={true}
                          className="float-right"
                          handleClick={() =>
                            deleleParty(
                              item?.userID,
                              item?.USE_firstname + " " + item?.USE_surename
                            )
                          }
                        />
                      )}
                    </div>
                  ))
                )}
              </div>
            }
          </div>
          {showUserDetails && (
          <UserDetails
            id={userIdForModal}
            {...props}
            // sourcePage="chat"
            handleClose={() =>
              setShowUserDetails(false)
            }
          />
        )}
          {remove && (
            <GlobalConfirmDialogue
              show={remove}
              dynamicContent={true}
              title={"remove_participant"}
              content={"remove_participant_message"}
              secondContent={"from_the_group_chat"}
              userName={removeName}
              name={props.name}
              closeModal={() => setRemove(false)}
              onSubmit={() => handleDeleteParticipants()}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AllPaticipantsModal;
