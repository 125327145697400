import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import { Link, useNavigate } from "react-router-dom";
import { getGroupAdmins } from "../../../api/common";
import IconButton from "../../elements/buttons/IconButton";
import UserCard from "../../elements/cards/UserCard";
import Loader from "../../posts/single-post/PostLoader/postLoader.js";

import "./chatmodal.scss";
import { getUsersWithGroups } from "../../../api/chat";
const ForwardGroupChatModal = (props) => {
  // const [showModal, setShowModal] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [renderFirstTime, setRenderFirsttime] = useState(false)
  const [loading, setLoading] = useState(true);
  const [scroolLoading, setScrollLoading] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false);
  const page = useRef(1); // Use useRef to keep track of the current page
  const myRef = useRef(null) 
  const searchRef = useRef(null)
  // const getUsers=()=> {
  //     let list=localStorage.getItem("ga");
  //     let data= JSON.parse(list)
  //     if(data) {
  //       return data
  //     }
  //     else {
  //       return []
  //     }
  // }
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    searchRef.current=searchText
    setSearchLoading(true)
    getForwardToList(); 
    const divElement = myRef.current; 

    if(divElement){
    myRef.current.scrollTop=0 
    }
  }, [searchText]);


  const clickOnCard = (data) => {
    let id = data?.CAR_type === "g" ? data?.roomID : data?.userID
    let navigateTo = data?.CAR_type === "g" ? '/group-chat/' : '/chat/'

    console.log(data, { id });
    props.handleForword(id, navigateTo);
  };


  const getForwardToList = () => { 
    getUsersWithGroups(props.token, searchRef.current, page.current, 20).then((res) => {
      if (res.data.row) {
        const data = res.data.row;
        let modifiedData;
        let userDetails = JSON.parse(localStorage.getItem("profile"));
        if (userDetails) {
          modifiedData = data.filter(
            (me) => me.FK_userID !== userDetails.userId
          );
        } 
        if(page.current != 1){
        setFilteredUsers((prevData) => [...prevData, ...modifiedData]) 
        }else{
          setFilteredUsers([...modifiedData])
        }
      }
    }).finally(() => {
      setLoading(false)
      setSearchLoading(false)
      setScrollLoading(false)
    });
  }

  const handleScroll = async () => {
    // Check if the user has scrolled to the bottom of the page 
    try {

      if (
        myRef.current.clientHeight + myRef.current.scrollTop + 1 >
        myRef.current.scrollHeight
      ) { 
        page.current += 1; // Increment the page for the next API call
        setScrollLoading(true)
        getForwardToList(); // Call fetchData when the user reaches the bottom
      }
    } catch (error) {
      console.log(error, "ids");
    }

  };

  useEffect(() => {
    // Attach the scroll event listener when the component mounts
    // console.log(window.innerHeight, document.documentElement.scrollTop, document.documentElement.scrollHeight, "ids");

    const divElement = myRef.current;

    if (divElement) {
      console.log("ids");
      divElement.addEventListener('scroll', handleScroll);
      console.log(myRef, "ids");
    }

    // Remove the event listener when the component unmounts
    return () => {
      if (divElement) {
        divElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [myRef.current]);

  return (
    <>
      <Modal
        centered
        show
        onHide={props.handleClose}
        className="custom-modal"
        contentClassName="mheight"
      >
        {loading ? (
          <Loader />
        ) : (
          <div style={{ position: 'relative' }}>
            <IconButton
              clear="true"
              className="closeIcon"
              icon="close"
              handleClick={props.handleClose}
            />
            {/* <ChatUserScreen type={props.type} token={props.token}  handleClose={()=>setShowModal(false)}/> */}
            <div className="search fixed">
              <input
                className="form-control"
                type="text"
                placeholder="Search"
                aria-label="Search"
                value={searchText}
                autoFocus
                onChange={(e) =>{setSearchText(e.target.value);
                                            page.current=1 ;}}

              // onKeyUp={() => this.searchData()}
              />
              {/* { searchLoading&&  <div className="loading">
              <Spinner animation="border"  size="sm"/>
              </div>} */}
            </div>

            <div className="inbox view-group" ref={myRef}  >
              <div className="main-content-narrow"  >
                {filteredUsers &&
                  filteredUsers.map((x, i) => (
                    <span key={i} className="decoration-none">
                      <UserCard
                        item={x}
                        // className={x.LCM_read === "y" ? "read" : "unread"}
                        className="cursor"
                        handleClick={() => clickOnCard(x)}
                      ></UserCard>

                    </span>
                  ))}
              </div>

              
            </div>

            {scroolLoading && (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '20px', position: 'absolute', bottom: '0px  ' }}
                ><Spinner /></div>)}


          </div>
        )}
      </Modal>
    </>
  );
};

export default ForwardGroupChatModal;
