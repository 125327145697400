export const LinkArrowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 7 11'
    className='fill-current'
    {...props}
  >
    <path
      fill='currentColor'
      d='M6.909 5.547q-.006.357-.324.65l-5.09 4.495a1 1 0 0 1-.283.172 1 1 0 0 1-.333.05.9.9 0 0 1-.44-.116.85.85 0 0 1-.315-.296.76.76 0 0 1-.11-.414.8.8 0 0 1 .277-.59l4.838-4.256-.009.552L.426 1.41A.8.8 0 0 1 .169.813.74.74 0 0 1 .293.408.84.84 0 0 1 .617.116a.9.9 0 0 1 .445-.103q.357.005.601.245l4.944 4.629q.155.147.23.313.068.159.072.347'
    />
  </svg>
);
