import React, { Component, useState } from "react";
import "./ReportPost.scss";
import Button from "../../elements/buttons/Button";
import { Form, Modal } from "react-bootstrap";
import { withTranslation } from 'react-i18next';


class ReportPost extends Component {

  state = {
    message: "",
    content: "",
 
  }

  componentDidMount(){
    
    
  }


  submitForm = (e) => {
    e.preventDefault();
    
    this.props.reportPost(this.state.content);

  }
  render() {
    const { t } = this.props;
    return (
      <>
        <Modal centered show={this.props.reporting} onHide={this.props.handleClose}>

          <Modal.Body>

            <h2>{this.props.isReportingUser?t('report_user'):t('report_post')}</h2>
            {
              !this.props.isReported ? 
              <>
                <p>{this.props.isReportingUser?t('report_user_text'):t('report_post_text')}</p>
                            

                <Form className="newPostForm" onSubmit={this.submitForm}>
                  <Form.Group size="lg" className="form-group" controlId="topic">
                    <Form.Label>{t('add_edit_post_description')}</Form.Label>
                    <Form.Control
                      autoFocus
                      required
                      as="textarea"
                      rows={8}
                      value={this.state.content}
                      onChange={(e) => this.setState({content: e.target.value})}
                    />
                  </Form.Group>
                
                <div className="report-actions">
                  <Button clear="true" text={t('cancel_alert')} handleClick={this.props.handleClose}/>
                  <Button text={t('report_post_button')} type="submit" />
                </div>
                  
                
                </Form>
              </>
                :
                <p>

                  
                {this.props.isReportingUser?t("report_user_success_msg"):t("report_success_msg")}
                </p>
            }
           
          </Modal.Body>

        </Modal>
      </>
  )
  }
  
}

export default withTranslation()(ReportPost);