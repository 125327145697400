import React, { useEffect, useState } from 'react';
import HtmlParser from 'html-react-parser';
import "./Gdpr.scss";
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import { determineLanguageAPI } from '../../i18n';


export const Gdpr = (props) => {
    const { t,i18n } = useTranslation();

    const [link, setLink] = useState();
    const [content, setContent] = useState();
    const [title, setTitle] = useState('');

    useEffect(() => {
      console.log('lab',i18n.language);

        switch (props.routeData.title) {
        
            case "Terms and Conditions":
              if(i18n.language!=='en-GB')
              {
                const USElanguage=i18n.language.split('-')[0]
               
                setLink("/agb_"+USElanguage);
              }
              else
              {
                setLink("/agb_en");  
              }
              setTitle(i18n.t('terms_and_conditions'));
                break;
            case "Privacy Policy":
              if(i18n.language!=='en-GB')
              {
                const USElanguage=i18n.language.split('-')[0]
               
                setLink("/privacy_"+USElanguage);
              }
              else
              {
                setLink("/privacy_en");  
              }
              setTitle(i18n.t('privacyPolicy'))
                break;
            case "Imprint":
              if(i18n.language!=='en-GB')
              {
                const USElanguage=i18n.language.split('-')[0]
	        setLink("/imprint_"+USElanguage);
              }
              else
              {
                setLink("/imprint_en");
              }

              setTitle(i18n.t('imprint_title_primary'))   
                break;
            default:
                break;
        }

    }, [i18n.language, props.routeData.title])
    return (
        <div className="main-content gdpr">
            <div className="main-content-narrow">
		{props.routeData.title == 'Imprint' &&
	                <h1>{t('imprint_title_primary')}</h1>
		}

                {props.routeData.title == 'Privacy Policy' &&
                        <h1>{t('privacyPolicy')}</h1>
                }

                {props.routeData.title == 'Terms and Conditions' &&
                        <h1>{t('terms_and_conditions')}</h1>
                }
		    
                {content &&
                    <div className="html-content">
                        {HtmlParser(content)}
                    </div>
                }

                {link &&
                    <div className="html-content" >
                        <iframe src={link} frameBorder={0} width={"100%"} className={"iframe"} />
                    </div>
                }
            </div>

        </div>
    )
}

export default Gdpr;
