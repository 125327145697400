import React, { Component, useState } from "react";
import "../report-post/ReportPost.scss";
import Button from "../../elements/buttons/Button";
import { Form, Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";

class DeletePost extends Component {
  state = {
    message: "",
    content: "",
  };

  componentDidMount() {}

  submitForm = (e) => {
    e.preventDefault();

    this.props.deletePost(this.props.postID);
    this.props.handleClose();
  };
  render() {
    const { t } = this.props;
    return (
      <>
        <Modal
          centered
          show={this.props.deleting}
          onHide={this.props.handleClose}
        >
          <Modal.Body>
            <h2>{t("delete_post_title")}</h2>
            {!this.props.isDeleted ? (
              <>
                <p>{t("Are_you_sure_want_to_delete")}</p>

                <div className="report-actions">
                  <Button
                    clear="true"
                    text={t("cancel_alert")}
                    handleClick={this.props.handleClose}
                  />
                  <Button text={t("delete")} handleClick={this.submitForm} />
                </div>
              </>
            ) : (
              <p>Thank you, the post has been reported.</p>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(DeletePost);
