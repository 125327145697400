import React, { createContext, useEffect, useRef, useState } from "react";
import {SOCKET_URL} from '../../global';
import EVENT_ENUM from '../utils/ChatEvents';
import { useAuthState } from "./authContext";
const SocketContext = createContext();

export const useSocketContext = () => React.useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const userDetails = useAuthState();
  const webScoketRef = useRef(null);
  const [isConnecting, setIsConnecting] = useState(false);


  const setWebSocketConnection = (socket) => { 
    webScoketRef.current = socket;
  };

  const initializeWebSocket = () => {
    if (!webScoketRef.current && !isConnecting) {
      setIsConnecting(true);
      const socket = new WebSocket(SOCKET_URL + userDetails?.token);

      socket.onopen = () => {
        console.log("WebSocket connection opened");
        webScoketRef.current = socket;
        setIsConnecting(false);
      };

      socket.onclose = () => {
        console.log("WebSocket connection closed");
        webScoketRef.current = null;
        setIsConnecting(false);
      };

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
        webScoketRef.current = null;
        setIsConnecting(false);
      };
    }
  };

  
  // Cleanup the WebSocket connection when the component unmounts or token changes
  useEffect(() => {
    initializeWebSocket();

    return () => {
      if (webScoketRef.current) {
        webScoketRef.current.close();
      }
    };
  }, [userDetails?.token]);

  const contextValue = { 
    webScoketRef, 
    setWebSocketConnection,
    initializeWebSocket,
    isConnecting,
  };

  return (
    <SocketContext.Provider value={contextValue}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketContext;
