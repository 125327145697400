import React, { Component, useState, useEffect } from "react";
import "./GroupMembers.scss";
import { getGroupMembers, removeMemberFromGroup } from "../../api/groups";
import IconLink from "../elements/buttons/IconLink";
import Button from "../elements/buttons/Button";
import UserCard from "../elements/cards/UserCard";
import UserData from "../elements/cards/UserData";
import { withRouter } from "../customNavigate/withRouter";
import IconButton from "../elements/buttons/IconButton";
import GroupPortal from "./GroupPortal";
import { useAuthState } from "lib/context/authContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BackButton from "../commons/BackButton";
import UserDetails from "../user-details";
import { deleteUserFromFavorites, getFavorites } from "../../api/user";
import { useAppSelector } from "lib/store";
import * as GlobalStore from 'lib/store/global';
import GroupMemberRemoveConfirmation from "./groupMember-remove-Modal/groupMember-remove-modal";
import ActionBar from "components/actionBar";

function GroupMembers({ ...props }) {
  const { t, i18n } = useTranslation();
  const history = useNavigate();
  const userDetails = useAuthState();
  const [groupId, setGroupId] = useState();
  const [members, setMembers] = useState([]);
  const [showRemoveConfirmModal,setShowRemoveConfirmModal] = useState(false) 
  const [removeMemberDetails,setRemoveMemberDetails] = useState({})
  const [groupPerms, setGroupPerms] = useState({
    isAdmin: false,
    isSuperAdmin: false,
  });
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [userId, setUserId] = useState(null);
  const [followers, setFolowers] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const queryState = useAppSelector(GlobalStore.selectQuery);
  const [query, setQuery] = useState("");

  useEffect(() => {
    // console.log(props);
    setGroupId(props.match.params.id);
    getData();
  }, []);

  useEffect(() => {
    if (queryState !== query) setQuery(queryState);
    getData();
  }, [queryState]);

  const getData = () => {
    getGroupMembers(props?.token, props?.match.params.id, queryState).then(
      (res) => {
        if (res.data.OK) {
          setMembers(res.data.members);
          setGroupPerms({
            isAdmin: res.data.admin == "1",
            isSuperAdmin: res.data.superAdmin == "1",
          });
        } else {
         history("/groups");
        }
      }
    );
  };
  const deleteFavorite = (id) => {
    deleteUserFromFavorites(props.token, id).then((res) => {
      if (res.data.OK) {
        getData();
      }
    });
  };

  const clickOnCard = (userId, type) => {
    switch (type) {
      case "profile":
        setUserId(userId);
        return setShowUserDetails(true);
      case "delete":
        return deleteMember(userId);
      default:
       history(`/chat/${userId}`);
    }
  };

  const deleteMember=(userId)=> {
    removeMemberFromGroup(
      props.token,
      groupId,
      userId
    ).then((res) => getData())
  }

  return (
    <>
      <div className="main-content">
        <div className="main-content-narrow">
          <ActionBar 
            leftIcon="arrow_back"
            onLeftClick={()=>history(-1)}
          />
          <div className={"user-card "}>
            <div className="user-card-content">
              {t("group_members_screen_title_primary")}
            </div>
          </div>

          {members.length > 0 ? (
            members.map((item, i) => (
              <UserCard
                key={i}
                item={item}
                dataChild={<UserData item={item} />}
                handleClick={() => clickOnCard(item.FK_userID, "profile")}
                className="cursor"
              >
                {userDetails.profile.userId != item.FK_userID && (
                  <div className={"memberActionBtnsHolder"}>
                    <Button
                        clear={true}
                        text={t("plus_message_button_title")}
                        handleClick={(e) => {
                          clickOnCard(item.FK_userID, "message");
                          e.stopPropagation();
                        }}
                      />
                    {item.admin == "0" &&
                      item.superAdmin == "0" &&
                      (groupPerms.isAdmin || groupPerms.isSuperAdmin) && (
                        <Button
                          clear={true}
                          text={t(
                            "group_members_screen_remove_member_button_text"
                          )}
                          handleClick={(e) => { 
                            setRemoveMemberDetails({memberId:item.FK_userID,name:item.USE_firstname +item.USE_surename}) 
                            setShowRemoveConfirmModal(true)
                            e.stopPropagation();
                          }}
                        />
                      )}
                  </div>
                )}
              </UserCard>
            ))
          ) : (
            <p>No members found</p>
          )}
        </div>
      </div> 
       {showRemoveConfirmModal && (
        <GroupMemberRemoveConfirmation  
            show={showRemoveConfirmModal}
            userName={removeMemberDetails?.name} 
            closeModal={() =>  setShowRemoveConfirmModal(false)}
            onSubmit={() => 
              {
              clickOnCard(removeMemberDetails?.memberId, "delete")
              setShowRemoveConfirmModal(false)
              }
            } 
            onChange={(data)=> console.log(data,"gggg")}
          />
        )}

      {showUserDetails && (
        <UserDetails
          id={userId}
          {...props}
          handleClose={() => setShowUserDetails(false)}
        />
      )}
    </>
  );
}

export default withRouter(GroupMembers);
