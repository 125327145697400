import { useAppDispatch } from "lib/store";
import * as GlobalStore from "lib/store/global";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

type Props = {
  children: React.ReactNode;
};

const HistoryListener = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  // Location change listener
  useEffect(() => {
    dispatch(GlobalStore.routeChanged(location.pathname));
  }, [location.pathname]);

  return children;
};

export default HistoryListener;
