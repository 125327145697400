import React, { Component } from "react";
import { getBlacklist, blockUser } from "../../api/user";
import Button from "../elements/buttons/Button";
import "react-datepicker/dist/react-datepicker.css";
import "rc-slider/assets/index.css";
import "./Blacklist.scss";
import UserCard from "../elements/cards/UserCard";
import UserData from "../elements/cards/UserData";
import { mapStateToProps } from "lib/store/mapStateToProps";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import BackButton from "../commons/BackButton";
import UserDetails from "../user-details";

class Blacklist extends Component {
  state = {
    list: [],
    query: "",
    showUserDetails: false,
    userId: null,
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate() {
    if (this.state.query != this.props.query) {
      this.setState({ query: this.props.query });
      this.getData();
    }
  }

  getData() {
    getBlacklist(this.props.token, this.props.query).then((res) => {
      if (res.data.OK) {
        this.setState({ list: res.data.blacklist });
      }
    });
  }

  onCardClick(e, clickedOn, data) {
    e.stopPropagation();
    if (clickedOn === "img") {
      this.setState({
        userId: data.LOB_blocked_userID,
        showUserDetails: true,
      });
    } else {
      this.onUnblockClick(e,data);
    }
  }

  async onUnblockClick(e, item) {
    e.stopPropagation();
    blockUser(this.props.token, item.LOB_blocked_userID, false).then((res) =>
      this.getData()
    );
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="blacklist main-content">
          <div className="main-content-narrow">
            {/* <div className="ctaBack">
              <BackButton />
            </div> */}

            {this.state.list.map((item, i) => (
              <UserCard
                key={i}
                item={item}
                dataChild={<UserData item={item} />}
                className="cursor"
                handleClick={(e, clickedOn) =>
                  this.onCardClick(e, clickedOn, item)
                }
              >
                <Button
                  clear={true}
                  text={t("view_profile_unblock")}
                  handleClick={(e) => this.onUnblockClick(e, item)}
                />
              </UserCard>
            ))}

            {this.state.list.length == 0 && <p>{t("blacklist_empty")}.</p>}
          </div>
        </div>

        {this.state.showUserDetails && (
          <UserDetails
            id={this.state.userId}
            {...this.props}
            showMsgButton={false}
            handleClose={() =>
              this.setState({
                showUserDetails: false,
              })
            }
          />
        )}
      </>
    );
  }
}

export default withTranslation()(connect(mapStateToProps)(Blacklist));
