/* eslint-disable */


import React, { useEffect, useState } from "react";
import {
  Dropdown,
  Spinner,
} from "react-bootstrap";
import { Box, Tooltip, Typography } from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { getMyDefaultGroup, getMyGroups } from "../../api/groups";
import { applyFilterOnPosts } from "../../api/posts";
import { useTranslation } from "react-i18next";
import CustomToggleButtton from "../elements/buttons/CustomToggleButton";
import { useAppDispatch, useAppSelector } from "lib/store";
import * as GlobalStore from "lib/store/global";
import { ButtonIcon, CustomDropdownMenu } from "./style";
import { getProfile } from "api/user";


interface SelectedGroup {
    id: string|number;
    status: string;
}

interface FilterDropdownProps {
    token: string|null;
}

const FilterDropdown = (props: FilterDropdownProps) => {
    const {token} = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [showPostFilter, setShowPostFilter] = useState(false);
    const [loader, setLoader] = useState(false);
    const [defaultGrpId, setDefaultGrpId] = useState(null);
    const [selectedGroups, setSelectedGroups] = useState<SelectedGroup[]>([]);
    const [myGroups, setMyGroups] = useState([]);
    const [isPersonalized, toggleIsPersonalized] = useState(true);
    const [showRegionalFeed, setShowRegionalFeed] = useState(true);
    const publicFeedIcon = useAppSelector(GlobalStore.selectPublicFeedIcon);
    const isPublicPersonalize = useAppSelector(GlobalStore.selectIsPublicPersonalize);

    useEffect(() => {
        if (selectedGroups?.length > 0) {
            setFilterOnPosts();
        };
    }, [selectedGroups]);

    useEffect(() => {
        getMyDefaultGroupId();
    }, [token]);

    const onSelectAllClick = (isSelectall: boolean) => {
        const selected: SelectedGroup[] = [];
        if (!isSelectall) {
          allGroups.forEach((me) => {
            selected.push({ id: me.id, status: "y" });
          });
          toggleIsPersonalized(true);
    
          setSelectedGroups(selected);
        } else {
          allGroups.forEach((me) => {
            selected.push({ id: me.id, status: "n" });
          });
          toggleIsPersonalized(false);
          setSelectedGroups(selected);
        }
    };

    const onToggleItem = (group: any) => {
        const groups = [];

        if (group.UCM_IsPersonalized === true) {
          groups.push({
            id: group.id,
            status: "n",
          });
        } else {
          groups.push({
            id: group.id,
            status: "y",
          });
        }

        setSelectedGroups(groups);
      };

    const onToggleClick = () => {
        if (!showPostFilter){  
          fetchMyGroups();
        }
        setShowPostFilter(!showPostFilter);
        setLoader(false);
    };

    const fetchMyGroups = () => {
        getMyGroups(token).then((res) => {
            if (res.data.OK) {
            let data = res.data.grp ?? [];

            data = data.map((item: any) => {
                item.UCM_IsPersonalized = item.UCM_IsPersonalized === 'y';

                return item;
            });

            setMyGroups(data);
            let show = res.data.show_regional === 'y';
            setShowRegionalFeed(show)
            }
        });
    };

    const getMyProfile = () => {
        getProfile(token).then((res) => {
            let data = res.data?.OK;

            dispatch(GlobalStore.setIsPublicPersonalize(data && data.LCU_IsPublicPersonalize == 'y'))
        });
    }

    const getMyDefaultGroupId = () => {
        getMyDefaultGroup(token).then((res) => {
          if (res.data.OK) {
            const data = res.data.data;
            setDefaultGrpId(data);
          }
        });
    };

    const allGroups = (showRegionalFeed)? [
        {
          name: t("regional_feed_title"),
          id: "public",
          userpic: publicFeedIcon,
          UCM_IsPersonalized: isPublicPersonalize,
        },
        ...myGroups, 
    ] : myGroups;

    const setFilterOnPosts = async () => {  
        try {   
            let gids: string[] = [];
    
            selectedGroups.forEach((me) => { 
                if (me.id!==defaultGrpId) {
                    gids.push(me.id.toString());
                } 
            });   
    
            let params = {
                token: token,
                IsPersonalized:
                selectedGroups?.length === 1
                    ? selectedGroups[0].status
                    : isPersonalized
                    ? "y"
                    : "n",
        
                action: 66,
                GroupId:
                selectedGroups?.length === 1 ? selectedGroups[0].id : gids.join(","),
            };
    
            let response = await applyFilterOnPosts(params);
    
            if (response.data.OK) {
                fetchMyGroups();
                getMyProfile();
                dispatch(GlobalStore.togglePersonalizedAPI());
            }
        } catch (e) {
          console.error(e);
        } finally {
          setLoader(false);
        } 
    };

    return (
        <>
            <Dropdown
                show={showPostFilter}
                onToggle={() => onToggleClick()}
            >
                <Dropdown.Toggle as={ButtonIcon}>
                    <i className="material-icons-outlined">filter_alt</i>
                </Dropdown.Toggle>

                <CustomDropdownMenu>
                {loader && (
                    <div className="filter-overlay">
                    <div className="loader">
                        <Spinner animation="border" role="status" color="var(--primary-color)">
                        <span className="sr-only">{t("loading")}...</span>
                        </Spinner>
                    </div>
                    </div>
                )}
                <Box>
                    <Typography component="div" className="label" id="1234">
                    {t("deselect")}/{t("selectAll")}
                    </Typography>
                    <CustomToggleButtton
                        className=""
                        checked={allGroups.every(
                            (me) => me.UCM_IsPersonalized
                        )}
                        onChange={() => {
                            setLoader(true);
                            onSelectAllClick(
                                allGroups.every((me) => me.UCM_IsPersonalized)
                            );
                        }}
                    />
                </Box>

                {allGroups.length > 0 &&
                    allGroups.map((group) => (
                    <Box key={group.id}>
                        <Typography component="div" className="left">
                        <img
                            src={
                                group.userpic ? group.userpic : "/images/profile.png"
                            }
                            alt="icon"
                        />
                        </Typography>
                        <Typography component="div" className="right">
                            <div className="label">{group.name}</div>
                            {parseInt(group.id) === 0 && (
                                <Tooltip
                                    title={t("feed_setting_public")}
                                    placement="bottom-end"
                                    arrow
                                >
                                <HelpOutlineOutlinedIcon />
                                </Tooltip>
                            )}

                            {group.id == defaultGrpId && (
                                <Tooltip
                                    title={t("feed_setting_locci")}
                                    placement="bottom-end"
                                    arrow
                                >
                                <HelpOutlineOutlinedIcon />
                                </Tooltip>
                            )}

                            <CustomToggleButtton
                                checked={
                                group.id === defaultGrpId 
                                    ? true
                                    : group.UCM_IsPersonalized
                                    ? true
                                    : false
                                }
                                onChange={() =>
                                group.id === defaultGrpId 
                                    ? null
                                    : onToggleItem(group)
                                }
                                className={group.id === defaultGrpId && "visiblehidden"}
                            />
                        </Typography>
                    </Box>
                    ))}
                </CustomDropdownMenu>
            </Dropdown>
        </>
    );
};

export default FilterDropdown;