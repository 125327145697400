import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { changePassword } from "../../api/user";
import Button from "../elements/buttons/Button";
import { useTranslation } from "react-i18next";
import IconButton from "../elements/buttons/IconButton";
import { logoutUser } from "lib/context/authActions";
import { validatePassword } from "lib/utils/Commons";
import { useNavigate } from "react-router-dom";
import { useAuthDispatch } from "lib/context/authContext";

function ChangePassword(props) {
  const history = useNavigate();
  const { t, i18n } = useTranslation();
  const [message, setMessage] = useState("");
  const [newPass, setNewPass] = useState("");
  const [newPass2, setNewPass2] = useState("");
  const [edit, setEdit] = useState(false);
  const authDispatch = useAuthDispatch();
  const [showResponseModal, setResponseModal] = useState(false);

  function changePasswordAction() {
    if(!validatePassword(newPass)) {
      setMessage('not_valid_password');
      setResponseModal(true);
    }
    else {

    changePassword(props.token, newPass, newPass2).then((res) => {
      if (res.data.OK) {
        setNewPass("");
        setNewPass2("");
        setMessage("change_pass_confirmation");
        setResponseModal(true);
        setTimeoutCall()

      } else {
        if (res.data.ER) {
          setMessage(res.data.ER);

          // setMessage(
          //   res.data.ER.split(" ")
          //     .join("_")
          //     .substring(0, res.data.ER.length - 1)
          // );
          setResponseModal(true);
          }
      }
    });
  }

  function setTimeoutCall(){
    setTimeout(callLogOut, 2000);
  }

  const callLogOut = async () =>{
    try {
    await logoutUser(authDispatch)
        .then((res) => {
         history("/login");
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }


  }
  return (
    <>
      {!edit ? (
        <Button
          text={t("edit_profile_change_pass_title")}
          className="inverted green-border mt-10"
          handleClick={() => setEdit(true)}
        />
      ) : (
        <Modal centered show={true} onHide={() => setEdit(false)}>
          <Modal.Body>
            <Form.Group size="lg" controlId="newpass">
              <Form.Label>{t("edit_profile_change_pass_new")}</Form.Label>
              <Form.Control
                type="password"
                value={newPass}
                onChange={(e) => setNewPass(e.target.value)}
              />
              <Form.Text className="text-muted">
                {t("not_valid_password")}
              </Form.Text>
            </Form.Group> 
            <Form.Group size="lg" controlId="newpass2">
              <Form.Label>
                {t("edit_profile_change_pass_new_confirm")}
              </Form.Label>
              <Form.Control
                type="password"
                value={newPass2}
                onChange={(e) => setNewPass2(e.target.value)}
              />
            </Form.Group>
            <div className="actions mt-30">
              <Button
                text={t("cancel_alert")}
                clear={true}
                handleClick={() => setEdit(false)}
              />

              <Button
                text={t("save")}
                className=""
                handleClick={() => changePasswordAction()}
              />
            </div>

            {/* {message && <span className="formMessage">{message}</span>} */}
          </Modal.Body>
        </Modal>
      )}

      <Modal
        show={showResponseModal}
        onHide={() => setResponseModal(false)}
        className="custom-modal"
      >
        <IconButton
          clear="true"
          className="closeIcon"
          icon="close"
          handleClick={() => setResponseModal(false)}
        />
        {t(message)}
      </Modal>
    </>
  );
}

export default ChangePassword;
