import { PostMedia as IPostMedia, PostMediaType } from "lib/types/postTypes";
import { useState } from "react";
import Lightbox from "../../elements/lightbox/index.js";
import { Media } from "./style";
import SafeImage from "./SafeImage";
 

const PostMedia = (props: { media: IPostMedia[] }) => {
  const [lightboxIndex, setLightboxIndex] = useState<number | null>(null);
  
  return (
    <Media className='mb-3' $size={props.media.length}>
      {props.media.map((media, index) => {
        if (index > 3) return null;
        return (
          <picture key={media.loci_imageID} onClick={() => setLightboxIndex(index)}>
            <SafeImage src={media.thumb || media.img} alt={media.loci_imageID.toString()} />
            {media.type === PostMediaType.VIDEO && (
              <span>
                <i className='material-icons'>play_arrow</i>
              </span>
            )}
            {index === 3 && props.media.length > 4 && (
              <span className='more-overlay'>
                +{props.media.length - 4}
              </span>
            )}
          </picture>
        );
      })}

      {lightboxIndex !== null && (
        <Lightbox
          onClose={() => setLightboxIndex(null)}
          media={props.media}
          index={lightboxIndex}
          setIndex={(i: number) => setLightboxIndex(i)}
          nameDate={undefined}
        />
      )}
    </Media>
  );
};

export default PostMedia;
