import React from "react";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {TailSpin} from "react-loader-spinner";
import "../PostLoader/loader.css";

export default class ProgressBar extends React.Component {
  render(props) {
    // console.log({props});
    return (
     <div className="loader">
      <TailSpin  
           color="var(--primary-color)" height={50} width={50}  
      />
      </div>
    );
  }
}