const CustomToggleButton = ({checked,onChange,className}) => {
  return (
    <>
      <div className={`toggle-btn ${className}`}>
        <label className="switch">
          <input type="checkbox" checked={checked} onChange={onChange} />
          <span className="slider round"></span>
        </label>
      </div>
    </>
  );
};

export default CustomToggleButton
