
import Button from "../../elements/buttons/Button";
import CommentTabData from "./commentTabData";
import FollowTabData from "./followTabData";
import LikeTabData from "./likeTabData";

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import UserDetails from "../../user-details";

function AnalyseTabData(props, setCurrentTab) {
  const { t, i18n } = useTranslation();

  const [showUserDetails, setShowUserDetails] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    // console.log('list in analyse',props.comments);
  }, [props.comments, props.favourites, props.likes]);

  const clickOnName = (userId) => {
    setUserId(userId);
    setShowUserDetails(true);
  };


  return (
    <>
      <div className="analysetab">
        {props.comments !== undefined && props.comments.length > 0 ? (
          <CommentTabData comments={props.comments} 
          clickOnName={(param) => clickOnName(param)}/>
        ) : (
          ""
        )}
        {props.comments !== undefined && props.comments.length > 0 ? (
          <div className="btn-cont">
            <Button
              text={t("SHOW MORE")}
              type="submit"
              className="normal"
              handleClick={() => props.parentMethod("COMMENT_TAB")}
            />
          </div>
        ) : (
          ""
        )}

        {props.likes !== undefined && props.likes.length > 0 ? (
          <LikeTabData likes={props.likes} 
          clickOnName={(param) => clickOnName(param)}/>
        ) : (
          ""
        )}
        {props.likes !== undefined && props.likes.length > 0 ? (
          <div className="btn-cont">
            <Button
              text={t("SHOW MORE")}
              type="submit"
              className="normal"
              handleClick={() => props.parentMethod("LIKE_TAB")}
            />
          </div>
        ) : (
          ""
        )}

        {props.favourites !== undefined && props.favourites.length > 0 ? (
          <FollowTabData
            favourites={props.favourites}
            clickOnName={(param) => clickOnName(param)}
          />
        ) : (
          ""
        )}
        {props.favourites !== undefined && props.favourites.length > 0 ? (
          <div className="btn-cont">
            <Button
              text={t("SHOW MORE")}
              type="submit"
              className="normal"
              handleClick={() => props.parentMethod("FOLLOW_TAB")}
            />
          </div>
        ) : (
          ""
        )}
      </div>

      {showUserDetails && (
        <UserDetails
          id={userId}
          {...props}
          handleClose={() => setShowUserDetails(false)}
        />
      )}
    </>
  );
}

export default AnalyseTabData;
