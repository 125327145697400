import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// import Multiselect from 'react-bootstrap-multiselect'
import { Multiselect } from "multiselect-react-dropdown";
import { inviteSelectedUsers, getUsers } from "../../../api/user";
import { createRef, useEffect, useState } from "react";
import Checkbox from "../../elements/checkbox";
import Slider from "rc-slider";
import Button from "../../elements/buttons/Button";
import { useNavigate, useParams } from "react-router";
import "./multiselect.scss";
import IconButton from "../../elements/buttons/IconButton";
// import { SelectAll } from "@mui/icons-material";

const InviteUsers = (props) => {
  const { t } = useTranslation();

  const [users, setUsers] = useState([]);
  const [isFavChecked, setFavChecked] = useState(true);
  const [isFollowerChecked, setFollowerChecked] = useState(true);
  const [currentDistance, setCurrentDistance] = useState(1000);
  const [minDistance, setMinDistance] = useState(0);
  const [maxDistance, setMaxDistance] = useState(2000);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [isSelectAll, setSelectAll] = useState(false);

  const [isGroupChecked, setGroupChecked] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showResponseModal, setResponseModal] = useState(false);

  const [responseText, setResponseText] = useState("");

  const multiselectRef = createRef();
  const [isDropDownOpen, setIsDropDownOpen] = useState(true);


  const params = useParams();
  const history = useNavigate();

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      const context = this;
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const onSliderChange = (e) => {
    setCurrentDistance(e);
  };

    useEffect(() => {

    let timeoutId;
    if (params?.id) {
      timeoutId = setTimeout(() => {
      debouncedGetUserList(currentDistance);
    }, 1000);
    }

    return () => clearTimeout(timeoutId);

    }, [currentDistance, props.token, isFavChecked, isGroupChecked, isFollowerChecked]);

  useEffect(() => {
    if (isSelectAll) onSelect(selectedUsers);
    else onRemove(selectedUsers);
  }, [isSelectAll]);


  const debouncedGetUserList = debounce((cd) => {
    console.log("debounce called", cd)
    const data = {
      radius: cd,
      favourite: isFavChecked,
      follower: isFollowerChecked,
      group: isGroupChecked,
      search: "",
      groupId: params?.id,
    };
    getUsers(props.token, data).then((res) => {
      if (res.data.OK !== null) {
        let modifyData = res.data.OK.map((me) => ({
          ...me,
          userID: me.userID,
          fullname: `${me.USE_firstname} ${me.USE_surename}`,
        }));
        setUsers(modifyData);
      } else {
        setUsers([]);
      }
    });
  }, 1000); 

  const inviteUsers = () => {
    const data = {
      groupId: params.id,
      userIds: selectedUsers.map((me) => me.userID).join(),
      linkFor: "locci users",
    };
    inviteSelectedUsers(props.token, data).then((res) => {
      console.log("res", res);
      if (res.data.OK) {
        setResponseText(res.data.OK);
        setShowModal(false);
        setResponseModal(true);
      }
    });
  };

  const onSelect = (selectedList, selectedItem) => {
    setIsDropDownOpen(!isDropDownOpen)
    // console.log({ selectedList, selectedUsers, selectedItem, users });
    if (isSelectAll) {
      const userIds = users;
      setSelectedUsers([...userIds]);
    } else {
      const userIds = selectedList.map((me) => me);
      setSelectedUsers([...userIds]);

      let oldIndex = users.findIndex((me) => me.userID === selectedItem.userID);
      //   console.log({ oldIndex });
      //   let temp = users;

      //   var element = temp[oldIndex];
      //   console.log({element});
      //  let a=temp.splice(oldIndex, 1);

      //  let b= temp.splice(selectedList?.length - 1, 0, element);

      //  console.log({a,element});
      // setUsers([...temp]);

      users.splice(selectedList?.length - 1, 0, users.splice(oldIndex, 1)[0]);
    }
  };

  const onRemove = (selectedList, removedItem) => {
    console.log("called", selectedList);
    if (selectedList === undefined) return;
    else {
      if (!isSelectAll && selectedList.length === users.length) {
        const userIds = [];
        setSelectedUsers([...userIds]);
      } else {
        const userIds = selectedList.map((me) => me);
        setSelectedUsers([...userIds]);
        setSelectAll(false);
      }
    }
  };

  // returns [2, 1, 3]

  const customSelectStyle = {
    multiselectContainerReact: {
      maxWidth: 500,
    },
    chips: {
      // To change css chips(Selected options)
      background: "grey",
    },
    option: {
      // To change css for dropdown options
      color: "black",
    },
    optionContainer: {
      background: "white",
    },
  };

  const onCloseRespModal = () => {
    setResponseModal(false);
   history("/groups");
  };

  return (
    <>
      <div className="invite-users-cont">
        <h4 className="pt-2 pb-2">{t("invite_Locci_users")}</h4>

        <div className="slider-wrapper mwidth nopadding">
          <div className="pb-2">
            {t(`show_user_within_radius`)} {currentDistance} km
          </div>
          <Slider
            min={minDistance}
            max={maxDistance}
            defaultValue={maxDistance / 2}
            // onChange={(e) => {setCurrentDistance(e); getUserList(e)}}   
            onChange={onSliderChange}         
            //onAfterChange={(e) => onSliderChange(e)} 
          />
          <div className="slider-labels">
            <span>{minDistance} km</span>
            <span>
              {maxDistance / 2}
              <br />
              km
            </span>
            <span>{maxDistance} km</span>
          </div>
        </div>
        <div className="pb-2">
          <div class="sub-heading pb-2">{t("filter_by")}</div>
          <Form className="row">
            <Form.Group className="col-lg-4 col-sm-12" controlId="isAllowPost">
              <Checkbox
                text={t("my_favourites")}
                value={isFavChecked}
                handleChange={(e) => setFavChecked(!isFavChecked)}
              />
            </Form.Group>

            <Form.Group className="col-lg-4 col-sm-12" controlId="isConfirm">
              <Checkbox
                text={t("my_followers")}
                value={isFollowerChecked}
                handleChange={(e) => setFollowerChecked(!isFollowerChecked)}
              />
            </Form.Group>

            <Form.Group
              className="col-lg-4 col-sm-12"
              controlId="LGP_shareable "
            >
              <Checkbox
                text={t("my_groups")}
                value={isGroupChecked}
                handleChange={(e) => setGroupChecked(!isGroupChecked)}
              />
            </Form.Group>
          </Form>
        </div>

        <div className="row main-cont">
          <div id="multiselectContainerReact">

          <Multiselect
            options={users} // Options to display in the dropdown
            displayValue="fullname" // Property name to display in the dropdown options
            showCheckbox={true}
            placeholder={t("select_locci_user")}
            style={customSelectStyle}
            closeOnSelect={false}
            avoidHighlightFirstOption={true}
            selectedValues={selectedUsers}
            isOpen={isDropDownOpen}
            onSelect={onSelect}
            onRemove={onRemove}
            caseSensitiveSearch={false}
            // ref={multiselectRef}
            className="customDemo"
          />
          </div>

          <Form className="row ml-2 invite-select w-auto">
            <Form.Group className="col-sm-12 invitediv">
              <Checkbox
                text={t("select_all_users_title")}
                value={isSelectAll}
                handleChange={(e) => setSelectAll(!isSelectAll)}
              />

              <div className="text-center invite-btn">
                <Button
                  text={t("invite_people_screen_title_primary")}
                  disabled={selectedUsers.length < 1 ? true : false}
                  handleClick={() => setShowModal(true)}
                />
              </div>
            </Form.Group>
          </Form>
        </div>

        {/* {showModal && (
          <showModalDialog
            showModal={showModal}
            shareUrl={responseText}
            shareUrlType={'group'}
            handleClose={() => setShowModal(false)}
          />
        )} */}

        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          contentClassName="invite-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("send_invitation")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{t("invite_confirm_title")}</div>
            <div className="cmargin">{t("invite_info")}</div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              text={t("enter_group_screen_enter_group_prompt_close_action")}
              handleClick={() => setShowModal(false)}
            />

            <Button
              text={t("send_invitation")}
              handleClick={() => inviteUsers()}
            />
          </Modal.Footer>
        </Modal>

        <Modal
          show={showResponseModal}
          onHide={() => onCloseRespModal()}
          className="custom-modal"
        >
          <IconButton
            clear="true"
            className="closeIcon"
            icon="close"
            handleClick={() => onCloseRespModal()}
          />
          {t(responseText)}
        </Modal>
      </div>
    </>
  );
};

export default InviteUsers;
