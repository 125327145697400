import { API_BASE_URL, API_BASE_HEADERS } from "../global";

import axios from "./AxiosProvider"

export function getGroupAdmins(token, search = "",limit="",page="") {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      Lang: "en",
      action: 29,
      limit:limit,
      page:page,
      search: search,
    },
  });
}
export function getShowRegional(token) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      Lang: "en",
      action: 'Z',
      
    },
  });
}

export function setGCMToken(token, gcmID) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      Lang: "en",
      action: 73,
      webgcmID: gcmID,
    },
  });
}



export function markReadNotification(token, notificationId) {
  const formData = new FormData()
  formData.append("TMBP_ACC_TOKEN",token);
  formData.append("notid",notificationId);
  formData.append("action","301");
  return axios.post(API_BASE_URL, formData ,{headers: API_BASE_HEADERS });
}

export function deleteUserAccount(token) {
  const formData = new FormData()
  const lang = localStorage.getItem('i18nextLng') ?? "en";
  formData.append("TMBP_ACC_TOKEN",token);
  formData.append("Lang", lang);
  formData.append("action","77");
  return axios.post(API_BASE_URL, formData ,{headers: API_BASE_HEADERS });
}

export function markReadNotificationMail(token, notificationId) {
  const formData = new FormData()
  formData.append("TMBP_ACC_TOKEN",token);
  formData.append("notid",notificationId);
  formData.append("action","301");
  return axios.post(API_BASE_URL, formData ,{headers: API_BASE_HEADERS });
}

export function deleteAllNotification(token) {
  const formData = new FormData()
  formData.append("TMBP_ACC_TOKEN",token);
  formData.append("action","48");
  return axios.post(API_BASE_URL, formData ,{headers: API_BASE_HEADERS });
}

export function notificationCount(token) {
  const formData = new FormData()
  formData.append("TMBP_ACC_TOKEN",token);
  formData.append("action","74");
  return axios.post(API_BASE_URL, formData ,{headers: API_BASE_HEADERS });
}

export function downloadChatFiles(token, Id, roomId, attachmentID, type) {
  let modifiedToken = encodeURIComponent(token);

  const link = document.createElement("a");
  link.target = "_blank";
  if (type === "chat" && attachmentID){
    console.log("hitted1", `${API_BASE_URL}?TMBP_ACC_TOKEN=${modifiedToken}&id=${Id}&LC_attachmentID=${attachmentID}&LC_room_id=${roomId}&action=62`);
    link.href = `${API_BASE_URL}?TMBP_ACC_TOKEN=${modifiedToken}&id=${Id}&LC_attachmentID=${attachmentID}&LC_room_id=${roomId}&action=62`;
  }else if(type === "chat" && !attachmentID){
    console.log("hitted3", `${API_BASE_URL}?TMBP_ACC_TOKEN=${modifiedToken}&id=${Id}&LC_room_id=${roomId}&action=62`);
    link.href = `${API_BASE_URL}?TMBP_ACC_TOKEN=${modifiedToken}&id=${Id}&LC_room_id=${roomId}&action=62`;
  } else {
    console.log("hitted2");
    link.href = `${API_BASE_URL}?TMBP_ACC_TOKEN=${modifiedToken}&Lang=en&postId=${Id}&action=63`;
  }

  // https://locciv2-web.tmbp.at/2loci/index.php?TMBP_ACC_TOKEN=D846405MbmYPzjrjTkxuZg%253D%253D&Lang=en&action=63&postId=10189
  link.setAttribute("download", true);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function downloadSingleFileFromPost(token, data) {
  const link = document.createElement("a");
  const encodedToken = encodeURIComponent(token);
  link.href = `${API_BASE_URL}?TMBP_ACC_TOKEN=${encodedToken}&id=${data.loci_docID}&action=17`;
  link.setAttribute("download", data.doc);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}


