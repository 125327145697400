import Favorites from "../../components/favorites";
import Followers from "../../components/followers";
import Gdpr from "../../components/gdpr";
import GroupFeed from "../../components/groups/GroupFeed";
import JoinGroup from "../../components/groups/JoinGroup";
import GroupMembers from "../../components/groups/GroupMembers";
import Inbox from "../../components/inbox";
import Chat from "../../components/inbox/chat";
import NewChat from "../../components/inbox/new";
import Settings from "../../components/settings";
import Blacklist from "../../components/blacklist";
import Notifications from "../../components/notifications";
import MembershipRequests from "../../components/groups/MembershipRequests";
import InviteToGroup from "../../components/groups/invite/InviteToGroup";
import FAQAndSupport from "../../components/FAQAndSupport";
import OverUs from "../../components/OverUs";
import UserPosts from "../../components/user-details/userPosts";
import ViewSinglePost from "../../components/posts/view-post/ViewSinglePost.tsx";
import UnauthPost from "../../components/posts/view-post/UnauthPost";
import GeneralContainer from "../general/GeneralContainer";
import ViewGroupNew from "../../components/groups/ViewGroupNew";
import ProfileNew from "../../components/profile/profilenew";
import ViewGroupStatic from "../../components/groups/ViewGroupStatic";
import GroupContainer from "../../components/groups/GroupsContainer";
import ProfileContainer from "../../components/profile/ProfileContainer";
import Feed from "../../components/feed/index.tsx";
import GroupSettings from "../../components/groups/GroupSettings";

import CreateChatGroup from "../../components/inbox/groupchat/create";
import GroupChatInfo from "../../components/inbox/groupchat/groupChatInfo";
import GroupChatNew from "../../components/inbox/groupchat/groupChatNew";
import SingleGroup from "../../components/single-group/index.tsx";

export const routes = [
  {
    path: "/join-group",
    component: JoinGroup,
  },
  {
    path: "/favorites",
    component: Favorites,
  },
  {
    path: "/followers",
    component: Followers,
  },

  {
    path: "/public-feed",
    component: Feed,
  },
  {
    path: "/my-profile",
    component: ProfileContainer,
  },
  {
    path: "/my-profilenew",
    component: ProfileNew,
  },
  {
    path: "/inbox",
    component: Inbox,
  },
  {
    path: "/new-chat",
    component: NewChat,
  },
  {
    path: "/create-chat-group",
    component: CreateChatGroup,
  },
  {
    path: "/group-chat/:id",
    component: GroupChatNew,
  },
  {
    path: "/group-chat-info/:id",
    component: GroupChatInfo,
  }, 
  {
    path: "/chat/:id",
    component: Chat,
  },
  {
    path: "/chat-info/:id",
    component: GroupChatInfo,
  },
  {
    path: "/settings",
    component: Settings,
  },
  // {
  //   path: "/groupss",
  //   component: GroupContainer,
  // },
  {
    path: "/groups",
    component: GroupContainer,
  },

  {
    path: "/group-settings/:id",
    component: GroupSettings,
    state: "true",
  },

  {
    path: "/group/:id",
    // component: GroupFeed,
    component: SingleGroup,
    state: "true",
  },
  {
    path: "/group/:id/about",
    component: SingleGroup,
  },
  {
    path: "/group/unauth/:id",
    component: SingleGroup,
    noTokenAccess: true,
  },
  {
    path: "/group/unauth/:id/about",
    component: SingleGroup,
    noTokenAccess: true,
  },
  // {
  //   path: "/newviewgroup/:id/about",
  //   component: ViewGroupNew,
  // },
  {
    path: "/viewgroupstatic",
    component: ViewGroupStatic,
  },
  {
    path: "/group/:id/members",
    component: GroupMembers,
  },
  {
    path: "/group/:id/requests",
    component: MembershipRequests,
  },
  {
    path: "/group/:id/invite",
    component: InviteToGroup,
  },

  {
    path: "/blacklist",
    component: Blacklist,
  },
  {
    path: "/notifications",
    component: Notifications,
  },
  {
    path: "/user-posts/:id",
    component: UserPosts,
  },

  {
    path: "/terms-and-conditions",
    component: Gdpr,
    props: { title: "Terms and Conditions" },
    noTokenAccess: true,
  },

  {
    path: "/general",
    component: GeneralContainer,
    noTokenAccess: true,
  },
  {
    path: "/privacy-policy",
    component: Gdpr,
    props: { title: "Privacy Policy" },
    noTokenAccess: true,
  },
  {
    path: "/imprint",
    component: Gdpr,
    props: { title: "Imprint" },
    noTokenAccess: true,
  },
  {
    path: "/FAQAndSupport",
    component: FAQAndSupport,
    noTokenAccess: true,
  },
  {
    path: "/info",
    component: OverUs,
    noTokenAccess: true,
  },
  {
    path: "/post/unauth/:id",
    component: UnauthPost,
    noTokenAccess: true,
  },
  {
    path: "/post/:id",
    component: ViewSinglePost,
  },
];

export default {
  routes,
};
