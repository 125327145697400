import React from 'react';
import "./Checkbox.scss";

  function Checkbox(props) {

    return (
      <span className={props.className? `customCheckbox ${props.className}`:"customCheckbox"} onClick={props.handleChange}>
        <input type="checkbox" checked={props.value} required={props.required} readOnly={true} />
        <span></span>
        {props.text}
      </span>
    );
}

export default Checkbox;

