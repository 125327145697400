import React, { Component } from "react";
import { deleteUserFromFavorites, getFavorites } from "../../api/user";
import IconLink from "../elements/buttons/IconLink";
import Button from "../elements/buttons/Button";
import UserCard from "../elements/cards/UserCard";
import UserData from "../elements/cards/UserData";
import { connect } from "react-redux";
import { mapStateToProps } from "lib/store/mapStateToProps";
import { withTranslation } from "react-i18next";
import BackButton from "../commons/BackButton";

class Favorites extends Component {
  state = {
    favorites: [],
    query: "",
    noDataTitle: "",
  };

  componentDidMount() {
    this.getFavoritesList();
  }

  getFavoritesList() {
    getFavorites(this.props.token, this.props.query).then((res) => {
      if (res.data.OK) {
        this.setState({ favorites: res.data.follow });
        if (res.data.follow.length < 1 || res.data.follow === [])
          this.setState({
            noDataTitle: "not_following_someone",
          });
      }
    });
  }

  componentDidUpdate() {
    if (this.state.query != this.props.query) {
      this.setState({ query: this.props.query });
      this.getFavoritesList();
    }
  }

  deleteFavorite = (id) => {
    deleteUserFromFavorites(this.props.token, id).then((res) => {
      if (res.data.OK) {
        this.getFavoritesList();
      }
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div className="favorites main-content">
        <div className="main-content-narrow">
          <BackButton />
          {this.state.favorites.length > 0 ? (
            this.state.favorites.map((item, i) => (
              <UserCard
                item={item}
                key={i}
                dataChild={<UserData item={item} />}
              >
                <IconLink
                  clear={true}
                  text={t("plus_message_button_title")}
                  link={"/chat/" + item.FK_userID}
                />
                <Button
                  clear={true}
                  text={t("remove")}
                  handleClick={() => this.deleteFavorite(item.FK_userID)}
                />
              </UserCard>
            ))
          ) : (
            <p className="text-center">{t(this.state.noDataTitle)}</p>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(connect(mapStateToProps)(Favorites));
