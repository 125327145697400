import React, { Component, useEffect, useState } from "react";
import {
  deleteUserFromFavorites,
  getFavorites,
  getFollowers,
} from "../../api/user";
import IconLink from "../elements/buttons/IconLink";
import Button from "../elements/buttons/Button";
import UserCard from "../elements/cards/UserCard";
import UserData from "../elements/cards/UserData";
import { connect } from "react-redux";
import { mapStateToProps } from "lib/store/mapStateToProps";
import { withTranslation } from "react-i18next";
import BackButton from "../commons/BackButton";
import { Card, Tab, Tabs } from "react-bootstrap";

import "../../views/general/generaltab.scss";
import { useTranslation } from "react-i18next";
import UserDetails from "../user-details";
import { useNavigate } from "react-router";
import { useAppSelector } from "lib/store";
import * as GlobalStore from 'lib/store/global';

const Followers = (props) => {
  const { t } = useTranslation();

  const history = useNavigate();

  const [key, setKey] = useState("following");
  const [followers, setFolowers] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [noDataTitle, setNoDataTitle] = useState("");
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [userId, setUserId] = useState(null);
  const query = useAppSelector(GlobalStore.selectQuery)

  useEffect(() => {
    if (key === "followers") getFollowersList();
    else getFavoritesList();
  }, [key, query]);

  const getFollowersList = () => {
    getFollowers(props.token, query).then((res) => {
      if (res.data.OK) {
        setFolowers([...res.data.follow]);
        // console.log("called", res.data.OK.length);
        if (res.data.follow < 1) {
          setNoDataTitle("no_followers");
        }
      }
    });
  };

  const getFavoritesList = () => {
    getFavorites(props.token, query).then((res) => {
      if (res.data.OK) {
        console.log("res", res.data.follow);
        setFavorites([...res.data.follow]);
        if (res.data.follow < 1) {
          setNoDataTitle("no_follow_me");
        }
      }
    });
  };

  const deleteFavorite = (id) => {
    deleteUserFromFavorites(props.token, id).then((res) => {
      if (res.data.OK) {
        getFavoritesList();
      }
    });
  };

  const handleTabSelect = (param) => {
    setKey(param);
  };

  const clickOnCard = (userId, type) => {
    console.log("type", type);
    switch (type) {
      case "profile":
        setUserId(userId);
        return setShowUserDetails(true);
      case "delete":
        return deleteFavorite(userId);
      default:
       history(`/chat/${userId}`);
    }
  };

  const clickOnFollowerCard = (e, clickedOn, userId, type) => {
    e.stopPropagation();
    console.log(clickedOn);
    if (clickedOn === "img") {
      setUserId(userId);
      return setShowUserDetails(true);
    } else {
     history(`/chat/${userId}`);
    }
  };

  return (
    <>
      <div className="container">
        {/* <div class="back-btn-cont">
          <BackButton />
        </div> */}

        <Card className="gdpr-card">
          <Card.Body>
            <Tabs
              justify
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => handleTabSelect(k)}
              unmountOnExit={true}
              // className="custom-width" 
              className="tab-content"
            >
              <Tab
                eventKey="following"
                title={t("i_follow")}
                // tabClassName="col-lg-6"
              >
                {favorites.length > 0 ? (
                  favorites.map((item, i) => (
                    <UserCard
                      item={item}
                      key={i}
                      dataChild={<UserData item={item} />}
                      handleClick={() => clickOnCard(item.FK_userID, "profile")}
                      className="cursor"
                    >
                      <Button
                        clear={true}
                        text={t("plus_message_button_title")}
                        handleClick={(e) => {
                          clickOnCard(item.FK_userID, "message");
                          e.stopPropagation();
                        }}
                      />
                      <Button
                        clear={true}
                        text={t("remove")}
                        handleClick={(e) => {
                          clickOnCard(item.FK_userID, "delete");
                          e.stopPropagation();
                        }}
                      />
                    </UserCard>
                  ))
                ) : (
                  <div className="noDataTitle2">{t(`${noDataTitle}`)}</div>
                )}
              </Tab>
              <Tab
                eventKey="followers"
                title={t("follow_me")}
                // tabClassName="col-lg-6"
              >
                {followers.length > 0 ? (
                  followers.map((item, i) => (
                    <UserCard
                      key={i}
                      item={item}
                      dataChild={<UserData item={item} />}
                      handleClick={(e, clickedOn) =>
                        clickOnFollowerCard(
                          e,
                          clickedOn,
                          item.FK_userID,
                          "profile"
                        )
                      }
                      className="cursor"
                    >
                      <Button
                        clear={true}
                        text={t("plus_message_button_title")}
                        handleClick={(e, clickedOn) => {
                          clickOnFollowerCard(
                            e,
                            clickedOn,
                            item.FK_userID,
                            "message"
                          );
                          e.stopPropagation();
                        }}
                      />
                    </UserCard>
                  ))
                ) : (
                  <div className="noDataTitle">{t(`${noDataTitle}`)}</div>
                )}
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </div>

      {showUserDetails && (
        <UserDetails
          id={userId}
          {...props}
          handleClose={() => setShowUserDetails(false)}
        />
      )}
    </>
  );
};

export default withTranslation()(connect(mapStateToProps)(Followers));
