import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Button from "../elements/buttons/Button";
import IconButton from "../elements/buttons/IconButton";

const ChatFileModal = (props) => {
  const { t } = useTranslation();
  

  return (
    <Modal
      centered
      show={props.show}
      onHide={() => props.closeModal()}
      contentClassName="chat-confirmation-modal"
    > 
      <div>
        <IconButton
          icon="close"
          clear={true}
          className="closeButtonChat"
          handleClick={() => props.closeModal()}
        />
      </div>
      <Modal.Body className="modalBodys">
        <div className={`report-actionss ${props.lowerCase && "lowerCaseBtn"}`}>
          <Button text={props.textBtnOne} handleClick={() => props.btnOne()} />

          <Button text={props.textBtnTwo} handleClick={() => props.btnTwo()} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChatFileModal;
